import React, { useEffect, useRef } from 'react';
import '../../Css/DevOps.css';
import certificateImg from '../../assets/certificate.webp';
import Contact from '../Contact'
import IMG from '../../assets/Work.webp'
import Roadmap from '../../Components/Roadmap';
import WhatsDevOps from '../../Components/WhatsDevOps'
import PageForm from '../../Components/PageForm';
import Syllabus from '../../Components/Syllabus';
import KeyFeatures from '../../Components/KeyFeatures';
import Faq from '../../Components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
import dev1 from '../../assets/Projects/Devops/DEV1.png';
import dev2 from '../../assets/Projects/Devops/DEV2.png';
import dev3 from '../../assets/Projects/Devops/DEV3.png';
import dev4 from '../../assets/Projects/Devops/DEV4.png';
import dev5 from '../../assets/Projects/Devops/DEV5.png';
import dev6 from '../../assets/Projects/Devops/DEV6.png';
import dev7 from '../../assets/Projects/Devops/DEV7.png';
import dev8 from '../../assets/Projects/Devops/DEV8.png';
import rev1 from '../../assets/Reviews/DEVOPS/devrev1.png';
import rev2 from '../../assets/Reviews/DEVOPS/devrev2.png';
import rev3 from '../../assets/Reviews/DEVOPS/devrev3.png';
import rev4 from '../../assets/Reviews/DEVOPS/devrev4.png';
import rev5 from '../../assets/Reviews/DEVOPS/devrev5.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import SideDropdown from '../../Components/SideDropdown';

const SAPBASIS = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);


  // useEffect(() => {
  // Function to create the YouTube player
  // const createPlayer = () => {
  //   if (window.YT && window.YT.Player) {
  //     playerRef.current = new window.YT.Player(videoRef.current, {
  //       videoId: 'i3HdVH6gdfE', // Updated YouTube video ID
  //       events: {
  //         onReady: (event) => {
  //           event.target.mute(); // Mute the video to allow autoplay
  //           // event.target.playVideo();
  //         },
  //       },
  //       playerVars: {
  //         loop: 1,
  //         controls: 0, // Hide video controls
  //         modestbranding: 1, // Minimal YouTube branding
  //         playlist: 'i3HdVH6gdfE', // Required for looping
  //       },
  //     });
  //   }
  // };

  // Load YouTube IFrame API if it's not already loaded
  // if (!window.YT) {
  //   const tag = document.createElement('script');
  //   tag.src = 'https://www.youtube.com/iframe_api';
  //   const firstScriptTag = document.getElementsByTagName('script')[0];
  //   firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  //   // Set up callback for when the API is ready
  //   window.onYouTubeIframeAPIReady = () => {
  //     createPlayer();
  //   };
  // } else {
  //   createPlayer();
  // }
  // }, []);

  const modulesData = [
    {
      title: 'SAP',
      points: [
          'Sapalogy Training in Nagpur offers expert SAP training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
      ],
      link: "/best-sap-training"
  },
    {
      title: 'SAP BASIS',
      points: [
        'Sapalogy Training in Nagpur offers expert SAP BASIS training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
      ],
      link: "/sap-basis-training-in-nagpur"
    },
    {
      title: 'SAP MM',
      points: [
        'SAP MM (Materials Management) is a module in SAP ERP that manages procurement processes and inventory management. Sapalogy Institute in Nagpur offers comprehensive training in SAP MM for effective utilization ',
      ],
      link: "/sap-mm"
    },
    {
      title: 'SAP FICO',
      points: [
        'SAP FICO, offered by Sapalogy Institute Nagpur, encompasses financial accounting and controlling modules within the SAP ERP system, facilitating comprehensive financial management and reporting for organizations'
      ],
      link: "/sap-fico"
    },
    {
      title: 'SAP ABAP',
      points: [
        'SAP ABAP is a programming language used for developing applications within the SAP ecosystem. Sapalogy Institute in Nagpur is a training center specializing in SAP courses, including ABAP programming to equip professionals with the skills.',
      ],
      link: "/sap-abap",
    },
    {
      title: 'SAP HCM',
      points: [
        'SAP HCM (Human Capital Management) is an integrated software suite by SAP that manages HR processes, from recruitment to payroll. Sapalogy Institute in Nagpur likely provides training in SAP HCM and related modules.'
      ],
      link: "/sap-hcm",
    },
    {
      title: 'SAP SCM',
      points: [
        'SAP SCM (Supply Chain Management) is a software suite by SAP that integrates and optimizes key supply chain processes for enhanced efficiency. It enables businesses to manage procurement, production, and distribution seamlessly, ensuring streamlined operations.',
      ],
      link: "/sap-scm"
    },
    {
      title: 'SAP ARIBA',
      points: [
        'SAP Ariba is a cloud-based procurement platform by SAP that streamlines and automates procurement processes, enhancing collaboration between buyers and suppliers. Sapalogy Institute Nagpur is an educational institution specializing in SAP training and certification.',
      ],
      link: "/sap-ariba"
    },
    {
      title: 'SAP PP',
      points: [
        'SAP PP (Production Planning) is an SAP module that helps organizations manage and optimize their manufacturing processes, from planning and scheduling to execution. It integrates various business functions to streamline production.',
      ],
      link: "/sap-pp"
    },
    {
      title: 'SAP SD',
      points: [
        'SAP SD (Sales and Distribution) is a module in SAP ERP that manages sales and customer service processes. It covers order-to-cash processes, including sales order processing, pricing, delivery, and billing. SAP SD to equip individuals with skills.',
      ],
      link: "/sap-sd"
    },
    {
      title: 'SAP FIORI',
      points: [
        'SAP Fiori is a user experience (UX) design approach and set of design principles that enhance the usability and functionality of SAP applications. It provides a modern, responsive, and personalized user interface for a seamless and intuitive user experience in the SAP environment.',
      ],
      link: "/sap-fiori"
    },
    {
      title: 'SAP QM',
      points: [
        'SAP QM (Quality Management) is a module in SAP ERP that facilitates quality control and assurance processes in manufacturing and production. It helps organizations manage quality planning, inspection, and certification, ensuring products meet specified standards and many more.',
      ],
      link: "/sap-qm"
    },
    {
      title: 'SAP PM',
      points: [
        'SAP PM (Plant Maintenance) is a module in SAP ERP that helps organizations effectively manage and maintain their assets, equipment, and facilities. It covers processes such as preventive maintenance, work orders, and equipment tracking to optimize asset performance.',
      ],
      link: "/sap-pm"
    },
    {
      title: 'SAP WM & EWM',
      points: [
        'SAP WM (Warehouse Management) focuses on efficient inventory management within a warehouse, while SAP EWM (Extended Warehouse Management) extends these capabilities with advanced features like labour management and slotting optimization for operations.',
      ],
      link: "/sap-wm-ewm"
    },
    {
      title: 'SAP LE & SL',
      points: [
        'SAP LE (Logistics Execution) is a module that manages and optimizes warehouse and transportation processes, while SAP SL (Slotting) focuses on optimizing storage space and picking efficiency within a warehouse. Both modules contribute to streamlining supply chain operations in SAP systems.',
      ],
      link: "/sap-le-sl"
    },
  ];

  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'What is SAP?',
        'What is  Business One?',
        'What is SAP HANA?',
        'SAP course',
        'SAP certification',
        'SAP jobs in india',
        'SAP jobs in nagpur',
      ]
    },
    {
      title: ' Understanding SAP Business One Fundamentals',
      points: [
        'Learn the architecture of SAP Business One systems.',
        'Understand the role and responsibilities of an SAP Business One consultant.',
        'Familiarize yourself with essential SAP Business One terminology.',
      ]
    },
    {
      title: 'Installation and Configuration',
      points: [
        'Install and configure SAP Business One systems and databases.',
        'Set up SAP Business One landscapes and perform system copies.',
        'Configure system parameters and profiles specific to SAP Business One.',
      ]
    },
    {
      title: 'User and Authorization Management',
      points: [
        'Create and manage user accounts in SAP Business One.',
        'Assign roles and authorizations.',
        'Implement security policies and procedures within SAP Business One.',
      ]
    },
    {
      title: 'System Monitoring and Performance Tuning',
      points: [
        'Monitor SAP Business One system performance and health.',
        'Use tools like SAP Solution Manager for system monitoring.',
        'Perform regular system health checks and optimize performance.',
      ]
    },
    {
      title: 'Backup and Recovery Procedures',
      points: [
        'Implement effective backup strategies for SAP Business One systems.',
        'Perform regular backups and data recovery tests.',
        'Ensure data integrity and availability for SAP Business One environments.',
      ]
    },
    {
      title: 'Upgrades and Patch Management',
      points: [
        'Plan and execute SAP Business One system upgrades.',
        'Apply patches and updates to SAP Business One software.',
        'Test and validate system changes before deployment.',
      ]
    },
    {
      title: 'Resume preparation',
      points: [
        'Include keywords',
        'How to prepare reume',
        'How to prepare resume for freshers.',
        'Show impact',
        'Include soft skills',
        'Be unique',
      ]
    }
  ];

  const syllabus = [
    {
      title: 'Introduction to ERP & SAP Business One',
      subpoints: [
        'What is ERP?',
        'Overview of SAP Business One',
        'Key Features of SAP Business One',
        'SAP Business One Versions and Releases',
      ],
    },
    {
      title: 'SAP Business One Architecture ',
      subpoints: [
        'SAP Business One System Architecture Overview',
        'Components of SAP Business One',
        'Client-Server Architecture',
        'Types of Servers in SAP Business One',
      ],
    },
    {
      title: 'Modules in SAP Business One',
      subpoints: [
        'Financial Management',
        'Sales and Customer Relationship Management (CRM)',
        'Purchasing and Inventory Control',
        'Production and Material Requirements Planning (MRP)',
        'Business Intelligence and Analytics',
        'Project Management',
      ],
    },
    {
      title: 'Installation and Setup',
      subpoints: [
        'Installation of SAP Business One',
        'System Configuration',
        'Database Setup and Connectivity',
      ],
    },
    {
      title: 'System Administration',
      subpoints: [
        'System Landscape Overview',
        'System Initialization and Configuration',
        'Company Setup and Customization',
        'User and Authorization Management',
        'Configuring Roles and Permissions',
      ],
    },
    {
      title: ' Financial Management in SAP Business One',
      subpoints: [
        'General Ledger',
        'Chart of Accounts',
        'Account Setup',
        'Bank Transactions and Reconciliation',
        'Accounts Payable and Receivable',
      ],
    },
    {
      title: ' Sales and Purchasing Management',
      subpoints: [
        'Sales Process Overview',
        'Sales Quotation, Orders, and Invoices',
        'Purchasing Process Overview',
        'Purchase Orders and Goods Receipt',
        'Inventory Tracking and Control',
      ],
    },
    {
      title: 'Inventory and Production Management',
      subpoints: [
        'Inventory Setup and Management',
        'Item Master Data',
        'Warehouse Management',
        'Bill of Materials (BOM)',
        'Production Orders',

      ],
    },
    {
      title: 'Reporting and Analytics',
      subpoints: [
        'Standard Reports in SAP Business One',
        'Custom Report Generation',
        'Business Intelligence Tools',
        'Creating Dashboards and KPIs',
      ],
    },
    {
      title: 'Customization and Add-Ons',
      subpoints: [
        'User-Defined Fields (UDF)',
        'User-Defined Tables (UDT)',
        'Custom Queries and Alerts',
        'Integration with Add-ons and Third-Party Tools',
      ],
    },
    {
      title: 'System Maintenance and Troubleshooting',
      subpoints: [
        'System Monitoring and Health Checks',
        'Data Backup and Recovery Procedures',
        'Patch and Update Management',
        'Troubleshooting Common Issues',
      ],
    },
    {
      title: 'Business Process Implementation',
      subpoints: [
        'Understanding the Business Processes',
        'End-to-End Business Process Implementation',
        'Real-time SAP Business One Project',
      ],
    },
    {
      title: 'Integration and Data Migration',
      subpoints: [
        'Data Import and Export Tools',
        'Integration with External Systems',
        'Data Migration Best Practices',
      ],
    },
    {
      title: 'SAP Business One Certification Preparation',
      subpoints: [
        'Exam Structure and Topics',
        'Sample Questions and Mock Tests',
        'Key Topics to Focus On',

      ],
    },
    {
      title: ' Resume Preparation & Interview Practice',
      subpoints: [
        'Resume Writing Tips for SAP Business One Roles',
        'Highlighting Key Skills and Projects',
        'Interview Questions and Mock Interviews',
      ],
    },

  ];

  const keyFeatures = [
    "126+ Hours course duration",
    "100% Job oriented Training",
    "Industry expert faculties",
    "Free demo class available",
    "Completed 200+ Batches",
    "Certifaction guidance",
  ];

  const faqs = [
    {
      question: "What is SAP Business One?",
      answer: "SAP Business One is an enterprise resource planning (ERP) software designed for small and medium-sized businesses to manage their operations, including finance, sales, inventory, and production.",
    },
    {
      question: "What are the main responsibilities of an SAP Business One consultant?",
      answer: "An SAP Business One consultant is responsible for system implementation, customization, support, training, and ensuring that the system aligns with the business needs.",
    },
    {
      question: "What skills are required to become an SAP Business One consultant?",
      answer: "Key skills include knowledge of business processes, experience with SAP Business One modules, database management, reporting, problem-solving, and strong communication skills.",
    },
    {
      question: "What is the difference between SAP Business One and SAP S/4HANA?",
      answer: "SAP Business One is designed for small and medium-sized businesses, offering essential ERP functionalities, while SAP S/4HANA is an advanced ERP solution for large enterprises with more complex and scalable features.",
    },
    {
      question: "How does SAP Business One support system security?",
      answer: "SAP Business One provides role-based access, user authentication, and authorization management to ensure system security and prevent unauthorized access.",
    },
    {
      question: "What tools are commonly used by SAP Business One consultants?",
      answer: "Common tools include SAP Business One Studio, Crystal Reports for custom reporting, and the SAP Integration Framework for system integration.",
    },
    {
      question: "What are the common challenges faced by SAP Business One consultants?",
      answer: "Common challenges include data migration, customization to meet business-specific needs, system performance optimization, and managing system updates and patches.",
    },

  ];

  const whatsData = [
    {
      title: 'What is SAP Business One ?',
      points: [
        'System Support: SAP Business One ensures seamless integration and smooth operation of business processes.',
        'Administration: Manages SAP Business One system configuration, monitoring, and troubleshooting.',
        'Security: Controls user access and protects against unauthorized access.',
        'Database Management: Oversees database operations, backups, and performance optimization for SAP Business One.',
        'Sapalogy, an authorized training center in Nagpur, offers SAP Business One training in both offline and online modes.',
        'The program includes end-user training, consulting, implementation, and support, with real-time SAP Business One project-based training.',
        'Whether you have an IT or non-IT background, are a fresher, or have prior experience, anyone can start their career in SAP Business One.',
        'Sapalogy is the best training institute in Nagpur, offering 100% job opportunities.',
      ]
    }
  ]

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imageList = [
    { Image: dev1, alt: "Award 1" },
    { Image: dev2, alt: "Award 2" },
    { Image: dev3, alt: "Award 3" },
    { Image: dev4, alt: "Award 4" },
    { Image: dev5, alt: "Award 5" },
    { Image: dev6, alt: "Award 6" },
    { Image: dev7, alt: "Award 7" },
    { Image: dev8, alt: "Award 8" },
  ];

  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },
    { Image: rev5, alt: "rev1" },
  ]


  return (
    <div className="devops-page">
         <SideDropdown roadmapData={modulesData} />
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Best SAP Business One Training</h1>
          <p>

            Best SAP Business One Training by Sapalogy provides top-notch courses for both freshers and experienced professionals in Nagpur. With expert guidance and 100% placement assistance, our training covers every technical topic in detail, ensuring real-time, hands-on experience.
          </p>
          <br />
          <p>
            Our practical, job-oriented training offers you a certificate and knowledge equivalent to 2+ years of experience. We respect your time and provide SAP Business One training that can be completed in just 4 months. Sapalogy's SAP training institute in Nagpur delivers comprehensive, practical training designed to give you the skills equivalent to 7+ years of industry experience.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faFileAlt} /> End user 1 month </li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Configuration 2 months </li>
            <li><FontAwesomeIcon icon={faBriefcase} /> Project 1 month</li>
            <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> 6-months internship</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
          </ul>
        </div>

        <PageForm />
      </div>

      {/* <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>

      </div> */}

      <Roadmap heading="Roadmap to learn SAP Business One " roadmapData={roadmapData} />
      <WhatsDevOps whatsData={whatsData} />
      <Syllabus heading="SAP Business One Training syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="SAP Business One Training" keyFeatures={keyFeatures} />

      <div className="batch-schedule-section">
        <h2>Upcoming Batch Schedule for SAP Business One Training</h2>
        <p>
          Sapalogy training offers flexible timings to accommodate all our students. Below is the SAP Business One Training Class Schedule at our branches. If this schedule doesn’t suit your availability, please let us know, and we will do our best to arrange timings that fit your schedule.
        </p>
        <div className="batch-schedule-table-wrapper">
          <table className="batch-schedule-table">
            <thead>
              <tr>
                <th>Course</th>
                <th>Batch Time</th>
                <th>Offline</th>
                <th>Online</th>
                <th>Enquire Now</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>SAP Business One</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td><a href="/contact">Enquire Now</a></td>
              </tr>
              <tr>
                <td>SAP</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td><a href="/contact">Enquire Now</a></td>
              </tr>
              {/* Add more rows as needed */}
            </tbody>
          </table>
        </div>
      </div>

      <section className="devops-certification-section">
        <h2 className="certification-heading">SAP Business One  CERTIFICATION</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
              <li>The SAP Business One certification offered by Sapalogy will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
              <li>Sapalogy provides comprehensive guidance for your SAP Business One global certification, ensuring a 100% passing guarantee in examinations such as SAP Certification, SAP Business One Implementation and Support Certification, and various other global exams.</li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      <Roadmap heading="SAP modules" roadmapData={modulesData} />

      {/* devops project Section */}
      {/* <div className="slider-container">
        <h2 className="slider-heading">SAP HCM Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div> */}

      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div> */}


      <div className="alumni-section">
        <h1>Our Alumni Works At</h1>
        <div className="full-screen-image">
          <img src={IMG} alt="Full Screen Image" />
        </div>
      </div>
      <Contact />

    </div>
  );
};

export default SAPBASIS;