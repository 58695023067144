import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Select,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Box,
} from '@mui/material';
import { courseData } from '../courses';

// Function to calculate EMI
const calculateEMI = (principal, annualInterestRate, months) => {
    const monthlyInterestRate = annualInterestRate / (12 * 100); // Monthly interest rate
    const emi = (principal * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, months)) /
        (Math.pow(1 + monthlyInterestRate, months) - 1);
    return emi.toFixed(2); // Rounded EMI
};

// Function to calculate total interest
const calculateTotalInterest = (emi, months, principal) => {
    const totalAmountPayable = emi * months;
    const totalInterest = totalAmountPayable - principal;
    return totalInterest.toFixed(2); // Rounded interest
};

const CourseFeesDialog = () => {
    const [open, setOpen] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [courseValue, setCourseValue] = useState(""); // Selected course state

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedCourse(null);
        setCourseValue(""); // Reset selected course value
    };

    const handleCourseChange = (event) => {
        const selectedId = event.target.value;
        const course = courseData.find(course => course.id === selectedId);
        setSelectedCourse(course);
        setCourseValue(selectedId); // Update course state
    };

    return (
        <>
            <Button className='fees-btn' variant="contained" sx={{
                backgroundColor: '#6c089a',
                color: 'white',
                padding: { xs: '5px 10px', sm: '10px 20px' }, // Smaller padding on extra-small screens
                borderRadius: '30px',
                fontSize: { xs: '12px', sm: '14px' }, // Smaller font size on extra-small screens
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                transition: 'all 0.3s ease',
                position: 'fixed',
                top: '130px',
                left: '5px',
                zIndex: 1000,
                '&:hover': {
                    backgroundColor: '#520277',
                    boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.4)',
                },
            }} onClick={handleOpen}>
                See Courses Fees
            </Button>


            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle sx={{
                    background: ' #29013b',
                    color: '#fff',
                    textAlign: 'center',
                    padding: '20px 0',
                    fontSize: { xs: '20px', sm: '24px' }, // Responsive font size
                    fontWeight: 'bold',
                }}>
                    Course Fees
                </DialogTitle>

                <DialogContent sx={{
                    background: '#29013b',
                    color: '#fff',
                    padding: '30px',
                }}>
                    <Select
                        value={courseValue}
                        onChange={handleCourseChange}
                        displayEmpty
                        fullWidth
                        sx={{
                            background: 'rgba(255, 255, 255, 0.1)',
                            color: '#fff',
                            marginBottom: '20px',
                            borderRadius: '5px',
                            padding: { xs: '8px', sm: '10px' }, // Responsive padding
                            fontSize: { xs: '14px', sm: '16px' }, // Responsive font size
                            '& .MuiSelect-icon': {
                                color: '#fff',
                            },
                        }}>
                        <MenuItem value="" disabled>Select a Course</MenuItem>
                        {courseData.map(course => (
                            <MenuItem key={course.id} value={course.id} sx={{ color: '#000' }}>
                                {course.name}
                            </MenuItem>
                        ))}
                    </Select>


                    {selectedCourse && (
                        <Box>
                            <Typography variant="h5" sx={{ color: '#9F33B1', mb: 1 }}>
                                {selectedCourse.name}
                            </Typography>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                Price: ₹{selectedCourse.price}
                            </Typography>
                            <div style={{ overflowX: 'auto' }}>
                                <Table sx={{
                                    background: 'rgba(255, 255, 255, 0.05)',
                                    borderRadius: '10px',
                                    border: '1px solid rgba(255, 255, 255, 0.1)',
                                    minWidth: '600px', // Ensure a minimum width
                                }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Duration</TableCell>
                                            <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Interest Rate (%)</TableCell>
                                            <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Monthly Payment (₹)</TableCell>
                                            <TableCell sx={{ color: '#fff', fontWeight: 'bold' }}>Total Interest (₹)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedCourse.emis.map((emi, index) => {
                                            const monthlyEMI = calculateEMI(selectedCourse.price, emi.interest, parseInt(emi.duration));
                                            const totalInterest = calculateTotalInterest(monthlyEMI, parseInt(emi.duration), selectedCourse.price);

                                            return (
                                                <TableRow key={index}>
                                                    <TableCell sx={{ color: '#fff' }}>{emi.duration}</TableCell>
                                                    <TableCell sx={{ color: '#fff' }}>{emi.interest}</TableCell>
                                                    <TableCell sx={{ color: '#fff' }}>{monthlyEMI}</TableCell>
                                                    <TableCell sx={{ color: '#fff' }}>{totalInterest}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        </Box>
                    )}
                </DialogContent>

                <DialogActions sx={{
                    background: '#29013b',
                    padding: '20px',
                }}>
                    <Button onClick={handleClose} sx={{
                        backgroundColor: '#9F33B1',
                        color: '#fff',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        fontWeight: 'bold',
                        '&:hover': {
                            backgroundColor: '#64037C',
                        },
                    }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CourseFeesDialog;
