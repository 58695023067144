import React, { useEffect, useRef } from 'react';
import '../../Css/DevOps.css';
import certificateImg from '../../assets/certificate.webp';
import Contact from '../../pages/Contact'
import IMG from '../../assets/Work.webp'
import Roadmap from '../../Components/Roadmap';
import WhatsDevOps from '../../Components/WhatsDevOps'
import PageForm from '../../Components/PageForm';
import Syllabus from '../../Components/Syllabus';
import KeyFeatures from '../../Components/KeyFeatures';
import Faq from '../../Components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import SideDropdown from '../../Components/SideDropdown';



const SAPARIBA = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);


   useEffect(() => {
    //Function to create the YouTube player
    const createPlayer = () => {
      if (window.YT && window.YT.Player) {
        playerRef.current = new window.YT.Player(videoRef.current, {
          videoId: 'oXDqjc564g4', // Updated YouTube video ID
          events: {
            onReady: (event) => {
              // event.target.mute(); // Mute the video to allow autoplay
              event.target.playVideo();
            },
          },
          playerVars: {
            loop: 1,
            controls: 0, // Hide video controls
            modestbranding: 1, // Minimal YouTube branding
            playlist: 'oXDqjc564g4', // Required for looping
          },
        });
      }
    };

    // Load YouTube IFrame API if it's not already loaded
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Set up callback for when the API is ready
      window.onYouTubeIframeAPIReady = () => {
        createPlayer();
      };
    } else {
      createPlayer();
    }
  }, []);


  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'What is SAP?',
        'What is SAP ARIBA?',
        'What is SAP HANA?',
        'SAP course',
        'SAP certification',
        'SAP jobs in india',
        'SAP jobs in nagpur',
      ]
    },
    {
      title: 'System Design and Configuration',
      points: [
        "Work on SAP Ariba system design based on business requirements.",
        "Configure SAP Ariba modules according to the organization’s needs.",
        "Establish integration points with other enterprise systems.",
      ]
    },
    {
      title: 'Data Migration',
      points: [
        "Develop a data migration strategy for transferring relevant data.",
        "Cleanse and validate data to ensure accuracy.",
        "Perform mock migrations to identify and resolve issues.",
      ]
    },
    {
      title: 'Testing',
      points: [
        'Conduct thorough testing of SAP Ariba modules.',
        'Perform unit testing, integration testing, and user acceptance testing.',
        'Address and resolve any identified issues.',
      ]
    },
    {
      title: 'Deployment',
      points: [
        'Plan and execute a phased deployment strategy.',
        'Monitor system performance during the initial rollout.',
        'Address any issues or concerns promptly.',
      ]
    },
    {
      title: ' OST-Implementation Support',
      points: [
        'Establish a support system for users post-implementation.',
        'Monitor system performance and user feedback.',
        'Address any issues or optimizations required.',
      ]
    },
    {
      title: 'Integration with Other Systems',
      points: [
        "Explore opportunities for further integration with other enterprise systems.",
        "Assess and implement APIs or connectors for seamless data flow.",
      ]
    },
    {
      title: 'Resume preparation',
      points: [
        'Include keywords.',
        'How to prepare resume.',
        'How to prepare resume for freshers.',
        'Show impact.',
        'Include soft skills.',
        'Be unique.',
      ]
    }
  ];

  const modulesData = [
    {
      title: 'SAP',
      points: [
          'Sapalogy Training in Nagpur offers expert SAP training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
      ],
      link: "/best-sap-training"
  },
    {
        title: 'SAP BASIS',
        points: [
            'Sapalogy Training in Nagpur offers expert SAP BASIS training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
        ],
        link: "/sap-basis-training-in-nagpur"
    },
    {
        title: 'SAP MM',
        points: [
            'SAP MM (Materials Management) is a module in SAP ERP that manages procurement processes and inventory management. Sapalogy Institute in Nagpur offers comprehensive training in SAP MM for effective utilization ',
        ],
        link: "/sap-mm"
    },
    {
        title: 'SAP FICO',
        points: [
            'SAP FICO, offered by Sapalogy Institute Nagpur, encompasses financial accounting and controlling modules within the SAP ERP system, facilitating comprehensive financial management and reporting for organizations'
        ],
        link: "/sap-fico"
    },
    {
        title: 'SAP ABAP',
        points: [
            'SAP ABAP is a programming language used for developing applications within the SAP ecosystem. Sapalogy Institute in Nagpur is a training center specializing in SAP courses, including ABAP programming to equip professionals with the skills.',
        ],
        link: "/sap-abap",
    },
    {
        title: 'SAP HCM',
        points: [
            'SAP HCM (Human Capital Management) is an integrated software suite by SAP that manages HR processes, from recruitment to payroll. Sapalogy Institute in Nagpur likely provides training in SAP HCM and related modules.'
        ],
        link: "/sap-hcm",
    },
    {
        title: 'SAP SCM',
        points: [
            'SAP SCM (Supply Chain Management) is a software suite by SAP that integrates and optimizes key supply chain processes for enhanced efficiency. It enables businesses to manage procurement, production, and distribution seamlessly, ensuring streamlined operations.',
        ],
        link: "/sap-scm"
    },
    {
        title: 'SAP ARIBA',
        points: [
            'SAP Ariba is a cloud-based procurement platform by SAP that streamlines and automates procurement processes, enhancing collaboration between buyers and suppliers. Sapalogy Institute Nagpur is an educational institution specializing in SAP training and certification.',
        ],
        link: "/sap-ariba"
    },
    {
        title: 'SAP PP',
        points: [
            'SAP PP (Production Planning) is an SAP module that helps organizations manage and optimize their manufacturing processes, from planning and scheduling to execution. It integrates various business functions to streamline production.',
        ],
        link: "/sap-pp"
    },
    {
        title: 'SAP SD',
        points: [
            'SAP SD (Sales and Distribution) is a module in SAP ERP that manages sales and customer service processes. It covers order-to-cash processes, including sales order processing, pricing, delivery, and billing. SAP SD to equip individuals with skills.',
        ],
        link: "/sap-sd"
    },
    {
        title: 'SAP FIORI',
        points: [
            'SAP Fiori is a user experience (UX) design approach and set of design principles that enhance the usability and functionality of SAP applications. It provides a modern, responsive, and personalized user interface for a seamless and intuitive user experience in the SAP environment.',
        ],
        link: "/sap-fiori"
    },
    {
        title: 'SAP QM',
        points: [
            'SAP QM (Quality Management) is a module in SAP ERP that facilitates quality control and assurance processes in manufacturing and production. It helps organizations manage quality planning, inspection, and certification, ensuring products meet specified standards and many more.',
        ],
        link: "/sap-qm"
    },
    {
        title: 'SAP PM',
        points: [
            'SAP PM (Plant Maintenance) is a module in SAP ERP that helps organizations effectively manage and maintain their assets, equipment, and facilities. It covers processes such as preventive maintenance, work orders, and equipment tracking to optimize asset performance.',
        ],
        link: "/sap-pm"
    },
    {
        title: 'SAP WM & EWM',
        points: [
            'SAP WM (Warehouse Management) focuses on efficient inventory management within a warehouse, while SAP EWM (Extended Warehouse Management) extends these capabilities with advanced features like labour management and slotting optimization for operations.',
        ],
        link: "/sap-wm-ewm"
    },
    {
        title: 'SAP LE & SL',
        points: [
            'SAP LE (Logistics Execution) is a module that manages and optimizes warehouse and transportation processes, while SAP SL (Slotting) focuses on optimizing storage space and picking efficiency within a warehouse. Both modules contribute to streamlining supply chain operations in SAP systems.',
        ],
        link: "/sap-le-sl"
    },
];

  const syllabus = [
    {
      title: 'Introduction to SAP Ariba',
      subpoints: [
        "Overview What is SAP Ariba",
        "Overview Usage in industry",
      ],
    },
    {
      title: 'SAP Ariba Buy Side Integration',
      subpoints: [
        " Overview and Getting Started on SAP Ariba Cloud Integration Gateway",
        " Configuration of Cloud Connector and Add-on/SPRO",
        " Ariba Network Integration – IDOC and Proxy",
        " Flows Master Data Overview",
        " SAP Ariba Procurement Integration Configuration",
        " SAP Ariba Strategic Sourcing Integration",
        " Configuration SAP Ariba Strategic Sourcing Transaction",
        " Integration SAP Ariba Procurement Transaction",
        " Integration Customization Troubleshooting",
      ],
    },
    {
      title: 'SAP Ariba Contracts',
      subpoints: [
        'a) Creating Contracts',
        '-Introduction',
        '-Preparing a Workspace',
        '-Author the Main Agreement',
        '-Review & Negotiate Contract',
        '-Documents Approve & Finalize',
        '-Contract Workspace Amend & Manage',
        '-Contract Workspace Contract',
        '-Requests',
        '-Run Prepackaged Reports',
        'b) Preparing Document Templates & Clauses with Desktop File Sync',
        '-Introduction',
        '-Preparing the Main',
        '-Agreement Clause Library',
        '-Overview Managing the',
        '-Clause Library Best Practices',
        'c) Preparing Document Templates & Clauses with Enhanced Contract Authoring',
        '-Introduction',
        '-Preparing the Main',
        '-Agreement Clause Library',
        '-Overview Managing the',
        '-Clause Library Best Practices',
        'd) Contract Compliance',
        '-Introduction',
        '-Contract',
        '-Requests',
        '-Release Order Contracts',
        '-No- release Order',
        '-Contracts Managing',
        '-Contracts Contract',
        '-Workspaces',
        'e) Managing Contract Compliance',
        '-Introduction to Contract Compliance',
        '-Creating Compliance',
        '-Contract Requests',
        '-Releases Against Contracts',
        '-Non-release Order Contracts',
        '-Contract Accumulators',
        '-Managing Contracts',
        '-Introduction to',
        '-Contract',
        '-Workspaces',
      ],
    },
    {
      title: 'SAP Ariba Integration',
      subpoints: [
        "a) SAP Ariba Integration: SAP Ariba Integration Points",
        "-Application landscape",
        "-Integration basics Suite",
        "-Integration",
        "-Strategic Sourcing",
        "-Integrations Procurement",
        "-Integrations Buyer",
        "-Network Integrations Supplier",
        "-Network Integrations",
        "-Catalog Enablement",
      ],
    },
    {
      title: 'SAP Ariba Procurement',
      subpoints: [
        'a) Buying Introduction',
        '-The Dashboard Catalogs Requisitions',
        '-Accounting',
        '-Approval',
        '-Managing POs',
        '-Receiving',
        '-SAP Ariba Mobile',
        '-Searching and',
        '-ReportingCollaboration',
        '-Demand Aggregation',
        'b) Requisition to Receiving',
        '-Introduction to Ariba',
        '-Procurement Application',
        '-Orientation Using Catalogs',
        '-Creating and Managing Requisitions',
        '-Accounting The Approval Process',
        '-Managing Purchase',
        '-OrdersReceiving Searching',
        '-& Reporting Appendix A:',
        '-Managing Budgets',
        '-Appendix B: Collaborative Requisitioning',
        '-Appendix C: Importing Requisitions',
        'c) Spot Buy Catalog',
        '-Getting Started with SAP Ariba Spot Buy',
        '-Catalog Implementing SAP Ariba Spot Buy',
        '-Catalog',
        'd) Invoicing Introduction Paper',
        '-Invoices',
        '-Electronic Invoices',
        '-Contract Based Invoices',
        '-Non- PO Invoices',
        '-Credit Memos Invoice',
        '-Approval Invoice',
        '-Reconciliation',
        '-OK2Pay and',
        '-Remittance Searching',
        '-Reporting Invoice',
        '-Conversion Service',
        'e) Creating and Managing Invoices',
        '-Introduction to Contract',
        '-Compliance Paper Invoices',
        '-PO-based Electronic',
        '-Invoices Contract-based',
        '-Invoices Non-PO Invoices',
        '-Credit Memos Invoice',
        '-Approval Invoice',
        '-Reconciliation OK2Pay',
        '-and Remittance',
        '-Searching and Reporting',
      ],
    },
    {
      title: 'SAP Ariba Supplier Management',
      subpoints: [
        "a) Supplier Performance Management Projects",
        "-Introduction",
        "-Application",
        "-Orientation",
        "-Create and Edit an SPM Project",
        "-Manage an SPM Project Planning",
        "-Phase",
        "-Manage an SPM Project Monitoring Phase",
        "-Managing Scorecards",
        "-Managing Surveys",
        "-SPM Reports",
        "b) Supplier Lifecycle Management",
        "-Introduction",
        "-Application",
        "-Orientation",
        "-Supplier Management Personas and User",
        "-Groups Managing Supplier Requests",
        "-Managing Supplier",
        "-Registrations Managing",
        "-Supplier Qualifications",
        "-Managing Preferred Suppliers",
        "-Managing Supplier",
        "-Disqualifications Managing Supplier",
        "-Contacts SAP Ariba Supplier Lifecycle and Performance Templates",
      ],
    },
    {
      title: 'SAP Ariba Sourcing',
      subpoints: [
        'a) Creating Events',
        '-Introduction',
        '-Application',
        '-Orientation',
        '-Building your Sourcing Event: Rules, Team,',
        '-Suppliers Building your Sourcing Event: Content,',
        '-Summary Running your Sourcing Event',
        '-Manually Awarding an Event',
        'b) Creating Events and Managing Projects',
        '-Application Orientation',
        '-Building your Sourcing',
        '-Event Running your Sourcing',
        '-Event Manually Awarding an',
        '-Event Creating Sourcing',
        '-Projects Adding Project',
        '-Details Working on a Project',
        '-Creating an Event within a',
        '-Project Completing the Project',
        'c) Creating and Managing a Sourcing Project',
        '-Application Orientation',
        '-Building your Sourcing',
        '-Event Running your Sourcing',
        '-Event Manually Awarding an Event',
        '-Creating Sourcing Projects',
        '-Adding Project Details',
        '-Working on a Project',
        '-Creating an Event within a',
        '-ProjectCompleting the Project',
        'd) Advanced',
        '-Features Introduction',
        '-Auction Types',
        '-Creating an Auction',
        '-Monitoring an Auction',
        '-Best Practices Appendix',
        'e) Creating Auctions',
        '-Introduction to Auctions Auction',
        '-Types',
        '-Creating an Auction',
        '-Monitoring an Auction',
        '-Best Practices',
        '-Introduction to Auctions Auction Types',
        '-Creating an Auction',
        '-Monitoring an',
        '-Auction Best Practice',
      ],
    },
    {
      title: 'Supplier Enablement: Buy Side in SAP Ariba',
      subpoints: [
        "a) Enabling Catalogs Catalog  ",
        "-Overview & Strategy Catalog ",
        "-Types & Configurables Template  ",
        "-Creation Training for Suppliers ",
        "-Catalog ",
        "-Administration  ",
        "-Appendix",
        "b)Managing and Maintaining Catalogs ",
        "-Introduction ",
        "-Using Catalogs  ",
        "-Loading  ",
        "-Catalogs ",
        "-Validation and Errors",
        "-Catalog Hierarchy ",
        "-Catalog Views ",
        "-Content Management  ",
        "-System BMEcat and CIF ",
        "-Fields Dynamic Filtering of  ",
        "-Catalogs ",
        "c) Standalone ",
        "–Catalogs Introduction",
        "-Using Catalogs ",
        "-Importing Catalogs  ",
        "-Validation and Errors",
        "-Catalog Hierarchy ",
        "-Catalog Views Contract",
        "-Compliance ",
        "-Content Management  ",
        "-System BMEcat and CIF ",
        "-fields Dynamic Filtering of Catalogs",
      ],
    },
    {
      title: 'Ariba Network: Planning and Executing Supplier Enablement for  Suppliers',
      subpoints: [
        "Overview of SAP Ariba Procurement SolutionsSupplier  ",
        "Enablement Concepts ",
        "Ariba Network Functionality  ",
        "Seller Summit ",
        "Supplier Education Channels ",
        "1.1 Outreach",
      ],
    },
  ];

  const keyFeatures = [
    "SAP Ariba is a B2B solution that allows you to connect to the world’s largest network of vendors and suppliers and enhance business collaboration with the right business partners.",
    "SAP Ariba allows organizations to connect with the right suppliers with deep visibility to your inside vendor and procurement management processes giving way to error free business transactions.",
    "With SAP Ariba, you can directly connect Ariba network with millions of suppliers meeting your business needs and managing supply chain.",
    "SAP Ariba network removes overall complexity in procurement process and suppliers and buyers can manage all key terms of vendor management on a single network.",
    "With acquisition of SAP, Ariba can easily integrate with different SAP ERP solutions like SAP ECC and S/4 HANA with easy to configure workflows to automate different processes in complete procurement cycle.",
    "You can easily integrate master and transactional data from different ERP solution to Ariba processes.",
  ];

  const keyFeatures2 = [
    "One of the key advantages of using Ariba solution is that it simplifies procurement and sourcing process with easy synchronization to SAP SRM and other ERP software.",
    "It enhances supplier, buyers and user experience by bringing in a digital transformation to the supply chain process.",
    "With cloud-based solution, SAP Ariba can be accessed from different locations. It requires very low initial capital cost for setting up the solution.",
    "With SAP Ariba, there is an easy setup of key procurement processes – Procure to pay (integration of purchase department with Accounts payable department), Procure to Order by maintaining shopping carts.",
    "There is easy transfer of master data. Organization structure, suppliers and GL data can be easily transferred to Ariba solution using optimal way of integration.",
    "SAP Ariba enables easy transfer of transactional data. Invoice details, goods receipt, PO details can be easily transferred to Ariba solution with optimum integration.",
    "We can use integration tool kit to connect ERP system with Ariba solution.",
  ];

  const faqs = [
    {
      question: "What is SAP ARIBA?",
      answer: "SAP Ariba is a cloud-based procurement solution that helps businesses streamline their procurement processes, from sourcing to payment.",
    },
    {
      question: "How does SAP Ariba work?",
      answer: "SAP Ariba connects buyers and suppliers on a single platform, facilitating collaboration, automation, and visibility throughout the procurement lifecycle.",
    },
    {
      question: "What are the key modules in SAP Ariba?",
      answer: "SAP Ariba consists of modules like Sourcing, Procurement, Contract Management, Supplier Management, and Invoice Management.",
    },
    {
      question: "How does Ariba benefit businesses?",
      answer: "Ariba improves efficiency, reduces costs, enhances supplier relationships, and provides real-time insights into procurement activities.",
    },
    {
      question: "Is SAP Ariba only for large enterprises?",
      answer: "While SAP Ariba is commonly used by large enterprises, it can also be scaled for mid-sized businesses seeking procurement process optimization",
    },
    {
      question: "What is Ariba Network?",
      answer: "The Ariba Network is a global trading community where buyers and suppliers collaborate, exchange documents, and conduct transactions electronically.",
    },
    {
      question: "Can SAP Ariba integrate with other ERP systems?",
      answer: "Yes, SAP Ariba can integrate with various ERP systems, enabling seamless data flow between procurement and other business processes.",
    },
    {
      question: "How does Ariba enhance supplier collaboration?",
      answer: "Ariba provides tools for supplier onboarding, performance monitoring, and collaboration, fostering stronger and more transparent relationships.",
    },
    {
      question: "What is Ariba Guided Buying?",
      answer: "Ariba Guided Buying is a user-friendly interface that guides users through the procurement process, ensuring compliance with organizational policies.",
    },
    {
      question: "WHow does Ariba support mobile procurement?",
      answer: "SAP Ariba offers mobile apps, allowing users to manage procurement activities, approvals, and collaboration on the go.",
    },
  ];

  const whatsData = [
    {
      title: 'What is SAP ARIBA ?',
      points: [
        "SAP Ariba is a cloud-based tool that links suppliers and buyers, making procurement easier and more cost-effective. It’s like a digital supply chain and procurement service that simplifies global business operations.",
        "In today’s world, managing your supply chain and working closely with suppliers is crucial for success. Suppliers need clear visibility into the procurement process to maintain efficiency and support business growth.",
        "Sapalogy taining provides SAP MM training in offline and online mode. Starting end user, consulting, implementation, support with real time SAP project based training.",
        "IT background, non IT background, freshers, experience can start their career in SAP irrespective of their background.",
        "Sapalogy is the best training institute in nagpur with the 100% job opportunities.",
      ]
    }
  ]

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const imageList = [
  //   { Image: dev1, alt: "Award 1" },
  //   { Image: dev2, alt: "Award 2" },
  //   { Image: dev3, alt: "Award 3" },
  //   { Image: dev4, alt: "Award 4" },
  //   { Image: dev5, alt: "Award 5" },
  //   { Image: dev6, alt: "Award 6" },
  //   { Image: dev7, alt: "Award 7" },
  //   { Image: dev8, alt: "Award 8" },
  // ];

  // const reviewList = [
  //   { Image: rev1, alt: "rev1" },
  //   { Image: rev2, alt: "rev1" },
  //   { Image: rev3, alt: "rev1" },
  //   { Image: rev4, alt: "rev1" },
  //   { Image: rev5, alt: "rev1" },
  // ]
  return (
    <div className="devops-page">
         <SideDropdown roadmapData={modulesData} />
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Best SAP ARIBA Training</h1>
          <p>
            SAP ARIBA Training by Sapalogy offers you the best SAP ARIBA(Advanced Business Application Programming) training in Nagpur for freshers and experienced candidates in Nagpur, with expert guidance and 100% guaranteed placement assistance. Our SAP training course covers every technical topic in detail and the student is left with real-time hands-on experience.
          </p>
          <br />
          <p>Our practical, Job-oriented training program will not only provide you with a certificate but also with knowledge equivalent to around 2+ years of field experience. We value your time as much as over. Hence we invented to train you in SAP ARIBA with S/4 HANA in just 4 months.</p>
          <ul className='points-list'>
            <li><FontAwesomeIcon icon={faFileAlt} />End user 1 month (ECC / S4 HANA)</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} />Configuration 2 months(ECC/S4 HANA)</li>
            <li><FontAwesomeIcon icon={faBriefcase} />Project 1 month</li>
          </ul>
          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> 6-months internship</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
        </ul>
        </div>
        <PageForm />
      </div>
   
      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>
      </div>
      <WhatsDevOps whatsData={whatsData} />
     
      <Roadmap heading="Roadmap to Learn SAP ARIBA" roadmapData={roadmapData} />
      <Syllabus heading="SAP ARIBA Training Syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="SAP ARIBA" keyFeatures={keyFeatures} />
      <KeyFeatures heading="SAP ARIBA & KEY BENEFITS" keyFeatures={keyFeatures2} />
      <section className="devops-certification-section">
        <h2 className="certification-heading">SAP ARIBA - ACCOUNT CONFIGURATION</h2>
        <div className="certification-content">
          <div className="certification-points">
            <p>In SAP Ariba, you can access and configure new accounts, set email notifications, electronic order and invoice routing and other account related configuration for new suppliers.</p>
          </div>
        </div>
      </section>

      <div className="batch-schedule-section">
        <h2>Upcoming Batch Schedule for SAP ARIBA Training</h2>
        <p>Sapalogy provides flexible timings to all our students. Here is the SAP ARIBA Training Class Schedule in our branches. If this schedule doesn’t match please let us know. We will try to arrange appropriate timings based on your flexible timings.</p>
        <div className="batch-schedule-table-wrapper">
        <table className="batch-schedule-table">
          <thead>
            <tr>
              <th>Course</th>
              <th>Batch Time</th>
              <th>Offline</th>
              <th>Online</th>
              <th>Enquire Now</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>SAP ARIBA</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
            <tr>
              <td>SAP FICO</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
            <tr>
              <td>SAP MM</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
            <tr>
              <td>SAP</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>

      <section className="devops-certification-section">
        <h2 className="certification-heading">SAP ARIBA CERTIFICATION</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
              <li>The SAP S/4HANA certification offered by Sapalogy will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
              <li>Sapalogy provides comprehensive guidance for your SAP S/4HANA global certification, ensuring a 100% passing guarantee in examinations such as SAP Certification, SAP S/4HANA Platform Development Certification, and various other global exams.</li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>
      <Roadmap heading="SAP modules" roadmapData={modulesData} />
      {/* devops project Section
            <div className="slider-container">
                <h2 className="slider-heading">Devops Project</h2>
                <RegularSlider sliderSettings={sliderSettings} images={imageList} />
            </div> */}

      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
        <h2 className="slider-heading">SAP ARIBA Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div> */}


      <div className="alumni-section">
        <h1>Our Alumni Works At</h1>
        <div className="full-screen-image">
          <img src={IMG} alt="Full Screen Image" />
        </div>
      </div>
      <Contact />

    </div>
  );
};

export default SAPARIBA;