import React, { useEffect, useRef, useState } from 'react';
import '../../Css/DevOps.css';
import certificateImg from '../../assets/certificate.webp';
import Contact from '../../pages/Contact'
import IMG from '../../assets/Work.webp'
import Roadmap from '../../Components/Roadmap';
import WhatsDevOps from '../../Components/WhatsDevOps'
import PageForm from '../../Components/PageForm';
import Syllabus from '../../Components/Syllabus';
import KeyFeatures from '../../Components/KeyFeatures';
import Faq from '../../Components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
// project slider image
import dev1 from '../../assets/Projects/Salesforce/1.png';
import dev2 from '../../assets/Projects/Salesforce/2.png';
import dev3 from '../../assets/Projects/Salesforce/3.png';
import dev4 from '../../assets/Projects/Salesforce/4.png';
import dev5 from '../../assets/Projects/Salesforce/5.png';
import dev6 from '../../assets/Projects/Salesforce/6.png';
import dev7 from '../../assets/Projects/Salesforce/7.png';
import dev8 from '../../assets/Projects/Salesforce/8.png';
import dev9 from '../../assets/Projects/Salesforce/9.png';
import dev10 from '../../assets/Projects/Salesforce/10.png';

import rev1 from '../../assets/Reviews/Salesforce/sf_rev1.png';
import rev2 from '../../assets/Reviews/Salesforce/sf_rev2.png';
import rev3 from '../../assets/Reviews/Salesforce/sf_rev3.png';
import rev4 from '../../assets/Reviews/Salesforce/sf_rev4.png';
import rev5 from '../../assets/Reviews/Salesforce/sf_rev5.png';
import RegularSlider from '../../Components/RegularSlider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const SalesforceAdmin = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);


  useEffect(() => {
    // Function to create the YouTube player
    const createPlayer = () => {
      if (window.YT && window.YT.Player) {
        playerRef.current = new window.YT.Player(videoRef.current, {
          videoId: 'syzh1IxEaak', // Updated YouTube video ID
          events: {
            onReady: (event) => {
              // event.target.mute(); // Mute the video to allow autoplay
              event.target.playVideo();
            },
          },
          playerVars: {
            loop: 1,
            controls: 0, // Hide video controls
            modestbranding: 1, // Minimal YouTube branding
            playlist: 'syzh1IxEaak', // Required for looping
          },
        });
      }
    };

    // Load YouTube IFrame API if it's not already loaded
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Set up callback for when the API is ready
      window.onYouTubeIframeAPIReady = () => {
        createPlayer();
      };
    } else {
      createPlayer();
    }
  }, []);


  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'What is salesforce admin?',
        'Salesforce Admin careers.',
        'Salesforce course.',
        'Salesforce certification.',
        'Salesforce job in India. ',
        'Salesforce job in nagpur.',
      ]
    },
    {
      title: ' Understanding Salesforce Basics',
      points: [
        'Learn the basics of Salesforce, including its architecture and data model.',
        'Understand Salesforce objects, fields, relationships, and record types.',
        'Familiarize yourself with the Salesforce user interface and navigation.',
      ]
    },
    {
      title: 'Mastering Salesforce Administration Tools',
      points: [
        'Explore Salesforce setup options and customize the platform to meet business needs.',
        'Learn about user management, roles, profiles, and permission sets.',
        'Dive into security settings, sharing rules, and data validation rules.',
      ]
    },
    {
      title: 'Working with Data and Reports',
      points: [
        'Learn to import, export, and manipulate data in Salesforce using data loader tools.',
        'Create and customize reports and dashboards to visualize data effectively.',
        'Understand data integrity best practices and data maintenance strategies.',
      ]
    },
    {
      title: ' Automation with Workflows and Processes',
      points: [
        'Explore workflow rules and process builder to automate business processes.',
        'Learn about approval processes and how to configure them in Salesforce.',
        'Understand the basics of Salesforce automation tools like flows and triggers.',
      ]
    },
    {
      title: ' Integration and AppExchange',
      points: [
        'Explore Salesforce integration options with external systems and APIs.',
        'Learn about AppExchange apps and how to install and configure them.',
        'Understand the basics of Salesforce Connect and external objects.',
      ]
    },
    {
      title: 'Advanced Topics and Best Practices',
      points: [
        'Explore advanced Salesforce admin topics like Lightning, communities, and mobile apps.',
        'Learn best practices for data, security, and performance in Salesforce.',
        'Stay updated with Salesforce releases, features, and certifications for a competitive edge.',
      ]
    },
    {
      title: 'Job Opportunities',
      points: [
        'Prepare for your Job search.',
        'Ready walkin in India.',
        'Tie up references.',
        'Get placed.',
      ]
    }
  ];

  const syllabus = [
    {
      title: 'Introduction to Cloud Computing And Salesforce Platform',
      subpoints: [
        'What is Cloud Computing',
        'SAAS (Salesforce.com)',
        'PAAS (Force.com)',
        'IAAS',
        'Introduction to Force.com Platform',
        'Declarative & Programmatic Options in Force.com',
        'Multi-tenant Architecture',
        'Sign-up for a Free Environment / Org',
        'Salesforce System overview',
        'Editions and Different Environments Available',
        'MVC Pattern',
      ],
    },
    {
      title: 'Data Model, Objects, Relationships And Fields',
      subpoints: [
        'Custom Objects and understand various',
        'properties in the Object definition section',
        'Standard objects',
        'Field and Data Types',
        'Custom Field Properties',
        'Field Dependencies',
        'Types of Relationship Fields',
        'Search Layouts',
        'Matching Rule',
        'Duplicate Rule',
      ],
    },
    {
      title: 'Salesforce Security Model And Its Features',
      subpoints: [
        'System Level or Org Level Security',
        'Profile and Permissions Sets',
        'Password Policy',
        'IP Address security',
        'Login hours',
        'Session settings',
        'Activations',
        'Page layout assignment',
        'Tab setting',
        'Role and Role Hierarchy',
        'Public Group',
        'Field level security',
        'Record Level and Object Level',
        'Organization wide defaults',
      ],
    },
    {
      title: 'Business Logic, Formulas & Validations',
      subpoints: [
        'Business Logic Mind Map',
        'Different Options to Implement Business',
        'Logic',
        'Functions',
        'Formula',
        'Validation Rules',
        'Rollup Summary Fields',
        'Record Types and Page Layouts',
      ],
    },
    {
      title: 'Business Process Automation In Salesforce',
      subpoints: [
        'Lightning flow',
        'Screen flow',
        'Trigger flow',
        'Schedule trigger flows',
        'Approval Process',
      ],
    },
    {
      title: 'Customer Management Using Salesforce Sales Cloud',
      subpoints: [
        'Sales Cloud',
        'Sales Process',
        'Accounts and Contacts Management',
        'Opportunities',
        'Activity',
      ],
    },
    {
      title: 'Customer Service Solution Using Salesforce Service Cloud',
      subpoints: [
        'Service Cloud',
        'Sales Cloud',
        'Email Templates',
      ],
    },
    {
      title: 'Data Handling And Processing In Salesforce',
      subpoints: [
        'Data Handling and Processing Overview',
        'Exporting data and Regular Backup',
        'External ID and Unique Fields',
        'Import Data',
        'Apex Data loader',
        'Import Wizard and Data Loader and compare the features',
        'Update',
        'Upsert operations',
        'Delete',
        'Hard Delete records.',
      ],
    },
    {
      title: 'Reports And Dashboards In Salesforcereports And Dashboards In Salesforce',
      subpoints: [
        'Report with and without modifications',
        'Tabular report, summary, Joined',
        'Matrix report',
        'User filters in reports',
        'Dashboard',
        'Bucket field',
      ],
    },
    {
      title: 'Others Basics Introduction',
      subpoints: [
        'MFA(Multifactor Authentication).',
        'AppExchange applications.',
        'Activity management',
        'Einstein Bots',
      ],
    },

  ];

  const keyFeatures = [
    "50+ Hrs Instructor Led Training",
    "Certificate guidance",
    "Mentor support",
    "Resume editing",
    "52 Hrs Project & Exercises",
    "100% Job oriented Training",
    "69 Hrs Self-placed videos",
    "Job Assistance",
    "Free demo class available",
    "Affordable fees structure",
    "Flexible Schedule",
    "Completed 1482+ batches"
  ];

  const faqs = [
    {
      question: "Salesforce administration?",
      answer: "Salesforce administration involves configuring and customizing the Salesforce platform to meet the specific needs of an organization. Admins manage users, security settings, data, and automation to ensure Salesforce operates efficiently and effectively.",
    },
    {
      question: "What are the roles and responsibilities of a Salesforce admin?",
      answer: "A Salesforce admin is responsible for user management, security settings, data maintenance, customization of Salesforce objects, workflows, reports, and dashboards. They also handle troubleshooting, training users, and staying updated with Salesforce releases and features.",
    },
    {
      question: "How can I become a Salesforce admin??",
      answer: "To become a Salesforce admin, you can start by learning Salesforce fundamentals through online courses, official Salesforce documentation, and hands-on practice in a Salesforce environment. Pursuing Salesforce admin certifications, such as Salesforce Certified Administrator, can also enhance your credentials.",
    },
    {
      question: "What are some common challenges faced by Salesforce admins??",
      answer: "Common challenges for Salesforce admins include data quality issues, managing complex automation, user adoption, integrating with external systems, and staying updated with new Salesforce features and best practices.",
    },
    {
      question: "How can I improve data management as a Salesforce admin??",
      answer: "To improve data management, Salesforce admins can implement data validation rules, clean up duplicate records, establish data governance policies, regularly audit data quality, and use data loader tools for import/export tasks.",
    },
    {
      question: "What are best practices for security in Salesforce administration?",
      answer: "Best practices for security include assigning proper user roles and profiles, setting up permission sets, implementing field-level security, enabling two-factor authentication, monitoring login activity, and regularly reviewing and updating security settings.",
    },
    {
      question: "How can I optimize performance in Salesforce as an admin?",
      answer: "Admins can optimize performance by optimizing page layouts, reducing the number of custom fields and workflows, using indexed fields for data queries, enabling caching, minimizing use of formula fields, and regularly monitoring system performance metrics.",
    },
    {
      question: "What resources are available for ongoing learning and support for Salesforce admins?",
      answer: "Salesforce Trailhead offers interactive learning modules and trails for Salesforce admins. Additionally, Salesforce community forums, webinars, user groups, and official Salesforce documentation provide valuable resources for ongoing learning and support.",
    },
  ];

  const whatsData = [
    {
      title: 'What is Salesforce admin?',
      points: [
        'Salesforce admin is responsible for configuring and maintaining the Salesforce platform to meet business needs.',
        'They handle user management, security settings, data management, and customization of Salesforce features.',
        'Salesforce admins play a key role in optimizing workflows, training users, and ensuring the smooth functioning of Salesforce within an organization.',
        'IT background, non IT background, Freshers, Experience can start their career in salesforce irrrespective of their background.',
        'Start by understanding the basics of platform, get training with Sapalogy and Join user group, Communities, and placement oriented training of salesforce will give you hand experience.',
        'Becoming a Salesforce pro is achievable through unwavering hard work, dedication, and commitment.',
      ]
    }
  ]

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const imageList = [
    { Image: dev1, alt: "Award 1" },
    { Image: dev2, alt: "Award 2" },
    { Image: dev3, alt: "Award 3" },
    { Image: dev4, alt: "Award 4" },
    { Image: dev5, alt: "Award 5" },
    { Image: dev6, alt: "Award 6" },
    { Image: dev7, alt: "Award 7" },
    { Image: dev8, alt: "Award 8" },
    { Image: dev9, alt: "Award 8" },
    { Image: dev10, alt: "Award 8" },
  ];

  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },
    { Image: rev5, alt: "rev1" },

  ]


  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Best Salesforce Admin Training</h1>
          <p>
            Salesforce Training by Sapalogy offers you  the Best Salesforce Admin Training in Nagpur for Fresher and Experience candidate in Nagpur, with Expert Guidance and 100% Guaranteed Placement Assistance. Our  Training Course cover  every technical topics in details and the student is left with Real-Time Hands-On Experience.          </p>
          <p>
            Our practical, Job-Oriented Training Program, will not only provide you with a certificate but also with knowledge equivalent to around 10+ years of Field Experience.We value your time as much as ours. Hence we intend to train you in Salesforce Admin in just 1.5 month.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
          <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> 6-months internship</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
            <li><FontAwesomeIcon icon={faBriefcase} />Salesforce software for practice for every student</li>
            <li><FontAwesomeIcon icon={faFileAlt} />Real time live project exposure</li>
          </ul>
        </div>

        <PageForm />
      </div>

      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>

      </div>
      <WhatsDevOps whatsData={whatsData} />
      <Roadmap heading="Roadmap to learn salesforce Admin" roadmapData={roadmapData} />
      <Syllabus heading="Salesforce Admin Syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="Salesforce administration" keyFeatures={keyFeatures} />

      <div className="batch-schedule-section">
        <h2>Upcoming Batch Schedule for Salesforce</h2>
        <p>
          Sapalogy Admin Training Institute in Nagpur provides flexible timings to all our students. Here is the Salesforce Training Schedule in our branches. If this schedule doesn’t match please let us know. We will try to arrange appropriate timings based on your flexible timings.
        </p>
        <div className="batch-schedule-table-wrapper">
        <table className="batch-schedule-table">
          <thead>
            <tr>
              <th>Course</th>
              <th>Batch Time</th>
              <th>Offline</th>
              <th>Online</th>
              <th>Enquire Now</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Salesforce(Admin)</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
            <tr>
              <td>Salesforce(Admin+development)	</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
            <tr>
              <td>Salesforce(LWC+integration)	</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div></div>



      <section className="devops-certification-section">
        <h2 className="certification-heading">Salesforce Certification</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
              <li>The Salesforce certification offered by Sapalogy will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
              <li>Sapalogy Admin training institute in Nagpur provides comprehensive guidance for your Salesforce global certification, ensuring a 100% passing guarantee in examinations such as Salesforce Administration Certification, Salesforce Platform Development Certification, and various other global exams.</li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>



      {/* devops project Section */}
      <div className="slider-container">
        <h2 className="slider-heading">Salesforce Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div>

      <Faq Faqs={faqs} />

      <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div>


      <div className="alumni-section">
        <h1>Our Alumni Works At</h1>
        <div className="full-screen-image">
          <img src={IMG} alt="Full Screen Image" />
        </div>
      </div>
      <Contact />

    </div>
  );
};

export default SalesforceAdmin;