import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import '../Css/Hero.css';

const Hero = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        course: '', // Add course field to form data
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission

        const submissionData = {
            access_key: 'fabb3cfb-5cb8-4f83-81ae-b1c5caf0797a', // Your access key
            name: formData.name,
            phone: formData.phone, // Accessing phone input value
            message: formData.message, // Accessing message textarea value
            course: formData.course, // Include selected course
            recipient_email: 'shivanihiware77@gmail.com', // Replace with your recipient email
        };

        try {
            const response = await fetch('https://api.web3forms.com/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Set content type to JSON
                    Accept: 'application/json', // Accept JSON response
                },
                body: JSON.stringify(submissionData), // Convert formData to JSON string
            });

            if (response.ok) {
                alert('Your message has been sent!'); // Success message
                e.target.reset(); // Reset the form after submission
                setFormData({
                    name: '',
                    phone: '',
                    message: '',
                    course: '', // Reset the course selection
                });
            } else {
                const errorData = await response.json(); // Parse error response
                alert(`There was a problem sending your message: ${errorData.message}`); // Error message
            }
        } catch (error) {
            console.error('Error during fetch:', error); // Log the error to the console
            alert('There was a problem sending your message. Please try again.'); // General error message
        }
    };

    return (
        <section className="hero-section">
            <div className="hero-content">
                <h1>LEARN WITH SAPALOGY FOR BEST JOB OPPORTUNITY</h1>
                <ul>
                    <li><FontAwesomeIcon icon={faCheckCircle} /> Expert Instructors</li>
                    <li><FontAwesomeIcon icon={faCheckCircle} /> Project-based learning</li>
                    <li><FontAwesomeIcon icon={faCheckCircle} /> Internship Opportunities</li>
                    <li><FontAwesomeIcon icon={faCheckCircle} /> Certification programs</li>
                </ul>
            </div>
            <div className="enquiry-form">
                <h2>For Free Demo Enquiry Now!</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Your Name"
                        required
                    />
                    <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="Your Phone Number"
                        required
                    />
                    <div style={{ marginBottom: '10px' }}>
                    
                        <select
                            id="course"
                            name="course"
                            value={formData.course}
                            onChange={handleChange}
                            required
                            style={{ width: '100%', padding: '8px', margin: '5px 0' }}
                        >
                            <option value="">-- Select a Course --</option>
                            <option value="SAP">SAP</option>
                            <option value="Salesforce">Salesforce</option>
                            <option value="AWS">AWS</option>
                            <option value="DevOps">DevOps</option>
                            <option value="Python">Python</option>
                            <option value="AI & ML">AI & ML</option>
                            <option value="Data Analytics">Data Analytics</option>
                            <option value="Business Analytics">Business Analytics</option>
                            <option value="Share Market">Share Market</option>
                        </select>
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </div>
        </section>
    );
};

export default Hero;
