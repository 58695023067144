import React, { useEffect, useRef, useState } from "react";
import "../Css/DevOps.css";
import certificateImg from "../assets/certificate.webp";
import Contact from "../pages/Contact";
import IMG from "../assets/Work.webp";
import Roadmap from "../Components/Roadmap";
import WhatsDevOps from "../Components/WhatsDevOps";
import PageForm from "../Components/PageForm";
import Syllabus from "../Components/Syllabus";
import KeyFeatures from "../Components/KeyFeatures";
import Faq from "../Components/Faq";
import "slick-carousel/slick/slick.css"; // Importing slick carousel styles
import "slick-carousel/slick/slick-theme.css"; // Importing slick theme styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faChalkboardTeacher,
  faBriefcase,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css";

import rev1 from "../assets/Reviews/sap/1.png";
import rev2 from "../assets/Reviews/sap/2.png";
import rev3 from "../assets/Reviews/sap/3.png";
import rev4 from "../assets/Reviews/sap/4.png";
import rev5 from "../assets/Reviews/sap/5.png";
import rev6 from "../assets/Reviews/sap/6.png";
import rev7 from "../assets/Reviews/sap/7.png";
import rev8 from "../assets/Reviews/sap/8.png";
import rev9 from "../assets/Reviews/sap/9.png";
import rev10 from "../assets/Reviews/sap/10.png";
import rev11 from "../assets/Reviews/sap/11.png";
import rev12 from "../assets/Reviews/sap/12.png";
import rev13 from "../assets/Reviews/sap/13.png";
import rev14 from "../assets/Reviews/sap/14.png";
import SideDropdown from "../Components/SideDropdown";
import RegularSlider from "../Components/RegularSlider";

const SAP = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    // Function to create the YouTube player
    const createPlayer = () => {
      if (window.YT && window.YT.Player) {
        playerRef.current = new window.YT.Player(videoRef.current, {
          videoId: "a2kql8BWKwI", // Updated YouTube video ID
          events: {
            onReady: (event) => {
              // event.target.mute(); // Mute the video to allow autoplay
              event.target.playVideo();
            },
          },
          playerVars: {
            loop: 1,
            controls: 0, // Hide video controls
            modestbranding: 1, // Minimal YouTube branding
            playlist: "a2kql8BWKwI", // Required for looping
          },
        });
      }
    };

    // Load YouTube IFrame API if it's not already loaded
    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Set up callback for when the API is ready
      window.onYouTubeIframeAPIReady = () => {
        createPlayer();
      };
    } else {
      createPlayer();
    }
  }, []);

  const roadmapData = [
    {
      title: "SAP BASIS",
      points: [
        "Sapalogy Training in Nagpur offers expert SAP BASIS training, empowering you to efficiently manage SAP systems and ensure seamless operations.",
      ],
      link: "/sap-basis-training-in-nagpur",
    },
    {
      title: "SAP MM",
      points: [
        "SAP MM (Materials Management) is a module in SAP ERP that manages procurement processes and inventory management. Sapalogy Institute in Nagpur offers comprehensive training in SAP MM for effective utilization ",
      ],
      link: "/sap-mm",
    },
    {
      title: "SAP FICO",
      points: [
        "SAP FICO, offered by Sapalogy Institute Nagpur, encompasses financial accounting and controlling modules within the SAP ERP system, facilitating comprehensive financial management and reporting for organizations",
      ],
      link: "/sap-fico",
    },
    {
      title: "SAP ABAP",
      points: [
        "SAP ABAP is a programming language used for developing applications within the SAP ecosystem. Sapalogy Institute in Nagpur is a training center specializing in SAP courses, including ABAP programming to equip professionals with the skills.",
      ],
      link: "/sap-abap",
    },
    {
      title: "SAP HCM",
      points: [
        "SAP HCM (Human Capital Management) is an integrated software suite by SAP that manages HR processes, from recruitment to payroll. Sapalogy Institute in Nagpur likely provides training in SAP HCM and related modules.",
      ],
      link: "/sap-hcm",
    },
    {
      title: "SAP SCM",
      points: [
        "SAP SCM (Supply Chain Management) is a software suite by SAP that integrates and optimizes key supply chain processes for enhanced efficiency. It enables businesses to manage procurement, production, and distribution seamlessly, ensuring streamlined operations.",
      ],
      link: "/sap-scm",
    },
    {
      title: "SAP ARIBA",
      points: [
        "SAP Ariba is a cloud-based procurement platform by SAP that streamlines and automates procurement processes, enhancing collaboration between buyers and suppliers. Sapalogy Institute Nagpur is an educational institution specializing in SAP training and certification.",
      ],
      link: "/sap-ariba",
    },
    {
      title: "SAP PP",
      points: [
        "SAP PP (Production Planning) is an SAP module that helps organizations manage and optimize their manufacturing processes, from planning and scheduling to execution. It integrates various business functions to streamline production.",
      ],
      link: "/sap-pp",
    },
    {
      title: "SAP SD",
      points: [
        "SAP SD (Sales and Distribution) is a module in SAP ERP that manages sales and customer service processes. It covers order-to-cash processes, including sales order processing, pricing, delivery, and billing. SAP SD to equip individuals with skills.",
      ],
      link: "/sap-sd",
    },
    {
      title: "SAP FIORI",
      points: [
        "SAP Fiori is a user experience (UX) design approach and set of design principles that enhance the usability and functionality of SAP applications. It provides a modern, responsive, and personalized user interface for a seamless and intuitive user experience in the SAP environment.",
      ],
      link: "/sap-fiori",
    },
    {
      title: "SAP QM",
      points: [
        "SAP QM (Quality Management) is a module in SAP ERP that facilitates quality control and assurance processes in manufacturing and production. It helps organizations manage quality planning, inspection, and certification, ensuring products meet specified standards and many more.",
      ],
      link: "/sap-qm",
    },
    {
      title: "SAP PM",
      points: [
        "SAP PM (Plant Maintenance) is a module in SAP ERP that helps organizations effectively manage and maintain their assets, equipment, and facilities. It covers processes such as preventive maintenance, work orders, and equipment tracking to optimize asset performance.",
      ],
      link: "/sap-pm",
    },
    {
      title: "SAP WM & EWM",
      points: [
        "SAP WM (Warehouse Management) focuses on efficient inventory management within a warehouse, while SAP EWM (Extended Warehouse Management) extends these capabilities with advanced features like labour management and slotting optimization for operations.",
      ],
      link: "/sap-wm-ewm",
    },
    {
      title: "SAP LE & SL",
      points: [
        "SAP LE (Logistics Execution) is a module that manages and optimizes warehouse and transportation processes, while SAP SL (Slotting) focuses on optimizing storage space and picking efficiency within a warehouse. Both modules contribute to streamlining supply chain operations in SAP systems.",
      ],
      link: "/sap-le-sl",
    },
  ];

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // const syllabus = [
  //   {
  //     title: 'Intruduction to Apex Programming.',
  //     subpoints: [
  //       'Basic Of C language',
  //       'What is Cloud Computing',
  //       'APEX Introduction',
  //       'APEX Classes',
  //       'APEX Development Process',
  //       'APEX Development Tools',
  //       'APEX Governer Limits'
  //     ],
  //   },
  //   {
  //     title: 'Data Types And Logic Control Statements',
  //     subpoints: [
  //       'Overview of Apex Data Types',
  //       'Primitive Data Types',
  //       'Non Primitive Datatypes',
  //       'SObject Data Types',
  //       'Enum Data Types',
  //       'Collections Data Types',
  //       'Apex Operators',
  //       'Apex Control Statements',
  //       'Executing Apex Programs'
  //     ],
  //   },
  //   {
  //     title: 'APEX Classes',
  //     subpoints: [
  //       'Apex Classes',
  //       'Access Modifiers',
  //       'Apex Class Constructors',
  //       'Apex Class Variables and Methods',
  //       'Inheritance, Sharing, and Interface',
  //       'Accessing Apex Class',
  //       'Apex Keywords and Methods',
  //     ],
  //   },
  //   {
  //     title: 'Implementing SOQL And SOSL Queries',
  //     subpoints: [
  //       'Standard Object and Field API Names',
  //       'Relationships in Apex',
  //       'SOQL Function, SOQL Bindings and SOQL For Loops',
  //       'Dynamic Query Creation',
  //       'Relationship Query and its Types',
  //       'Child to Parent Querry',
  //       'Parent to Child Querry'
  //     ],
  //   },
  //   {
  //     title: 'Data Manipulation Language',
  //     subpoints: [
  //       ' Data Manipulation Language',
  //       'Loops in DML',
  //       'Database.DML Options',
  //       'Transaction Control',
  //       'Ways to invoke DML Operations',
  //       'Invoking DML Operations',
  //     ],
  //   },
  //   {
  //     title: 'Trigger In APEX',
  //     subpoints: [
  //       'Apex Triggers and its Types',
  //       'Order of Execution of Trigger',
  //       'Trigger Syntax and Events',
  //       'Trigger Context Variables',
  //       'Bulkify Triggers',
  //       'Trigger Helper Class Pattern',
  //       'Best Practice of Trigger'
  //     ],
  //   },
  //   {
  //     title: 'Batch Classes',
  //     subpoints: [
  //       'Batch Class In Apex',
  //       'Batch Class Methods',
  //       'Schedule Methods In Batch Class',
  //       'Cronn Expressions',
  //       'Email Programming',
  //       'Future Methods',
  //       'Asynchronous Apex VS Synchronous Apex'
  //     ],
  //   },
  //   {
  //     title: 'Exception Handling',
  //     subpoints: [
  //       'Exceptions',
  //       'Exception Statements',
  //       'System-Defined Exception',
  //       'Exception Methods',
  //       'Catching Different Exception Types',
  //       'Try catch and finally methods'
  //     ],
  //   },
  //   {
  //     title: 'APEX Testing Essentials',
  //     subpoints: [
  //       'Write and run Apex Tests',
  //       'Create Tests data',
  //       'Running Test Units',
  //       'Testing Best Practices',
  //       'Code Coverage',
  //       'Test Classes Methods'
  //     ],
  //   }
  // ];

  const keyFeatures = [
    "152+ Hrs Instructor-Led Training",
    "Certficate Guidance",
    "Mentor Support",
    "Resume Editing",
    "52 Hrs Project & Exercises",
    "100% Job Oriented Training",
    "36Hrs Self-Placed Videos",
    "Job Assistance",
    "Free Demo Class Available",
    "Affordable Fees Stucture",
    "Flexible Schedule",
    "Completed 1500+ Batches",
  ];

  const faqs = [
    {
      question: "What is SAP?",
      answer:
        "SAP stands for Systems, Applications, and Products in Data Processing. It is a leading enterprise resource planning (ERP) software used by businesses to manage various aspects such as finances, sales, procurement, human resources, and more.",
    },
    {
      question: "What are the benefits of SAP certification?",
      answer:
        "SAP certification enhances your skills and credibility in using SAP software, leading to better job opportunities, higher salaries, and increased chances of career advancement in the IT industry.",
    },
    {
      question: "What are the different modules available in SAP?",
      answer:
        "SAP offers a wide range of modules catering to different business functions. Some popular modules include SAP Finance (FI), SAP Sales and Distribution (SD), SAP Material Management (MM), SAP Human Capital Management (HCM), and SAP Production Planning (PP).",
    },
    {
      question: "How long does it take to learn SAP?",
      answer:
        "The time required to learn SAP varies depending on factors such as your prior experience, the specific modules you want to learn, and the intensity of your training. Generally, it can take anywhere from a few weeks to several months to gain proficiency in SAP.",
    },
    {
      question: "Is SAP difficult to learn for beginners?",
      answer:
        "SAP can be challenging for beginners due to its complexity and the vast range of functionalities it offers. However, with dedication, structured learning, and hands-on practice, beginners can gradually master SAP concepts and become proficient users.",
    },
    {
      question: "What are SAP implementation challenges?",
      answer:
        "SAP implementation challenges may include high costs, integration complexities with existing systems, resistance from employees to adapt to new processes, data migration issues, and ensuring successful user adoption and training.",
    },
    {
      question: "What is SAP HANA?",
      answer:
        "SAP HANA is an in-memory database and application platform that accelerates data processing and analytics in real-time. It enables businesses to access and analyze large volumes of data swiftly, leading to faster decision-making and improved business agility.",
    },
    {
      question: "What are the job roles in SAP?",
      answer:
        "Job roles in SAP vary based on expertise and specialization. Some common SAP job roles include SAP Consultant, SAP Developer, SAP Basis Administrator, SAP Project Manager, and SAP Functional Analyst, each focusing on different aspects of SAP implementation, customization, and support.",
    },
  ];

  const whatsData = [
    {
      title: "What is SAP ?",
      points: [
        "SAP Stands for system application and products in data processing. SAP is the most used ERP software.",
        "The various products and modules offered by SAP ERP enable customers to perform day to day business processess, such as sales, production, accounting, HR and finance.",
        "For every business processess SAP has their specific modules like SAP MM, SAP FICO, SAP HCM, SAP PP and many more.",
        "Sapalogy provides real time project based SAP training in all SAP modules.",
        "IT background, non IT background, Freshers, experience can start their carrer in SAP irrespective of their background.",
        "Start by understanding the basics of platform, get training with sapalogy and join communities and placement oriented training of salesforce will give you hand experiences.",
        "Becoming a SAP Pro in a specific module is achievable through unwavering hard work, dedication, and commitment.",
      ],
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },
    { Image: rev5, alt: "rev1" },
    { Image: rev6, alt: "rev1" },
    { Image: rev7, alt: "rev1" },
    { Image: rev8, alt: "rev1" },
    { Image: rev9, alt: "rev1" },
    { Image: rev10, alt: "rev1" },
    { Image: rev11, alt: "rev1" },
    { Image: rev12, alt: "rev1" },
    { Image: rev13, alt: "rev1" },
    { Image: rev14, alt: "rev1" },
  ];

  return (
    <div className="devops-page">
      <SideDropdown roadmapData={roadmapData} />
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>

        <div className="left-content">
          <h1>Best SAP Training</h1>
          <p>
            Best SAP Training by Sapalogy has well-structured SAP Training
            program. The SAP training in nagpur as well as certification courses
            help you to maximize the productivity of your Sales, Marketing or
            Admin tasks by tapping into the vast resources of Salesforce.
          </p>
          <br />
          <p>
            Join us, Best SAP Classes in nagpur to build up your career in the
            booming sector and add the hot skill to your resume. Our subject
            expert provides the best real-time projects, examples, and learnings
            in the SAP classes in nagpur. SAP SERVER also provided by Sapalogy !
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li>
              <FontAwesomeIcon icon={faFileAlt} /> Resume preparation
            </li>
            <li>
              <FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice
            </li>
            <li>
              <FontAwesomeIcon icon={faBriefcase} /> 6-months internship
            </li>
            <li>
              <FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities
              guaranteed program
            </li>
          </ul>
        </div>

        <PageForm />
      </div>
  
      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>
      </div>
      <WhatsDevOps whatsData={whatsData} />

      {/* <Syllabus heading="SAPTraining Syllabus" Syllabus={syllabus} /> */}
      <KeyFeatures heading="SAP Training" keyFeatures={keyFeatures} />

      <div className="batch-schedule-section">
        <h2>Upcoming Batch Schedule for SAP Training</h2>
        <p>
          Sapalogy provides flexible timings to all our students. Here is the
          SAP Training Class Schedule in our branches. If this schedule doesn’t
          match your availability, please let us know. We will try to arrange
          appropriate timings based on your flexible timings.
        </p>
        <div className="batch-schedule-table-wrapper">
          <table className="batch-schedule-table">
            <thead>
              <tr>
                <th>Course</th>
                <th>Batch Time</th>
                <th>Offline</th>
                <th>Online</th>
                <th>Enquire Now</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>SAP MM</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td>
                  <a href="/contact">Enquire Now</a>
                </td>
              </tr>
              <tr>
                <td>SAP FICO</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td>
                  <a href="/contact">Enquire Now</a>
                </td>
              </tr>
              <tr>
                <td>SAP SD</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td>
                  <a href="/contact">Enquire Now</a>
                </td>
              </tr>
              <tr>
                <td>SAP HCM</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td>
                  <a href="/contact">Enquire Now</a>
                </td>
              </tr>
              <tr>
                <td>SAP ABAP</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td>
                  <a href="/contact">Enquire Now</a>
                </td>
              </tr>
              <tr>
                <td>SAP PP</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td>
                  <a href="/contact">Enquire Now</a>
                </td>
              </tr>
              <tr>
                <td>SAP ARIBA</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td>
                  <a href="/contact">Enquire Now</a>
                </td>
              </tr>
              <tr>
                <td>SAP SCM</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td>
                  <a href="/contact">Enquire Now</a>
                </td>
              </tr>

              {/* Add more rows as needed */}
            </tbody>
          </table>
        </div>
      </div>

      <section className="devops-certification-section">
        <h2 className="certification-heading">SAP Certification</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>
                Sapalogy training certification will serve as proof that the
                courses were completed by Sapalogy.
              </li>
              <li>
                The SAP S/4HANA Certification offered by Sapalogy will equip you
                with valuable skills, enhancing your competitiveness in the job
                market.
              </li>
              <li>
                Sapalogy provides comprehensive guidance for your SAP S/4HANA
                global certification, ensuring a 100% passing guarantee in
                examinations such as Salesforce Administration Certification,
                SAP S/4 HANA Platform Development Certification, and various
                other global exams.
              </li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>
      <Roadmap heading="SAP modules" roadmapData={roadmapData} />
      {/* devops project Section */}
      {/* <div className="slider-container">
        <h2 className="slider-heading">Salesforce Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div> */}

      <Faq Faqs={faqs} />

      <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div>

      <div className="alumni-section">
        <h1>Our Alumni Works At</h1>
        <div className="full-screen-image">
          <img src={IMG} alt="Full Screen Image" />
        </div>
      </div>
      <Contact />
    </div>
  );
};

export default SAP;
