import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // If using React Router
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../Css/DevOps.css"; // Assuming CSS is in a separate file

const SideDropdown = ({ roadmapData }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate(); // For React Router navigation

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLinkClick = (link) => {
    navigate(link); // React Router's navigate function
  };

  return (
    <div className="side-dropdown-container">
      {/* Icon to open the dropdown */}
      <div className="icon-container" onClick={toggleDropdown}>
        <p>Explore More Modules</p>
      </div>

      {/* Side dropdown */}
      <div className={`side-dropdown ${isDropdownOpen ? "open" : ""}`}>
        {/* Close button inside the dropdown */}
        <div className="close-btn-container" onClick={toggleDropdown}>
          <i className="fas fa-times"></i> {/* Font Awesome icon for closing */}
        </div>

        <ul>
          {roadmapData.map((item, index) => (
            <li key={index} onClick={() => handleLinkClick(item.link)}>
              {item.title}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideDropdown;
