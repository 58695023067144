import React, { useEffect, useRef } from 'react';
import '../../Css/DevOps.css';
import certificateImg from '../../assets/certificate.webp';
import Contact from '../Contact'
import IMG from '../../assets/Work.webp'
import Roadmap from '../../Components/Roadmap';
import WhatsDevOps from '../../Components/WhatsDevOps'
import PageForm from '../../Components/PageForm';
import Syllabus from '../../Components/Syllabus';
import KeyFeatures from '../../Components/KeyFeatures';
import Faq from '../../Components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
import dev1 from '../../assets/Projects/Devops/DEV1.png';
import dev2 from '../../assets/Projects/Devops/DEV2.png';
import dev3 from '../../assets/Projects/Devops/DEV3.png';
import dev4 from '../../assets/Projects/Devops/DEV4.png';
import dev5 from '../../assets/Projects/Devops/DEV5.png';
import dev6 from '../../assets/Projects/Devops/DEV6.png';
import dev7 from '../../assets/Projects/Devops/DEV7.png';
import dev8 from '../../assets/Projects/Devops/DEV8.png';
import rev1 from '../../assets/Reviews/DEVOPS/devrev1.png';
import rev2 from '../../assets/Reviews/DEVOPS/devrev2.png';
import rev3 from '../../assets/Reviews/DEVOPS/devrev3.png';
import rev4 from '../../assets/Reviews/DEVOPS/devrev4.png';
import rev5 from '../../assets/Reviews/DEVOPS/devrev5.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import SideDropdown from '../../Components/SideDropdown';



const SAPWMEWM = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);


  // useEffect(() => {
  //   // Function to create the YouTube player
  //   const createPlayer = () => {
  //     if (window.YT && window.YT.Player) {
  //       playerRef.current = new window.YT.Player(videoRef.current, {
  //         videoId: 'i3HdVH6gdfE', // Updated YouTube video ID
  //         events: {
  //           onReady: (event) => {
  //             event.target.mute(); // Mute the video to allow autoplay
  //             // event.target.playVideo();
  //           },
  //         },
  //         playerVars: {
  //           loop: 1,
  //           controls: 0, // Hide video controls
  //           modestbranding: 1, // Minimal YouTube branding
  //           playlist: 'i3HdVH6gdfE', // Required for looping
  //         },
  //       });
  //     }
  //   };

  //   // Load YouTube IFrame API if it's not already loaded
  //   if (!window.YT) {
  //     const tag = document.createElement('script');
  //     tag.src = 'https://www.youtube.com/iframe_api';
  //     const firstScriptTag = document.getElementsByTagName('script')[0];
  //     firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  //     // Set up callback for when the API is ready
  //     window.onYouTubeIframeAPIReady = () => {
  //       createPlayer();
  //     };
  //   } else {
  //     createPlayer();
  //   }
  // }, []);


  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'What is SAP?',
        'What is SAP WM & EWM?',
        'What is SAP HANA?',
        'SAP course',
        'SAP certification',
        'SAP jobs in india',
        'SAP jobs in nagpur',
      ]
    },
    {
      title: ' System Design and Configuration',
      points: [
        'Design the SAP WM & EWM system architecture.',
        'Configure SAP WM to meet basic warehouse management needs.',
        'Implement SAP EWM functionalities for advanced warehouse processes.',
        'Integrate SAP WM & EWM with other relevant SAP modules (e.g., SAP ERP, SAP S/4HANA).',
        'Conduct thorough testing to ensure system stability and functionality.',
      ]
    },
    {
      title: 'Data Migration and Master Data Setup',
      points: [
        'Identify and migrate relevant data from legacy systems to SAP WM & EWM.',
        'Set up master data, including materials, storage bins, and warehouse structures.',
        'Validate data accuracy and completeness.',
      ]
    },
    {
      title: 'Go-Live and Stabilization',
      points: [
        'Execute the system go-live with a carefully planned cutover strategy.',
        'Monitor system performance and resolve any issues promptly.',
        'Provide post-implementation support and training.',
        'Gather feedback from users and make necessary adjustments.',
      ]
    },
    {
      title: 'Optimization and Continuous Improvement',
      points: [
        'Conduct regular system audits to identify areas for improvement.',
        'Implement enhancements to optimize warehouse processes.',
        'Stay updated with SAP releases and apply relevant patches or updates.',
        'Explore additional SAP EWM features and functionalities.',
      ]
    },
    {
      title: 'Integration with Advanced Technologies',
      points: [
        'Explore and integrate emerging technologies such as IoT, RFID, and automation.',
        'Evaluate the use of analytics and business intelligence tools for warehouse optimization.',
        'Investigate possibilities for integrating with external systems and partners.',
      ]
    },
    {
      title: 'Assessment and Planning',
      points: [
        'Conduct current state analysis of existing warehouse processes.',
        'Define business requirements and identify pain points.',
        'Evaluate the need for SAP EWM based on advanced features and capabilities.',
        'Engage stakeholders to gather input and expectations.',
        'Develop a high-level project plan and budget.',
      ]
    },
    {
      title: 'Resume preparation',
      points: [
        'Include keywords',
        'How to prepare reume',
        'How to prepare resume for freshers.',
        'Show impact',
        'Include soft skills',
        'Be unique',
      ]
    }
  ];

  const syllabus = [
    {
      title: 'SAP WM+EWM - Introduction',
      subpoints: [
        'Warehouse management overview in SAP R/3',
        'Organization Data',
        'Warehouse Structure',
        'Warehouse Master Data',
        'WM Material master view',
      ],
    },
    {
      title: 'Organization data',
      subpoints: [
        'Define Warehouse structure',
        'Master Data',
        'Transfer Requirement',
      ],
    },
    {
      title: 'Transfer order',
      subpoints: [
        'Number Range for TO',
        'Create TO w.r.t Transfer Requirement',
        'Create TO w.r.t Posting change notice no',
        'Create TO w.r.t Storage unit',
        'Create TO w.r.t Material document',
        'Create TO w.r.t Inbound delivery',
        'T.O. Creation In Background',
        'Confirm the Transfer Order',
        'Cancel the Transfer Order',
        'Print Transfer Order',
      ],
    },
    {
      title: 'Put away strategies',
      subpoints: [
        'Next to empty bin',
        'Fixed Bin',
        'Open Storage',
        'Addition to existing bin',
        'Pallets',
        'Fixed bin strategy',
      ],
    },
    {
      title: 'Picking strategies',
      subpoints: [
        'Shelf life expired',
        'FIFO',
        'Stringent FIFO',
        'LIFO',
      ],
    },
    {
      title: 'Warehouse management business scenario’s (mm)',
      subpoints: [
        'Goods Receipt processing with inbound delivery (with ASN)',
        'Goods receipt processing without inbound delivery',
        'Goods issue for internal consumption',
        'Replenishment Control for Storage Type',
      ],
    },
    {
      title: 'Warehouse movement types',
      subpoints: [
        'Define reference movement types for WM',
        'Assign reference movement types to WM movement types',
      ],
    },
    {
      title: 'Search strategies',
      subpoints: [
        'Storage type search strategies',
        'Storage section search strategies',
        'Storage bin type search strategies',

      ],
    },
    {
      title: 'Batch management in wm',
      subpoints: [
        'Condition Tables',
        'Access Sequence',
        'Strategy Types',
        'WM Batch Search Procedure',
        'Batch Status in WM',
        'Characteristics and class',
        'SLED in Batch in WM',
        'Manual and Automatic Batch Display in WM',


      ],
    }

  ];

  const keyFeatures = [
    "123+ Hours course duration",
    "100% Job oriented Training",
    "Industry expert faculties",
    "Free demo class available",
    "Completed 200+ Batches",
    "Certifaction guidance",
  ];

  const faqs = [
    {
      question: "What is SAP WM?",
      answer: "SAP Warehouse Management (WM) is a module that provides efficient control of warehouse processes, optimizing the use of resources and ensuring accurate inventory management.",
    },
    {
      question: "What is SAP EWM?",
      answer: "SAP Extended Warehouse Management (EWM) is an advanced warehouse solution that offers more functionality than SAP WM, including improved flexibility, automation, and support for complex warehouse processes",
    },
    {
      question: "Differences between SAP WM and EWM?",
      answer: "EWM provides more advanced features like slotting, labor management, and wave management compared to WM. EWM is recommended for complex warehouse requirements, while WM is suitable for simpler operations.",
    },
    {
      question: "What is the purpose of a Warehouse Management System (WMS)?",
      answer: "WMS, like SAP WM and EWM, helps organizations manage and control warehouse activities, including receiving, picking, packing, and shipping, to optimize inventory and increase efficiency.",
    },
    {
      question: "Key features of SAP EWM?",
      answer: "EWM includes features such as advanced slotting, labor management, yard management, cross-docking, and value-added services, providing a comprehensive solution for complex warehouse operations.",
    },
    {
      question: "How does SAP EWM integrate with other SAP modules?",
      answer: "EWM integrates with other SAP modules like SAP ERP, SAP S/4HANA, and SAP Transportation Management (TM) to ensure seamless data flow and coordination between different business processes.",
    },
    {
      question: "What is the significance of Yard Management in SAP EWM?",
      answer: "Yard Management in EWM focuses on optimizing the use of space within the warehouse yard, managing trailer movements, and improving overall efficiency in the transfer of goods between the warehouse and transportation.",
    },
    {
      question: "Explain the concept of Wave Management in SAP EWM",
      answer: "Wave Management in EWM allows the grouping of similar or related tasks, like picking orders, to be processed together, optimizing the use of resources and improving overall efficiency in warehouse operations.",
    },
    {
      question: "How does SAP EWM support mobile solutions?",
      answer: "EWM supports mobile solutions for tasks like picking, packing, and inventory management, allowing warehouse workers to use handheld devices for real-time data entry and retrieval.",
    },
    {
      question: "Advantages of implementing SAP WM or EWM?",
      answer: "Implementing SAP WM or EWM can lead to improved inventory accuracy, increased efficiency in warehouse processes, better visibility into stock levels, and enhanced control over logistics operations.",
    }
  ];
  const modulesData = [
    {
      title: 'SAP',
      points: [
          'Sapalogy Training in Nagpur offers expert SAP training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
      ],
      link: "/best-sap-training"
  },
    {
      title: 'SAP BASIS',
      points: [
        'Sapalogy Training in Nagpur offers expert SAP BASIS training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
      ],
      link: "/sap-basis-training-in-nagpur"
    },
    {
      title: 'SAP MM',
      points: [
        'SAP MM (Materials Management) is a module in SAP ERP that manages procurement processes and inventory management. Sapalogy Institute in Nagpur offers comprehensive training in SAP MM for effective utilization ',
      ],
      link: "/sap-mm"
    },
    {
      title: 'SAP FICO',
      points: [
        'SAP FICO, offered by Sapalogy Institute Nagpur, encompasses financial accounting and controlling modules within the SAP ERP system, facilitating comprehensive financial management and reporting for organizations'
      ],
      link: "/sap-fico"
    },
    {
      title: 'SAP ABAP',
      points: [
        'SAP ABAP is a programming language used for developing applications within the SAP ecosystem. Sapalogy Institute in Nagpur is a training center specializing in SAP courses, including ABAP programming to equip professionals with the skills.',
      ],
      link: "/sap-abap",
    },
    {
      title: 'SAP HCM',
      points: [
        'SAP HCM (Human Capital Management) is an integrated software suite by SAP that manages HR processes, from recruitment to payroll. Sapalogy Institute in Nagpur likely provides training in SAP HCM and related modules.'
      ],
      link: "/sap-hcm",
    },
    {
      title: 'SAP SCM',
      points: [
        'SAP SCM (Supply Chain Management) is a software suite by SAP that integrates and optimizes key supply chain processes for enhanced efficiency. It enables businesses to manage procurement, production, and distribution seamlessly, ensuring streamlined operations.',
      ],
      link: "/sap-scm"
    },
    {
      title: 'SAP ARIBA',
      points: [
        'SAP Ariba is a cloud-based procurement platform by SAP that streamlines and automates procurement processes, enhancing collaboration between buyers and suppliers. Sapalogy Institute Nagpur is an educational institution specializing in SAP training and certification.',
      ],
      link: "/sap-ariba"
    },
    {
      title: 'SAP PP',
      points: [
        'SAP PP (Production Planning) is an SAP module that helps organizations manage and optimize their manufacturing processes, from planning and scheduling to execution. It integrates various business functions to streamline production.',
      ],
      link: "/sap-pp"
    },
    {
      title: 'SAP SD',
      points: [
        'SAP SD (Sales and Distribution) is a module in SAP ERP that manages sales and customer service processes. It covers order-to-cash processes, including sales order processing, pricing, delivery, and billing. SAP SD to equip individuals with skills.',
      ],
      link: "/sap-sd"
    },
    {
      title: 'SAP FIORI',
      points: [
        'SAP Fiori is a user experience (UX) design approach and set of design principles that enhance the usability and functionality of SAP applications. It provides a modern, responsive, and personalized user interface for a seamless and intuitive user experience in the SAP environment.',
      ],
      link: "/sap-fiori"
    },
    {
      title: 'SAP QM',
      points: [
        'SAP QM (Quality Management) is a module in SAP ERP that facilitates quality control and assurance processes in manufacturing and production. It helps organizations manage quality planning, inspection, and certification, ensuring products meet specified standards and many more.',
      ],
      link: "/sap-qm"
    },
    {
      title: 'SAP PM',
      points: [
        'SAP PM (Plant Maintenance) is a module in SAP ERP that helps organizations effectively manage and maintain their assets, equipment, and facilities. It covers processes such as preventive maintenance, work orders, and equipment tracking to optimize asset performance.',
      ],
      link: "/sap-pm"
    },
    {
      title: 'SAP WM & EWM',
      points: [
        'SAP WM (Warehouse Management) focuses on efficient inventory management within a warehouse, while SAP EWM (Extended Warehouse Management) extends these capabilities with advanced features like labour management and slotting optimization for operations.',
      ],
      link: "/sap-wm-ewm"
    },
    {
      title: 'SAP LE & SL',
      points: [
        'SAP LE (Logistics Execution) is a module that manages and optimizes warehouse and transportation processes, while SAP SL (Slotting) focuses on optimizing storage space and picking efficiency within a warehouse. Both modules contribute to streamlining supply chain operations in SAP systems.',
      ],
      link: "/sap-le-sl"
    },
  ];
  const whatsData = [
    {
      title: 'What is SAP WM (Warehouse Management) ?',
      points: [
        ' Core functionality for managing warehouse processes.',
        ' Handles goods receipt, goods issue, and stock transfers.',
        ' Optimizes storage bin utilization and warehouse layout.',
        ' Provides real-time visibility into inventory levels.',
        ' Supports RF (Radio Frequency) technology for efficient data entry.',
        ' Integrates with other SAP modules for end-to-end business processes.',
        ' Suitable for organizations with standard warehousing needs.',
      ]
    },
    {
      title: 'What is SAP EWM (Extended Warehouse Management) ?',
      points: [
        'Advanced warehouse management solution for complex logistics scenarios.',
        'Offers advanced features such as slotting, labor management, and yard management.',
        'Enables multi-level storage hierarchy for high flexibility.',
        'Supports wave management for efficient order picking.',
        'Incorporates task and resource management for optimized operations.',
        'Provides real-time analytics and reporting for decision-making.',
        'Ideal for organizations with complex distribution networks and diverse logistics requirements.',
      ]
    }
  ]

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imageList = [
    { Image: dev1, alt: "Award 1" },
    { Image: dev2, alt: "Award 2" },
    { Image: dev3, alt: "Award 3" },
    { Image: dev4, alt: "Award 4" },
    { Image: dev5, alt: "Award 5" },
    { Image: dev6, alt: "Award 6" },
    { Image: dev7, alt: "Award 7" },
    { Image: dev8, alt: "Award 8" },
  ];

  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },
    { Image: rev5, alt: "rev1" },
  ]


  return (
    <div className="devops-page">
         <SideDropdown roadmapData={modulesData} />
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Best SAP WM & EWM Training</h1>
          <p>
            Sapalogy offers you the best SAP WM & EWM training in Nagpur for freshers and experience candidate in Nagpur, with expert guidance and 100% guaranteed placement assistance. Our SAP training course covers every technical topics in details and the student is left with real-time hand on experience.
          </p>
          <br />
          <p>
            Our practical, Job-oriented training program will not only provide you with a certificate but also with knowledge equivalent to around 2+ years of field experience. We value your time as much as over. Hence we invented to train you in SAP WM & EWM with S/4 HANA in just 4 months.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faFileAlt} /> End user 1 month (ECC / S4 HANA)</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Configuration 2 months (ECC / S4 HANA)</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> Project 1 month</li>
            <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> 6-months internship</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
          </ul>
        </div>

        <PageForm />
      </div>


      {/* <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>

      </div> */}
      <Roadmap heading="Roadmap to learn SAP WM & EWM" roadmapData={roadmapData} />
      <WhatsDevOps whatsData={whatsData} />
      <Syllabus heading="SAP WM & EWM Training syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="SAP WM & EWM Training" keyFeatures={keyFeatures} />

      <div className="batch-schedule-section">
        <h2>Upcoming Batch Schedule for SAP WM & EWM Training</h2>
        <p>
          Sapalogy provides flexible timings to all our students. Here are the SAP WM & EWM Training Classes in Nagpur Schedule in our branches. If this schedule doesn’t match please let us know. We will try to arrange appropriate timings based on your flexible timing.
        </p>
        <div className="batch-schedule-table-wrapper">
          <table className="batch-schedule-table">
            <thead>
              <tr>
                <th>Course</th>
                <th>Batch Time</th>
                <th>Offline</th>
                <th>Online</th>
                <th>Enquire Now</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>SAP SAP WM & EWM</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td><a href="/contact">Enquire Now</a></td>
              </tr>
              <tr>
                <td>SAP PM</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td><a href="/contact">Enquire Now</a></td>
              </tr>
              <tr>
                <td>SAP FICO</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td><a href="/contact">Enquire Now</a></td>
              </tr>
              <tr>
                <td>SAP</td>
                <td>Starts every week</td>
                <td>Nagpur</td>
                <td>India</td>
                <td><a href="/contact">Enquire Now</a></td>
              </tr>
              {/* Add more rows as needed */}
            </tbody>
          </table>
        </div>
      </div>


      <section className="devops-certification-section">
        <h2 className="certification-heading">SAP WM & EWM CERTIFICATION</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
              <li>The SAP S/4HANA certification offered by Sapalogy will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
              <li>Sapalogy provides comprehensive guidance for your SAP S/4HANA global certification, ensuring a 100% passing guarantee in examinations such as SAP Certification, SAP S/4HANA Platform Development Certification, and various other global exams.</li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>
      <Roadmap heading="SAP modules" roadmapData={modulesData} />


      {/* devops project Section */}
      {/* <div className="slider-container">
        <h2 className="slider-heading">SAP HCM Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div> */}

      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div> */}


      <div className="alumni-section">
        <h1>Our Alumni Works At</h1>
        <div className="full-screen-image">
          <img src={IMG} alt="Full Screen Image" />
        </div>
      </div>
      <Contact />

    </div>
  );
};

export default SAPWMEWM;