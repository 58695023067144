import React from 'react';
import '../Css/CallIcon.css'; // Importing the CSS for styling

const CallIcon = () => {
  const handleClick = () => {
    window.open('tel:9175978889'); // This will initiate a call on mobile devices
  };

  return (
    <div className="call-icon" onClick={handleClick}>
      <img src="https://cdn-icons-png.flaticon.com/512/724/724664.png" alt="Call" />
    </div>
  );
};

export default CallIcon;
