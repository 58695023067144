import React, { useState } from 'react'
import '../Css/DevOps.css';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Importing icons for accordion

const Syllabus = ({heading, Syllabus}) => {
    const [activeIndex, setActiveIndex] = useState(null); // State for accordion  

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    return (
        <div>
            <div className="roadmap-section">
                <h2 className='main-heading'>{heading}</h2>
                <div className="roadmap-container">
                    {Syllabus.map((item, index) => (
                        <div key={index} className="roadmap-item">
                            <div
                                className="roadmap-title"
                                onClick={() => toggleAccordion(index)}
                            >
                                <h3>{item.title}</h3>
                                <span>
                                    {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                                </span>
                            </div>
                            {activeIndex === index && (
                                <ul className="roadmap-subpoints">
                                    {item.subpoints.map((subpoint, subIndex) => (
                                        <li key={subIndex}>{subpoint}</li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Syllabus
