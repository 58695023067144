import React from 'react';
import "../Css/WhyUs.css"; // Ensure the CSS file is imported

const ReachUsComponent = () => {
  return (
    <div className="reach-us-container">
      {/* Left Section: Form */}
      <div className="left-section">
        <h2>Reach us now!</h2>
        <form>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" placeholder="Enter your name" />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone No.</label>
            <input type="text" id="phone" name="phone" placeholder="Enter your phone number" />
          </div>
          <div className="form-group">
            <label htmlFor="course" >Select Your Course</label>
            <select id="course" name="course"  placeholder="Select Your Course">
              <option value="salesforce">Salesforce</option>
              <option value="sap">SAP</option>

              <option value="Business Analytics">Business Analytics</option>
              <option value="Data Analytics">Data Analytics</option>
              <option value="Python">Python</option>
              <option value="Full Stack Developer">Full Stack Developer</option>
              <option value="tally">Tally</option>
              <option value="ml">ML</option>
              <option value="ai_ml">AI & ML</option>
              <option value="data_science">Data Science</option>
              <option value="share_market">Share Market</option>
              <option value="aws">AWS</option>
              <option value="devops">DevOps</option>
            </select>
          </div>
          <button type="submit" className="submit-btn">Submit</button>
        </form>
      </div>

      {/* Right Section: Content */}
      <div className="right-section">
        <h2>Why SAPalogy?</h2>
        <p>
          Be a part of our comprehensive training programs & start your journey towards becoming a Professional Developer today.
        </p>
        <ul className="points-list">
          <li><i className="icon"></i> Start learning with us!</li>
          <li><i className="icon"></i> Become industry ready</li>
          <li><i className="icon"></i> 24*7 support</li>
          <li><i className="icon"></i> Attend live webinars every week</li>
          <li><i className="icon"></i> Access to study material</li>
          <li><i className="icon"></i> Job placement assistance</li>
        </ul>
      </div>
    </div>
  );
};

export default ReachUsComponent;
