import React from 'react';
import '../Css/Marquee.css'; // Assuming you're using an external CSS file for styles

const Marquee = () => {
  return (
    <div className="marquee">
      <span className="marquee-text">
      Sapalogy Training | Contact Us: +91-9175978889 | Address: 607, 608 B-wing, Lokmat Bhavan, Lokmat Square, Ramdaspeth, Nagpur, Maharashtra 440012.
      </span>
    </div>
  );
};

export default Marquee;
