import React, { useEffect, useRef } from 'react';
import '../../Css/DevOps.css';
import certificateImg from '../../assets/certificate.webp';
import Contact from '../Contact'
import IMG from '../../assets/Work.webp'
import Roadmap from '../../Components/Roadmap';
import WhatsDevOps from '../../Components/WhatsDevOps'
import PageForm from '../../Components/PageForm';
import Syllabus from '../../Components/Syllabus';
import KeyFeatures from '../../Components/KeyFeatures';
import Faq from '../../Components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
import dev1 from '../../assets/Projects/Devops/DEV1.png';
import dev2 from '../../assets/Projects/Devops/DEV2.png';
import dev3 from '../../assets/Projects/Devops/DEV3.png';
import dev4 from '../../assets/Projects/Devops/DEV4.png';
import dev5 from '../../assets/Projects/Devops/DEV5.png';
import dev6 from '../../assets/Projects/Devops/DEV6.png';
import dev7 from '../../assets/Projects/Devops/DEV7.png';
import dev8 from '../../assets/Projects/Devops/DEV8.png';
import rev1 from '../../assets/Reviews/DEVOPS/devrev1.png';
import rev2 from '../../assets/Reviews/DEVOPS/devrev2.png';
import rev3 from '../../assets/Reviews/DEVOPS/devrev3.png';
import rev4 from '../../assets/Reviews/DEVOPS/devrev4.png';
import rev5 from '../../assets/Reviews/DEVOPS/devrev5.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import SideDropdown from '../../Components/SideDropdown';


const SAPFICO = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);


  useEffect(() => {
     //Function to create the YouTube player
    const createPlayer = () => {
      if (window.YT && window.YT.Player) {
        playerRef.current = new window.YT.Player(videoRef.current, {
          videoId: 'VhmCwdaf63k', // Updated YouTube video ID
          events: {
            onReady: (event) => {
              // event.target.mute(); // Mute the video to allow autoplay
              event.target.playVideo();
            },
          },
          playerVars: {
            loop: 1,
            controls: 0, // Hide video controls
            modestbranding: 1, // Minimal YouTube branding
            playlist: 'VhmCwdaf63k', // Required for looping
          },
        });
      }
    };

    // Load YouTube IFrame API if it's not already loaded
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Set up callback for when the API is ready
      window.onYouTubeIframeAPIReady = () => {
        createPlayer();
      };
    } else {
      createPlayer();
    }
  }, []);


  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'What is SAP?',
        'What is SAP FICO?',
        'What is SAP HANA?',
        'SAP course',
        'SAP certification',
        'SAP jobs in India',
        'SAP jobs in Nagpur',
      ]
    },
    {
      title: ' Understanding SAP FICO Basics',
      points: [
        'Learn the fundamentals of SAP ERP and its modules.',
        'Understand the role of SAP FICO in financial management and reporting.',
        'Explore the SAP FICO user interface and navigation.',
      ]
    },
    {
      title: 'Mastering Financial Accounting (FI)',
      points: [
       'Learn how to configure and manage general ledger accounts.',
       'Understand accounts receivable and accounts payable processes.',
       'Master asset accounting and bank accounting functionalities.',
      ]
    },
    {
      title: 'Controlling Module (CO) Essentials',
      points: [
        'Dive into cost element accounting and cost center accounting.',
        'Learn about profit center accounting and internal orders.',
        'Understand product costing and profitability analysis.',
      ]
    },
    {
      title: 'Integration with Other SAP Modules',
      points: [
        'Explore integration between SAP FICO and other modules like MM (Materials Management) and SD (Sales and Distribution).',
        'Understand how financial transactions flow across different modules.',
        'Learn about data migration and integration best practices.',
      ]
    },
    {
      title: 'Advanced Reporting and Analysis',
      points: [
       ' Master SAP FICO reporting tools like SAP ECC, SAP S/4HANA, and SAP BW/4HANA.',
       ' Learn how to create financial statements, balance sheets, and profit and loss statements.',
       ' Explore advanced reporting features for financial analysis and decision-making.',
      ]
    },
    {
      title: 'Real-world Projects and Case Studies',
      points: [
        'Engage in hands-on projects to apply SAP FICO concepts in real-world scenarios.',
        'Analyze case studies to understand industry-specific challenges and solutions.',
        'Prepare for SAP FICO certification exams and continuous professional development.',
      ]
    },
    {
      title: 'Resume preparation',
      points: [
        'Include keywords',
        'How to prepare reume',
        'How to prepare resume for freshers.',
        'Show impact',
        'Include soft skills',
        'Be unique',
      ]
    }
  ];
  const modulesData = [
    {
      title: 'SAP',
      points: [
          'Sapalogy Training in Nagpur offers expert SAP training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
      ],
      link: "/best-sap-training"
  },
    {
      title: 'SAP BASIS',
      points: [
        'Sapalogy Training in Nagpur offers expert SAP BASIS training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
      ],
      link: "/sap-basis-training-in-nagpur"
    },
    {
      title: 'SAP MM',
      points: [
        'SAP MM (Materials Management) is a module in SAP ERP that manages procurement processes and inventory management. Sapalogy Institute in Nagpur offers comprehensive training in SAP MM for effective utilization ',
      ],
      link: "/sap-mm"
    },
    {
      title: 'SAP FICO',
      points: [
        'SAP FICO, offered by Sapalogy Institute Nagpur, encompasses financial accounting and controlling modules within the SAP ERP system, facilitating comprehensive financial management and reporting for organizations'
      ],
      link: "/sap-fico"
    },
    {
      title: 'SAP ABAP',
      points: [
        'SAP ABAP is a programming language used for developing applications within the SAP ecosystem. Sapalogy Institute in Nagpur is a training center specializing in SAP courses, including ABAP programming to equip professionals with the skills.',
      ],
      link: "/sap-abap",
    },
    {
      title: 'SAP HCM',
      points: [
        'SAP HCM (Human Capital Management) is an integrated software suite by SAP that manages HR processes, from recruitment to payroll. Sapalogy Institute in Nagpur likely provides training in SAP HCM and related modules.'
      ],
      link: "/sap-hcm",
    },
    {
      title: 'SAP SCM',
      points: [
        'SAP SCM (Supply Chain Management) is a software suite by SAP that integrates and optimizes key supply chain processes for enhanced efficiency. It enables businesses to manage procurement, production, and distribution seamlessly, ensuring streamlined operations.',
      ],
      link: "/sap-scm"
    },
    {
      title: 'SAP ARIBA',
      points: [
        'SAP Ariba is a cloud-based procurement platform by SAP that streamlines and automates procurement processes, enhancing collaboration between buyers and suppliers. Sapalogy Institute Nagpur is an educational institution specializing in SAP training and certification.',
      ],
      link: "/sap-ariba"
    },
    {
      title: 'SAP PP',
      points: [
        'SAP PP (Production Planning) is an SAP module that helps organizations manage and optimize their manufacturing processes, from planning and scheduling to execution. It integrates various business functions to streamline production.',
      ],
      link: "/sap-pp"
    },
    {
      title: 'SAP SD',
      points: [
        'SAP SD (Sales and Distribution) is a module in SAP ERP that manages sales and customer service processes. It covers order-to-cash processes, including sales order processing, pricing, delivery, and billing. SAP SD to equip individuals with skills.',
      ],
      link: "/sap-sd"
    },
    {
      title: 'SAP FIORI',
      points: [
        'SAP Fiori is a user experience (UX) design approach and set of design principles that enhance the usability and functionality of SAP applications. It provides a modern, responsive, and personalized user interface for a seamless and intuitive user experience in the SAP environment.',
      ],
      link: "/sap-fiori"
    },
    {
      title: 'SAP QM',
      points: [
        'SAP QM (Quality Management) is a module in SAP ERP that facilitates quality control and assurance processes in manufacturing and production. It helps organizations manage quality planning, inspection, and certification, ensuring products meet specified standards and many more.',
      ],
      link: "/sap-qm"
    },
    {
      title: 'SAP PM',
      points: [
        'SAP PM (Plant Maintenance) is a module in SAP ERP that helps organizations effectively manage and maintain their assets, equipment, and facilities. It covers processes such as preventive maintenance, work orders, and equipment tracking to optimize asset performance.',
      ],
      link: "/sap-pm"
    },
    {
      title: 'SAP WM & EWM',
      points: [
        'SAP WM (Warehouse Management) focuses on efficient inventory management within a warehouse, while SAP EWM (Extended Warehouse Management) extends these capabilities with advanced features like labour management and slotting optimization for operations.',
      ],
      link: "/sap-wm-ewm"
    },
    {
      title: 'SAP LE & SL',
      points: [
        'SAP LE (Logistics Execution) is a module that manages and optimizes warehouse and transportation processes, while SAP SL (Slotting) focuses on optimizing storage space and picking efficiency within a warehouse. Both modules contribute to streamlining supply chain operations in SAP systems.',
      ],
      link: "/sap-le-sl"
    },
  ];

  const syllabus = [
    {
      title: 'SAP FICO - Introduction',
      subpoints: [
        'Introduction of basic ERP. What is sap?',
        'Introduction of sap different modules.',
        'SAP R/3 architecture.',
        'Difference between technical and functional module.',
        'Phases of sap implementation projects.',
        'SAP FICO and its sub- modules.',
        'Introduction of sap Screens.',
      ],
    },
    {
      title: 'Definition And Assignment Of Organisation Structure',
      subpoints: [
        'Define client/ company.',
        'Define credit control area.',
        'Define company code.',
        'Define Business area.',
        'Define Functional area.',
        'Define consolidation area.',
        'Define financial management area.',
      ],
    },
    {
      title: 'Assignment',
      subpoints: [
        'Assign Company code to company.',
        'Assign company code to credit control area.',
        'Assign business area to consolidation business area.',
        'Assign company code to financial management area.',
      ],
    },
    {
      title: 'General Ledger Configuration',
      subpoints: [
        'Chart of account.',
        'Fiscal year variant.',
        'Posting period variant.',
        'Currency setting.',
        'Field status variant.',
        'Tolerance group.',
        'Exchange rate deviation.',
        'Company code globe parameter.',
      ],
    },
    {
      title: 'Controlling Area And Profit Centre',
      subpoints: [
        'Introduction of controlling area and profit Centre.',
        'Maintain controlling area.',
        'Assign company code to controlling area.',
        'Activate component in controlling area.',
        'Define profit Centre standard hierarchy in controlling area.',
        'Define standard hierarchy.',
        'Create profit Centre.',
      ],
    },
    {
      title: 'Parallel Accounting System',
      subpoints: [
        'Introduction of parallel accounting, Leading ledger and non-ledger',
        'Define ledger for general ledger accounting',
        'Define currencies for leading ledger',
        'Define and activate non-leading ledger',
        'Assign scenario and customer fields to ledger',
        'Activate cost of sales accounting',
        'Define ledger groups',
        'Define accounting principle',
        'Assign accounting principle to ledger group',
      ],
    },
    {
      title: 'Document Splitting',
      subpoints: [
        'Classify GL account for document splitting',
        'Classify document type for document splitting',
        'Creation of zero balance account',
        'Define document splitting characteristic for general ledger accounting',
        'Define zero balance clearing account',
        'Define document splitting characteristic for accounting',
        'Activate document splitting',
      ],
    },
    {
      title: 'General Ledger Number Range Configuration',
      subpoints: [
        'Define document type for entry view.',
        'Define document type for entry view in a ledger.',
        'Define document type for general ledger view.',
        'Define documents number range for entry view.',
        'Copy to company code.',
        'Copy to fiscal year.',
        'Define document number range for general ledger view.',
        'Copy to company code/ Copy to fiscal year.',
        
      ],
    },
    {
      title: 'GL Master Data',
      subpoints: [
        'Creation of G/L accounts.',
        'Change GL master data.',
        'Display change centrally.',
        'Block GL account.',
        'Unblock GL account.',
        'Display of sap table data.',
      ],
    },
    {
        title: 'Accounting Entries Posting',
        subpoints: [
          'General document posting single screen.',
          'General document posting double screen.',
          'Posting of general document with foreign currency.',
          'General document with special period.',
          'GL account document for ledger group.',
        ],
      },
      {
        title: 'Reversal Document Posting',
        subpoints: [
          'Individual reversal.',
          'Individual document with current date.',
          'Mass reversal documents.',
          'Reversal of reversal.',
          'Cleared item reversal.',
        ],
      },
      {
        title: 'Cross Company Code Transaction',
        subpoints: [
          'Creation of different G/L accounts.',
          'Prepare cross company code transaction.',
          'Intercompany code posting using double screen.',
          'Intercompany code posting using single screen.',
        ],
      },
      {
        title: 'Park Document/ Hold Document/ Sample Document',
        subpoints: [
          'PARK DOCUMENT',
          'Park document posting in single screen.',
          'Park documents posting in double screen.',
          'Display parked documents list.',
          'Change Park document single screen.',
          'Display changes.',
          'Convert Park documents into normal documents.',
          'Delete Park documents.',
        ],
      },
      {
        title: 'Hold Document',
        subpoints: [
          'Create hold document.',
          'Display hold document list.',
          'Convert hold document into normal documents.',
          'Delete hold document list.',
          
        ],
      },
      {
        title: 'Sample Documents',
        subpoints: [
          'Maintain number ranges for sample documents.',
          'Post sample documents.',
          'Display sample documents.',
          'Change sample documents.',
          'Display changes of sample documents.',
          'Create sample documents to normal documents.',
          'Delete sample documents.',
        ],
      },
      {
        title: 'Recurring Documents',
        subpoints: [
          'Define document number range for entry view.',
          'Enter recurring entry.',
          'Display recurring document transaction code.',
          'Create recurring document in book transaction code.',
        ],
      },
      {
        title: 'Foreign Currency Valuation Posting',
        subpoints: [
          'Enter posting.',
          'Enter GL payment post.',
          'Creation of valuation area.',
          'Assign valuation area to accounting principle.',
          'Creation of loan account and foreign currency valuation.',
        ],
      },
      {
        title: 'Account Payable',
        subpoints: [
          'Define account group with screen layout.',
          'Creation of vendors.',
          'Create and assign number range for vendor’s account.',
          'Tolerance for vendors.',
          'Document configuration & document number range.',
          'Creation of reconciliation account for different vendors.',
          'Creation of vendor master record and Post invoice.',
          
        ],
      },
      {
        title: 'Vendor Account Posting',
        subpoints: [
          'Create vendor master record.',
          'Post vendor invoice in single screen and double screen.',
          'Vendor credit memo in single screen and double screen.',
          'Vendor out-going payment.',
          'Display vendor balance & change line item.',
          'Display payable profit Centre & payable Centre.',
        ],
      },
      {
        title: 'Vendor Special General Ledger Accounts',
        subpoints: [
          'Creation of special GL accounts.',
          'Define reconciliation account for down payment.',
          'Maintain foreign currency valuation setting for vendor special GL accounts.',
          'Vendor down-payment.',
          'Vendor advance payment.',
          'Vendor invoice posting / vendor payment posting.',
          
        ],
      },
      {
        title: 'Tax On Sales And Puschase (gst)',
        subpoints: [
          'Introduction of GST.',
          'Define condition type.',
          'Creation of account key.',
          'Check calculation procedures.',
          'Define tax code for sales and purchase.',
          'Creation of GL accounts for SGST/ CGST/ IGST and assign it of general ledgers.',
          'Maintain tax codes for excise duty using condition techniques.',
          'Vendor invoice posting.',
        ],
      },
      {
        title: 'Withholding Tax (tds)',
        subpoints: [
          'Introduction of withholding tax.',
          'Configuration of withholding tax.',
          'Creation of TDS GL account.',
          'Invoice posting.',
        ],
      },
      {
        title: 'House Bank',
        subpoints: [
          'Introduction of house bank.',
          'Creation of different bank accounts.',
          'Define house bank.',
          'Assign house bank and account id in GL master.',
          'Define number ranges for checks and Vendor outgoing payment.',
        ],
      },
      {
        title: 'Automatic Payment Programm Run',
        subpoints: [
          'Automatic Payment program run configuration.',
          'Paying company code.',
          'Payment method in country.',
          'Payment method in company code.',
          'Bank determination.',
          'Automatic payment program run.',
          
        ],
      },
      {
        title: 'Account Receivable',
        subpoints: [
         ' Define customer account group with screen layouts.',
         ' Define and assign number ranges to the customers.',
         ' Define tolerance for the customer.',
         ' Creation of GL accounts.',
         ' Define document types for different views.',
         ' Assign number ranges to document types for different views.',
         ' Create customer master data.',
         ' Post customer invoice.',
        ],
      },
      {
        title: 'Customer Posting',
        subpoints: [
          'Create customer master.h',
          'Post customer invoice using single screen/ double screen.',
          'Customer credit memo single screen/double screen.',
          'Customer incoming payment.',
          'Display change line item.',
        ],
      },
      {
        title: 'Dunning',
        subpoints: [
          'Introduction of dunning.',
          'Configuration of dunning.',
          'Post customer invoice.',
          'Dunning run.',
        ],
      },
      {
        title: 'Asset Accounting',
        subpoints: [
          'Configuration of chart of depreciation.',
          'Create screen layout rule.',
          'Specify account determination.',
          'Define asset classes.',
          'Creation of GL accounts and assign it to chart of depreciation.',
          'Define base method and multilevel methods.',
          'Maintain period control method and maintain depreciation key.',
          'Creation of asset master data.',
          'Execution of depreciation run.',
          'Sale of asset, scrapping of asset and transfer of asset.',
        ],
      },
      {
        title: 'Bank Reconciliation Statement (brs)',
        subpoints: [
          'Create and assign business transaction.',
          'Define posting key and posting rules for manual statement.',
          'Define variant for bank statement and Bank reconciliation.',
          
        ],
      },
      {
        title: 'Financial Statement Version',
        subpoints: [
          'Introduction of financial statement versions.',
          'Configuration of financial statement version.',
          'Display financial statements.',
        ],
      }
  ];

  const keyFeatures = [
    "162+ Hours course duration",
    "100% Job oriented Training",
    "Industry expert faculties",
    "Free demo class available",
    "Completed 200+ Batches",
    "Certifaction guidance",
  ];

  const faqs = [
    {
      question: "What is SAP FICO?",
      answer: "SAP FICO (Financial Accounting and Controlling) is an integrated module in SAP ERP that handles financial processes, accounting, and reporting.",
    },
    {
      question: "What are the key components of SAP FICO?",
      answer: "SAP FICO comprises two main modules: Financial Accounting (FI) and Controlling (CO).",
    },
    {
      question: "How does SAP FICO integrate with other SAP modules?",
      answer: "SAP FICO integrates seamlessly with other SAP modules like MM (Materials Management) and SD (Sales and Distribution) for a comprehensive business solution.",
    },
    {
      question: "What is the purpose of SAP FICO in an organization?",
      answer: "SAP FICO helps organizations manage financial transactions, generate financial statements, conduct cost accounting, and make informed business decisions.",
    },
    {
      question: "What is a General Ledger in SAP FICO?",
      answer: "The General Ledger in SAP FICO is the primary accounting record that contains all financial transactions of an organization. It provides a complete financial overview.",
    },
    {
      question: "Explain Accounts Payable (AP) in SAP FICO.",
      answer: "Accounts Payable in SAP FICO manages an organization’s outgoing payments to vendors and suppliers, tracking invoices and ensuring timely payments.",
    },
    {
      question: "What is Accounts Receivable (AR) in SAP FICO?",
      answer: "Accounts Receivable in SAP FICO handles incoming payments from customers, manages invoices, and ensures accurate tracking of customer transactions.",
    },
    {
      question: "How does Asset Accounting work in SAP FICO?",
      answer: "Asset Accounting in SAP FICO manages an organization’s fixed assets, including acquisition, depreciation, and disposal, ensuring accurate asset valuation.",
    },
    {
        question: "What is Cost Center Accounting in SAP FICO?",
        answer: "Cost Center Accounting in SAP FICO helps organizations track and manage costs associated with specific departments or business units, aiding in cost control and analysis.",
      },
      
  ];

  const whatsData = [
    {
      title: 'What is SAP FICO ?',
      points: [
        'SAP finance and controlling (FICO) is a central component for the finance module of the ERP solution from SAP.',
        'SAP FICO helps companies to manage all their financial data, generate balance sheet and make decisions for corporate planning.',
        'FICO is the combination of two modules. FI(Financial Accounting) and CO(Controlling)',
        'Sapalogy training provides SAP FICO training in offline and online mode. Starting end user, consulting, implementation, support with real time SAP project based training.',
        'IT background, non IT background, freshers, experience can start their career in SAP irrespective of their background.',
        'Sapalogy is the best training institute in nagpur with the 100% job opportunities.',
      ]
    }
  ]

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imageList = [
    { Image: dev1, alt: "Award 1" },
    { Image: dev2, alt: "Award 2" },
    { Image: dev3, alt: "Award 3" },
    { Image: dev4, alt: "Award 4" },
    { Image: dev5, alt: "Award 5" },
    { Image: dev6, alt: "Award 6" },
    { Image: dev7, alt: "Award 7" },
    { Image: dev8, alt: "Award 8" },
  ];

  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },
    { Image: rev5, alt: "rev1" },
  ]


  return (
    <div className="devops-page">
         <SideDropdown roadmapData={modulesData} />
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Best SAP FICO Training</h1>
          <p>
          SAP FICO Sapalogy offers you the Best SAP Financial Accounting (FI) and SAP Controlling (CO) training in nagpur for freshers and experience candidate in nagpur, with expert guidance and 100% guranteed placement assistance. Our SAP training course covers every technical topics in details and the student is left with real-time hand on experience.
          </p>
          <br />
          <p>
          Our practical, Job-oriented training program will not only provide you with a certificate but also with knowledge equivalent to around 2+ years of field experience. We value your time as much as over. Hence we invented to train you in SAP FICO with S/4 HANA in just 4 month.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faFileAlt} /> End user 1 month (ECC / S4 HANA)</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Configuration 2 months (ECC / S4 HANA)</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> Project 1 month</li>
            <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> 6-months internship</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
        </ul>
        </div>

        <PageForm />
      </div>
     
      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>

      </div>
      
      <Roadmap heading="Roadmap to learn SAP FICO" roadmapData={roadmapData} />
      <WhatsDevOps whatsData={whatsData} />
      <Syllabus heading="SAP FICO Training syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="SAP FICO Training" keyFeatures={keyFeatures} />

      <div className="batch-schedule-section">
        <h2>Upcoming Batch Schedule for SAP FICO Training</h2>
        <p>
        Sapalogy provides flexible timings to all our students. Here are the SAP FICO Training Classes in Nagpur Schedule in our branches. If this schedule doesn’t match please let us know. We will try to arrange appropriate timings based on your flexible timing.
        </p>
        <div className="batch-schedule-table-wrapper">
        <table className="batch-schedule-table">
          <thead>
            <tr>
              <th>Course</th>
              <th>Batch Time</th>
              <th>Offline</th>
              <th>Online</th>
              <th>Enquire Now</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>SAP FICO</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
            <tr>
              <td>SAP MM</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
            <tr>
              <td>SAP HCM</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
            <tr>
              <td>SAP</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div>
</div>


      <section className="devops-certification-section">
        <h2 className="certification-heading">SAP FICO CERTIFICATION</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
              <li>The SAP S/4HANA certification offered by Sapalogy will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
              <li>Sapalogy provides comprehensive guidance for your SAP S/4HANA global certification, ensuring a 100% passing guarantee in examinations such as SAP Certification, SAP S/4HANA Platform Development Certification, and various other global exams.</li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>

      <Roadmap heading="SAP modules" roadmapData={modulesData} />

      {/* devops project Section */}
      {/* <div className="slider-container">
        <h2 className="slider-heading">SAP FICO Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div> */}

      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div> */}


      <div className="alumni-section">
        <h1>Our Alumni Works At</h1>
        <div className="full-screen-image">
          <img src={IMG} alt="Full Screen Image" />
        </div>
      </div>
      <Contact />

    </div>
  );
};

export default SAPFICO;