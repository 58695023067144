import React, { useEffect, useState } from 'react';
import Slider from 'react-slick'; // Importing Slider from react-slick
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
import cert1 from '../assets/cert/cert1.webp';
import cert2 from '../assets/cert/cert2.webp';
import cert3 from '../assets/cert/cert3.webp';
import cert4 from '../assets/cert/cert4.webp';
import cert5 from '../assets/cert/cert5.webp';
import cert6 from '../assets/cert/cert6.webp';
import cert7 from '../assets/cert/cert7.webp';
import cert8 from '../assets/cert/cert8.webp';
import cert9 from '../assets/cert/cert9.webp';
import cert10 from '../assets/cert/cert10.webp';

import award1 from '../assets/award1.webp';
import award2 from '../assets/award2.webp';
import award3 from '../assets/award3.webp';
import award4 from '../assets/award4.webp';
import award5 from '../assets/award5.webp';
import award6 from '../assets/award6.webp';
import award7 from '../assets/award7.webp';
// Workshop images
import workshop1 from '../assets/workshop1.webp';
import workshop2 from '../assets/workshop2.webp';
import workshop3 from '../assets/workshop3.webp';
import workshop4 from '../assets/workshop4.webp';
import workshop5 from '../assets/workshop5.webp';
import workshop6 from '../assets/workshop6.webp';
// import workshop7 from '../assets/workshop7.webp';

// mou images
import mou1 from '../assets/mou1.webp';
import mou2 from '../assets/mou2.webp';
import mou3 from '../assets/mou3.webp';
import mou4 from '../assets/mou4.webp';
import mou5 from '../assets/mou5.webp';
// placement image
import placement from '../assets/placement.webp';
const About = () => {
  const sectionStyle = {
    padding: '40px 10px',
    margin: '40px auto',
    maxWidth: '900px',
    fontFamily: "'Poppins', sans-serif",
    color: '#fff',
    lineHeight: '1.8',
    background: 'rgba(255, 255, 255, 0.125)',
    borderRadius: '15px',
    border: '1px solid gray',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
  };

  const headingStyle = {
    fontSize: '30px',
    fontWeight: '700',
    color: '#ffe400',
    marginBottom: '25px',
    textAlign: 'center',
    textTransform: 'uppercase',
  };

  const subHeadingStyle = {
    fontSize: '24px',
    fontWeight: '600',
    color: 'white',
    marginTop: '25px',
    marginBottom: '15px',
    textTransform: 'uppercase',
  };

  const textStyle = {
    fontSize: '18px',
    marginBottom: '15px',
    textAlign: 'justify',
    textAlign:'center',
  };
  

  const listStyle = {
    paddingLeft: '20px',
    listStyleType: 'none',
  };

  const listItemStyle = {
    marginBottom: '10px',
    fontSize: '18px',
    color: 'white',
    background: 'transparent',
    border: '1px solid gray',
    padding: '10px',
    borderRadius: '8px',
  };

  const counterSectionStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '40px',
    textAlign: 'center',
    flexWrap: 'wrap',
  };

  const counterItemStyle = {
    flex: '1 1 150px',
    margin: '20px',
    padding: '20px',
    background: 'rgba(255, 255, 255, 0.125)',
    borderRadius: '15px',
    border: '1px solid gray',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
    minWidth: '150px',
  };

  const counterNumberStyle = {
    fontSize: '48px',
    fontWeight: '700',
    color: '#ffe400',
    marginBottom: '10px',
  };

  const counterLabelStyle = {
    fontSize: '18px',
    color: 'white',
    textTransform: 'uppercase',
  };

  const certificateSectionStyle = {
    marginTop: '40px',
    textAlign: 'center',
    marginBottom: '30px',
  };

  const certificateContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '20px',
   
  };

  const certificateItemStyle = {
    width: '280px', // or any fixed width you want
    height: '250px', // or any fixed height you want
    overflow: 'hidden',
    position: 'relative',
  };

  const certificateImageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover', // Ensures the image covers the entire container

  };

 


  const sliderContainerStyle = {
    margin: '40px auto',
    padding: '30px 20px',
    maxWidth: '1100px', // Increased max width for a more spacious look
    background: 'rgba(255, 255, 255, 0.125)',
    borderRadius: '15px',
    border: '1px solid gray',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
    textAlign: 'center', // Center the text content inside the container
  };

  const sliderItemStyle = {
    width: '100%',
    padding: '0 15px', // Slightly increased padding for more spacing
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column', // Arrange content in a column
    alignItems: 'center', // Center-align all content
    justifyContent: 'center', // Center content vertically
  
  };

  const sliderImageStyle = {
    width: '100%',
    height: '300px', // Increased height for more visual impact
    objectFit: 'cover', // Cover the entire slider area nicely
    borderRadius: '15px', // More rounded corners
    marginBottom: '15px', // Space below the image for text
    // border:'2px solid black',
    padding:'8px',

  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Shows 3 slides at a time
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const cardContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '20px',
    marginTop: '20px',
  };

  const cardStyle = {
    width: '250px', // Adjust width as needed
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '15px',
    border: '1px solid gray',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
    background: 'rgba(255, 255, 255, 0.125)',
    textAlign: 'center',
    paddingBottom: '15px', // Adds space for description
  };

  const cardImageStyle = {
    width: '100%',
    height: '200px', // Adjust height as needed
    objectFit: 'cover', // Ensures the image covers the entire container
  };

  const singleImageSectionStyle = {
    margin: '40px auto',
    padding: '30px 20px',
    maxWidth: '600px',
    textAlign: 'center',
    background: 'rgba(255, 255, 255, 0.125)',
    borderRadius: '15px',
    border: '1px solid gray',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
  };

  const singleImageStyle = {
    width: '100%',
    height: '400px',
    objectFit: 'cover',
    borderRadius: '15px',
  };
  const Counter = ({ endValue }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
      let start = 0;
      const duration = 2000; // duration of the count-up in milliseconds
      const incrementTime = 30; // increment time in milliseconds
      const increment = endValue / (duration / incrementTime);

      const timer = setInterval(() => {
        start += increment;
        if (start >= endValue) {
          setCount(endValue);
          clearInterval(timer);
        } else {
          setCount(Math.ceil(start));
        }
      }, incrementTime);

      return () => clearInterval(timer); // cleanup the interval on component unmount
    }, [endValue]);

    return <div style={{ fontSize: '48px', fontWeight: '700', color: '#ffe400', marginBottom: '10px' }}>{count}+</div>;
  };
  // CONTENT OF WORKSHOP
  const workshopDescriptions = [
    "SAPALOGY SUCCESSFULLY COMPLETED A ERP WORKSHOP AT LAD COLLEGE",
    "SAPALOGY SUCCESSFULLY COMPLETED A DATA ANALYTICS WORKSHOP AT PALLOTI COLLEGE",
    "SAPALOGY SUCCESSFULLY COMPLETED A SALESFORCE WORKSHOP AT NIT COLLEGE",
    "SAPALOGY SUCCESSFULLY COMPLETED A ERP WORKSHOP AT GH, COLLEGE",
    "SAPALOGY SUCCESSFULLY COMPLETED A DATA ANALYTICS AND AI & ML WORKSHOP AT GH RAISONI COLLEGE",
    "SAPALOGY SUCCESSFULLY COMPLETED A G.H.RAISONI COLLEGE.",
    // "SAPALOGY SUCCESFULLY COMPLETED A G.H.RAISONI HINGNA AND SAP WORKSHOP AT GH RAISONI COLLEGE.",
  ];

  return (
    <>
    <div>
      <div style={sectionStyle}>
        <h1 style={headingStyle}>Who We Are?</h1>
        <p style={textStyle}>
          Sapalogy Training is an award-winning IT training institute in Nagpur, established in 2012. We are one of the best training institutes in central India, offering services to individual candidates, colleges, and universities across PAN India.
        </p>
        <h2 style={subHeadingStyle}>Courses Offered:</h2>
        <ul style={listStyle}>
          <li style={listItemStyle}>Salesforce Training</li>
          <li style={listItemStyle}>Data Analytics Training</li>
          <li style={listItemStyle}>Data Science Training</li>
          <li style={listItemStyle}>Business Analytics Training</li>
          <li style={listItemStyle}>AI Artificial Intelligence Training</li>
          <li style={listItemStyle}>AWS Training</li>
          <li style={listItemStyle}>DevOps Training</li>
          <li style={listItemStyle}>Full Stack Developer Training</li>
          <li style={listItemStyle}>Python Training</li>
        </ul>
        <p style={textStyle}>
          Our practical, job-oriented training program will not only provide you with internationally accepted certificates but also with knowledge equivalent to a minimum of 1+ years of field experience. We value your time as much as ours. Hence, we provide an industry-based syllabus with industrial-experienced trainers, plus technical mock interviews, resume preparation, and 100% guaranteed job assistance. All global certifications are available under one roof in Nagpur.
        </p>
        <p style={textStyle}>
          Additionally, Sapalogy assists you in honing your soft skills, including communication skills, public speaking, email etiquette, personal interviews, and HR grooming sessions. You’ll ace the interviews both during and after the training using these abilities.
        </p>
        <p style={textStyle}>
          We proudly consider ourselves the highest placement-providing institute in Nagpur, as per our last 10 years' records, with 400+ placement tie-ups with IT, service, and manufacturing companies across PAN India.
        </p>
        <p style={textStyle}>
          We focus on delivering innovative, efficient, and future-proof training to the youth of India, helping them build successful careers. We envision a success story for all our students.
        </p>
      </div>

      {/* Counter Section */}
      <div style={counterSectionStyle}>
        <div style={counterItemStyle}>
          <Counter endValue={92} />
          <div style={counterLabelStyle}>Qualified Trainers</div>
        </div>
        <div style={counterItemStyle}>
          <Counter endValue={250} />
          <div style={counterLabelStyle}>Live Classes Per Month</div>
        </div>
        <div style={counterItemStyle}>
          <Counter endValue={120} />
          <div style={counterLabelStyle}>Global Accreditations</div>
        </div>
        <div style={counterItemStyle}>
          <Counter endValue={10} />
          <div style={counterLabelStyle}>Courses</div>
        </div>
      </div>

      {/* Certificates Section */}
      <div style={certificateSectionStyle}>
        <h2 style={headingStyle}>Our Certifications</h2>
        <div style={certificateContainerStyle}>

        <div style={certificateItemStyle}>
            <img
              src={cert8}
              alt="Certificate 8"
              style={certificateImageStyle}
            />
          </div>
         
        
          <div style={certificateItemStyle}>
            <img
              src={cert10}
              alt="Certificate 10"
              style={certificateImageStyle}
            />
          </div>
          
        
          <div style={certificateItemStyle}>
            <img
              src={cert4}
              alt="Certificate 4"
              style={certificateImageStyle}
            />
          </div>

      
          
          <div style={certificateItemStyle}>
            <img
              src={cert6}
              alt="Certificate 6"
              style={certificateImageStyle}
            />
          </div>

          <div style={certificateItemStyle}>
            <img
              src={cert5}
              alt="Certificate 5"
              style={certificateImageStyle}
            />
          </div>
          
          <div style={certificateItemStyle}>
            <img
              src={cert1}
              alt="Certificate 1"
              style={certificateImageStyle}
            />
          </div>
          
          {/* <div style={certificateItemStyle}>
            <img
              src={cert3}
              alt="Certificate 3"
              style={certificateImageStyle}
            />
          </div> */}
         
        
          <div style={certificateItemStyle}>
            <img
              src={cert9}
              alt="Certificate 9"
              style={certificateImageStyle}
            />
          </div>
          <div style={certificateItemStyle}>
            <img
              src={cert7}
              alt="Certificate 7"
              style={certificateImageStyle}
            />
          </div>
          <div style={certificateItemStyle}>
            <img
              src={cert2}
              alt="Certificate 2"
              style={certificateImageStyle}
            />
          </div>

         
          
          
      
        </div>

      </div>

       {/* Awards Section */}
       <div style={sliderContainerStyle}>
          <h2 style={headingStyle}>Our Awards</h2>
          <Slider {...sliderSettings}>
            <div style={sliderItemStyle}>
              <img src={award1} alt="Award 1" style={sliderImageStyle} />
              <p style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>SAPALOGY PVT LTD AWARDED AS YCC UPSURGE AWARD</p>
            </div>
            <div style={sliderItemStyle}>
              <img src={award2} alt="Award 2" style={sliderImageStyle} />
              <p style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>SAPALOGY PVT LTD AWARDED AS BEST DATA SCIENCE DELIVERY PARTNER</p>
            </div>
            <div style={sliderItemStyle}>
              <img src={award3} alt="Award 3" style={sliderImageStyle} />
              <p style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>SAPALOGY PVT LTD AWARDED AS BEST ACE BUSINESS AWARD 2020</p>
            </div>
            <div style={sliderItemStyle}>
              <img src={award4} alt="Award 4" style={sliderImageStyle} />
              <p style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>SAPALOGY PVT LTD AWARDED AS BEST BRAND ACADEMY BUSINESS LEADERSHIP</p>
            </div>
            <div style={sliderItemStyle}>
              <img src={award5} alt="Award 5" style={sliderImageStyle} />
              <p style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>SAPALOGY PVT LTD AWARDED AS BEST GREAT INDIAN STARUP AWARD 2023</p>
            </div>
            <div style={sliderItemStyle}>
              <img src={award6} alt="Award 6" style={sliderImageStyle} />
              <p style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>SAPALOGY PVT LTD AWARDED AS BEST PRATIBIMB AWARD 2020</p>
            </div>
            <div style={sliderItemStyle}>
              <img src={award7} alt="Award 7" style={sliderImageStyle} />
              <p style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>SAPALOGY PVT LTD AWARDED AS BEST GLOBAL STARTUP AWARD</p>
            </div>
          </Slider>
        </div>
{/* Workshop Section */}
<div style={sectionStyle}>
        <h2 style={headingStyle}>Our Workshops</h2>
        <p style={textStyle}>
          Elevate your skills and knowledge with Sapalogy Institute’s transformative workshop experience.
        </p>
        <div style={cardContainerStyle}>
          {[workshop1, workshop2, workshop3, workshop4, workshop5, workshop6].map((image, index) => (
            <div key={index} style={cardStyle}>
              <img src={image} alt={`Workshop ${index + 1}`} style={cardImageStyle} />
              <p style={{ color: 'white', marginTop: '10px' }}>{workshopDescriptions[index]}</p>
            </div>
          ))}
        </div>
      </div>


       {/* Mou */}
       <div style={sliderContainerStyle}>
          <h2 style={headingStyle}>Mou</h2>
          <Slider {...sliderSettings}>
            <div style={sliderItemStyle}>
              <img src={mou1} alt="Award 1" style={sliderImageStyle} />
              <p style={{ color: 'white', fontSize: '16px', marginTop: '10px'}}>CONFERENCE MEETING VNIT,NAGPUR</p>
            </div>
            <div style={sliderItemStyle}>
              <img src={mou2} alt="Award 2" style={sliderImageStyle} />
              <p style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>HR CONCLAVE ORGANISED BY TULSIRAMJI GAIKWAD PATIL COLLEGE OF ENGINEERING & TECHNOLOGY NAGPUR</p>
            </div>
            <div style={sliderItemStyle}>
              <img src={mou3} alt="Award 3" style={sliderImageStyle} />
              <p style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>MOU AT AMBEDKAR COLLEGE</p>
            </div>
            <div style={sliderItemStyle}>
              <img src={mou4} alt="Award 4" style={sliderImageStyle} />
              <p style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>MOU AT PRIYADARSHANI COLLEGE HINGNA</p>
            </div>
            <div style={sliderItemStyle}>
              <img src={mou5} alt="Award 5" style={sliderImageStyle} />
              <p style={{ color: 'white', fontSize: '16px', marginTop: '10px' }}>MOU AT GH RAISONI COLLEGE,NAGPUR</p>
            </div>
           
          </Slider>
        </div>
           
        <section style={singleImageSectionStyle}>
        <h2 style={headingStyle}>Our students placed at</h2>
        <img src={placement} alt="Partnership" style={singleImageStyle} />
      </section>
          </div>
    </>
  );
};

export default About;
