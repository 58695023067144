import React, { useEffect, useRef } from 'react';
import '../../Css/DevOps.css';
import certificateImg from '../../assets/certificate.webp';
import Contact from '../../pages/Contact'
import IMG from '../../assets/Work.webp'
import Roadmap from '../../Components/Roadmap';
import WhatsDevOps from '../../Components/WhatsDevOps'
import PageForm from '../../Components/PageForm';
import Syllabus from '../../Components/Syllabus';
import KeyFeatures from '../../Components/KeyFeatures';
import Faq from '../../Components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
// import dev1 from '../../assets/Projects/Devops/DEV1.png';
// import dev2 from '../../assets/Projects/Devops/DEV2.png';
// import dev3 from '../../assets/Projects/Devops/DEV3.png';
// import dev4 from '../../assets/Projects/Devops/DEV4.png';
// import dev5 from '../../assets/Projects/Devops/DEV5.png';
// import dev6 from '../../assets/Projects/Devops/DEV6.png';
// import dev7 from '../../assets/Projects/Devops/DEV7.png';
// import dev8 from '../../assets/Projects/Devops/DEV8.png';
// import rev1 from '../../assets/Reviews/DEVOPS/devrev1.png';
// import rev2 from '../../assets/Reviews/DEVOPS/devrev2.png';
// import rev3 from '../../assets/Reviews/DEVOPS/devrev3.png';
// import rev4 from '../../assets/Reviews/DEVOPS/devrev4.png';
// import rev5 from '../../assets/Reviews/DEVOPS/devrev5.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import SideDropdown from '../../Components/SideDropdown';


const SAPMM = () => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);


    useEffect(() => {
        // Function to create the YouTube player
        const createPlayer = () => {
            if (window.YT && window.YT.Player) {
                playerRef.current = new window.YT.Player(videoRef.current, {
                    videoId: 'olXxskoVN08', // Updated YouTube video ID
                    events: {
                        onReady: (event) => {
                            // event.target.mute(); // Mute the video to allow autoplay
                            event.target.playVideo();
                        },
                    },
                    playerVars: {
                        loop: 1,
                        controls: 0, // Hide video controls
                        modestbranding: 1, // Minimal YouTube branding
                        playlist: 'olXxskoVN08', // Required for looping
                    },
                });
            }
        };

        if (!window.YT) {
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            // Set up callback for when the API is ready
            window.onYouTubeIframeAPIReady = () => {
                createPlayer();
            };
        } else {
            createPlayer();
        }
    }, []);

    const modulesData = [
        {
            title: 'SAP',
            points: [
                'Sapalogy Training in Nagpur offers expert SAP training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
            ],
            link: "/best-sap-training"
        },
        {
            title: 'SAP BASIS',
            points: [
                'Sapalogy Training in Nagpur offers expert SAP BASIS training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
            ],
            link: "/sap-basis-training-in-nagpur"
        },
        {
            title: 'SAP MM',
            points: [
                'SAP MM (Materials Management) is a module in SAP ERP that manages procurement processes and inventory management. Sapalogy Institute in Nagpur offers comprehensive training in SAP MM for effective utilization ',
            ],
            link: "/sap-mm"
        },
        {
            title: 'SAP FICO',
            points: [
                'SAP FICO, offered by Sapalogy Institute Nagpur, encompasses financial accounting and controlling modules within the SAP ERP system, facilitating comprehensive financial management and reporting for organizations'
            ],
            link: "/sap-fico"
        },
        {
            title: 'SAP ABAP',
            points: [
                'SAP ABAP is a programming language used for developing applications within the SAP ecosystem. Sapalogy Institute in Nagpur is a training center specializing in SAP courses, including ABAP programming to equip professionals with the skills.',
            ],
            link: "/sap-abap",
        },
        {
            title: 'SAP HCM',
            points: [
                'SAP HCM (Human Capital Management) is an integrated software suite by SAP that manages HR processes, from recruitment to payroll. Sapalogy Institute in Nagpur likely provides training in SAP HCM and related modules.'
            ],
            link: "/sap-hcm",
        },
        {
            title: 'SAP SCM',
            points: [
                'SAP SCM (Supply Chain Management) is a software suite by SAP that integrates and optimizes key supply chain processes for enhanced efficiency. It enables businesses to manage procurement, production, and distribution seamlessly, ensuring streamlined operations.',
            ],
            link: "/sap-scm"
        },
        {
            title: 'SAP ARIBA',
            points: [
                'SAP Ariba is a cloud-based procurement platform by SAP that streamlines and automates procurement processes, enhancing collaboration between buyers and suppliers. Sapalogy Institute Nagpur is an educational institution specializing in SAP training and certification.',
            ],
            link: "/sap-ariba"
        },
        {
            title: 'SAP PP',
            points: [
                'SAP PP (Production Planning) is an SAP module that helps organizations manage and optimize their manufacturing processes, from planning and scheduling to execution. It integrates various business functions to streamline production.',
            ],
            link: "/sap-pp"
        },
        {
            title: 'SAP SD',
            points: [
                'SAP SD (Sales and Distribution) is a module in SAP ERP that manages sales and customer service processes. It covers order-to-cash processes, including sales order processing, pricing, delivery, and billing. SAP SD to equip individuals with skills.',
            ],
            link: "/sap-sd"
        },
        {
            title: 'SAP FIORI',
            points: [
                'SAP Fiori is a user experience (UX) design approach and set of design principles that enhance the usability and functionality of SAP applications. It provides a modern, responsive, and personalized user interface for a seamless and intuitive user experience in the SAP environment.',
            ],
            link: "/sap-fiori"
        },
        {
            title: 'SAP QM',
            points: [
                'SAP QM (Quality Management) is a module in SAP ERP that facilitates quality control and assurance processes in manufacturing and production. It helps organizations manage quality planning, inspection, and certification, ensuring products meet specified standards and many more.',
            ],
            link: "/sap-qm"
        },
        {
            title: 'SAP PM',
            points: [
                'SAP PM (Plant Maintenance) is a module in SAP ERP that helps organizations effectively manage and maintain their assets, equipment, and facilities. It covers processes such as preventive maintenance, work orders, and equipment tracking to optimize asset performance.',
            ],
            link: "/sap-pm"
        },
        {
            title: 'SAP WM & EWM',
            points: [
                'SAP WM (Warehouse Management) focuses on efficient inventory management within a warehouse, while SAP EWM (Extended Warehouse Management) extends these capabilities with advanced features like labour management and slotting optimization for operations.',
            ],
            link: "/sap-wm-ewm"
        },
        {
            title: 'SAP LE & SL',
            points: [
                'SAP LE (Logistics Execution) is a module that manages and optimizes warehouse and transportation processes, while SAP SL (Slotting) focuses on optimizing storage space and picking efficiency within a warehouse. Both modules contribute to streamlining supply chain operations in SAP systems.',
            ],
            link: "/sap-le-sl"
        },
    ];

    const roadmapData = [
        {
            title: 'Introduction',
            points: [
                'What is SAP?',
                'What is SAP MM?',
                'What is SAP HANA?',
                'SAP course',
                'SAP certification',
                'SAP jobs in india',
                'SAP jobs in nagpur',
            ]
        },
        {
            title: 'SAP MM End user(ECC/H4 HANA)',
            points: [
                'SAP software access.',
                'SAP netweaves login.',
                'Define company.',
                'Purchase organisation.',
                'SAP Netweaves, SAP Netweaves portal.',
                'SAP end users jobs.',
            ]
        },
        {
            title: 'SAP MM CONSULTANT (ECC/H4 HANA)',
            points: [
                'What is SAP MM consultant?',
                'Phases of SAP implementation project.',
                'Master data in SAP MM.',
                'Organisation structure in SAP MM.',
                'SAP MM job in india.',
                'SAP MM freshers job.',
                'SAP Project.',
            ]
        },
        {
            title: 'Resume preparation',
            points: [
                'Include keywords.',
                'How to prepare resume.',
                'How to prepare resume for freshers.',
                'Show impact.',
                'Include soft skills.',
                'Be unique.',
            ]
        }
    ];

    const syllabus = [
        {
            title: 'Intruduction of SAP MM',
            subpoints: [
                'Introduction to basic ERP & SAP',
                'What is SAP?',
                'SAP R/3 structure',
                'Types of server',
                'SAP projects',
                'Module of Introduction Sap project',
                'Phases',
                'Project preparation',
                'Business Blue print',
                'Configuration and Customisation',
                'Final Preparation',
                'Go live',
            ],
        },
        {
            title: 'Definition And Assignment Of Organization Structure Definition',
            subpoints: [
                "Define Company",
                "Define company code",
                "Define Plant",
                "Define storage location",
                "Define purchasing organization",
                "Regular purchasing organization",
                "Standard Purchasing organization",
                "Reference Purchasing organization",
                "Define Purchasing group",
            ],
        },
        {
            title: 'Assignment',
            subpoints: [
                'Assign company code to company',
                'Assign Plant to company code',
                'Assign Purchasing organization to company code',
                'Assign Purchasing organization to plant',
                'Assign Standard purchasing organization to plant',
                'Assign purchasing organization to reference purchasing organization',
            ],
        },
        {
            title: 'Integration Mm Module With Controlling',
            subpoints: [
                "Introduction of controlling module",
                "Define controlling Area",
                "Assign company code to controlling area",
                "Maintain controlling area",
                "Maintain Number range for Controlling Document",
                "Maintain versions",
                "Create cost centre (department)",
                "Cost Element Accounting (GL Accounting)",
                "Posting period and posting period variant",
            ],
        },
        {
            title: 'Display Organization Structure',
            subpoints: [
            ],
        },
        {
            title: 'Display Transport Organizer',
            subpoints: [
            ],
        },
        {
            title: 'Master Data',
            subpoints: [
                'Introduction of Master Data',
                'Material Master',
                'Vendor Master',
                'Info Record',
                'Source list',
                'Quota Arrangements',
            ],
        },
        {
            title: 'Document Type Forpurchasing Documents',
            subpoints: [
                'Introduction of different Purchasing documents',
                'Define documents type for',
                'Define Number ranges for document type',
                'Define screen layout at documents level',
            ],
        },
        {
            title: 'Purchasing',
            subpoints: [
                "Creation of different purchasing document",
                "Purchase Requisition (PR)",
                "Request for quotation (RFQ)",
                "PURCHASE ORDER (PO)",
                "PO Without any reference documents",
                "PO With reference to PR",
                "PO With reference to RFQ",
                "PO With reference to contract",
                "PO Cost Centre PO/ PO for Non-stock item/ PO with reference account assignment",
            ],
        },
        {
            title: 'Out Line Agreement',
            subpoints: [
                "Contract (Value contract / Quantity contract)",
                "Schedule Agreements"
            ],
        },
        {
            title: 'Automatic Account Determinations',
            subpoints: [
            ],
        },
        {
            title: 'Optimizing Purchasing',
            subpoints: [
                'Introduction of optimising purchasing',
                'Configuration of optimising Purchasing',
                'Automatic PO with reference to GR',
                'Automatic PO at GR',
            ],
        },
        {
            title: 'External Service Managements',
            subpoints: [
                "Introduction of optimising purchasing",
                "Configuration of optimising Purchasing",
                "Automatic PO with reference to GR",
                "Automatic PO at GR",
                "EXTERNAL SERVICE MANAGEMENTS (ESM)",
                "Introduction of external service Management",
                "Different types of service procurement",
                "Externalservice management configuration",
                "Creation of services",
                "Maintain services catalogue",
                "Create service purchase requisition",
                "Creation of Service purchase order",
                "Create service entry sheet",
                "Invoice for service",
            ],
        },
        {
            title: 'Inventory Managements',
            subpoints: [
                'Introduction of inventory Management',
                'Goods Receipt',
                'Transfer Posting',
                'Initial entry of stocks',
                'Good Issue',
                'Reservations',
            ],
        },
        {
            title: 'Physical Inventories',
            subpoints: [
                'Introduction of physical Inventory',
                'Creation of physical inventory documents',
                'Monitor stock overview',
                'Entry of inventory count',
                'List of physical inventory differences',
                'Post physical inventory differences',
            ],
        },
        {
            title: 'Special Stocks And Specialprocurement Type',
            subpoints: [
                'Introduction of special stocks and special procurement type',
                'Consignment',
                'Subcontracting',
                'Stock transfer using stock transport order',
                'Returnable transport packing',
                'Pipe Line Handling process',
            ],
        },
        {
            title: 'Invoice Verification',
            subpoints: [
                'Invoice for purchase order and their different scenarios',
                'Evaluate Receipt Settlement (ERS)',
                'Subsequent Debit',
                'Subsequent Credit',
                'Credit Memo',
                'Maintain GR/IR Clearing Account',
                'Invoice for planned and unplanned delivery',
            ],
        },
        {
            title: 'PR/ PO Release Strategy',
            subpoints: [
                'Definition of release strategy',
                'Configuration of release strategy for Purchase requisition',
                'Creation of Purchase requisition',
                'Release purchase requisition',
                'Configuration of release strategy for Purchase order',
                'Creation of purchase order',
                'Release purchase order',
            ],
        },
        {
            title: 'Split Valuations',
            subpoints: [
                'Introduction of split valuation',
                'Activate split valuation',
                'Configuration of split valuation',
                'Definition of global categories',
                'Assign valuation type to valuation category',
                'Assign Valuation category to valuation area',
            ],
        },
        {
            title: 'Pricing Procedures',
            subpoints: [
                "Introduction of pricing procedure",
                "Define condition table",
                "Define access sequence",
                "Define condition table",
                "Define calculation schema",
                "Schema Group for vendor",
                "Schema group for purchasing organisation",
                "Assignment of schema group to purchasing organisation",
                "Define schema determination",
                "Creation of purchase order",
            ],
        },
        {
            title: 'End User MM',
            subpoints: [
                'Material Creation',
                '125) Vendor Creation',
                '126) P2P:- Process:',
                'a) PR creation',
                'b) RFQ creation',
                'c) PO creation',
                'd) GR (good receipt)',
                'e) MIRO (invoice)',
                'f) Vendor Payment (F-53)',
                '127) Special Procurement',
                'a) Consignment',
                'b) Subcontracting',
                'c) Pipeline',
                'd) RTP',
                '128) Service Procurement (Plan/Unplan)',
                '129) GR – Back',
                '130) Return Process',
                '131) Transfer Posting (Stock –To- Stock) (Plant- To-Plant)',
                '132) Negative Stock',
                '133) Blanket + Purchase Order',
                '134) Outline Agreement',
                '135) Physical Inventory',
                '136) Reservation',
            ],
        },

    ];

    const syllabus2 = [
        {
            title: 'Sap introduction & overview',
            subpoints: [
                'Evolution of sap over time from ECC to S4HANA',
                'How sap helps business – Understanding the meaning of business process',
                'Introduciton to various modules in sap',
                'Different stages of sap project and role of sap consultant',
                'Things to know before getting started in sap eg. Client, T code,sap OSS etc',
                'Navigation in sap',
            ],
        },
        {
            title: 'Key innovation in S4HANA',
            subpoints: [
                "Business partner",
                "Inventory management",
                "Architectural changes/ Configurations related to sourcing & procurement",
                "Overview of SAP Firoi app",
            ],
        },
        {
            title: 'Organization structure',
            subpoints: [
                'Client, Company code',
                'Purchase organization, Plant, Storage location, Purchase group',


            ],
        },
        {
            title: 'Master Data',
            subpoints: [
                'Material master',
                'Material number field _ length extension',
                'Material type',
                'MRP field in the material master',
                'Simplified prodcut master table',
                'Vendor master (Business partner approach)',
                'Info record, Source list, Quota',
            ],
        },
        {
            title: 'Procurement of stock & Consumable material',
            subpoints: [
                'Quotation processing,',
                'Purchase requisition creation',
                'Purchase order creation',
                'Contracts',
                'Scheduling agreements',
                'Blanket purchase order',
                'Automatic PO creation',
            ],
        },
        {
            title: 'Special Business Processes',
            subpoints: [
                'Subcontracting',
                'Stock Transfer Order',
                'Consignment',
            ],
        },
        {
            title: 'Release Strategy',
            subpoints: [
                "PR Release strategy"
            ],
        },
        {
            title: 'Output Message Determination',
            subpoints: [
                "PO output determination"
            ],
        },
        {
            title: 'Pricing',
            subpoints: [
                'Condition type, Pricing procedure, Condition records'
            ],
        },
        {
            title: 'Valuation',
            subpoints: [
                'Material valuation (S/ V price control)',
                'Valuation class',
            ],
        },
        {
            title: 'Inventory Management',
            subpoints: [
                'Movement types',
                'Goods Receipt Scenarios',
                'Goods Issue Scenarios',
                'Stock Transfer',
                'Transfer Posting',
                'Inventory Table Reduction',
                'Obsolete transactions',
            ],
        },
        {
            title: 'Physical Inventory',
            subpoints: [
                'Types of Physical Inventory',
                'Creating Physicals Inventory Documents',
            ],
        },
        {
            title: 'Invoice Verification',
            subpoints: [
                'Invoice Posting',
                'Credit Memo',
                'Subsequent Debit/ Credit',
                'Authority concept for the Supplier Invoice',
                'Automatic Document Adjustment',
            ],
        },
        {
            title: 'Vendor Rebate Arrangements',
            subpoints: [
                'Subsequent settlement – Vendor Rebate Agreements'
            ],
        },
        {
            title: 'IN ADDITION',
            subpoints: [
                '5+ PROJECTS ON SAP',
                '20+ TOPIC WISE TESTS',
                'RESUME BUILDING',
                'INTERVIEW PREPARATION',
                '6 MONTH INTERNSHIP WITH CIN NO.',
                'JOB OPPORTUNITIES',
                'ONE ON ONE CLASSROOM INTERVIEW PRACTICE',
            ],
        },
    ];

    const keyFeatures = [
        '124+ Hours course duration',
        '100% Job oriented training',
        'Industry expert faculties',
        'Free demo class available',
        'Certification guidance',
        'Completed 200+ batches',

    ];

    const faqs = [
        {
            question: "What is SAP MM?",
            answer: "SAP MM, or Material Management, is a module in SAP ERP that deals with the procurement and inventory functions of an organization. It involves managing materials, vendors, procurement processes, and more.",
        },
        {
            question: "What are the key components of SAP MM?",
            answer: "SAP MM comprises various components, including Material Master, Vendor Master, Purchasing, Inventory Management, and Invoice Verification. Each component plays a vital role in managing different aspects of material and procurement processes.",
        },
        {
            question: "How is SAP MM different from SAP SD?",
            answer: "While SAP MM focuses on material management and procurement, SAP SD (Sales and Distribution) deals with sales, order processing, and distribution. MM is more related to the supply chain and internal procurement, whereas SD is customer-oriented, covering the sales process.",
        },
        {
            question: "How does SAP MM Contribute to supply chain management?",
            answer: "SAP MM plays a critical role in the supply chain by optimizing procurement processes, managing inventory efficiently, and ensuring a smooth flow of materials from suppliers to production and finally to customers. It helps in maintaining an organized and cost-effective supply chain.",
        },
        {
            question: "Can SAP MM integrate with other SAP modules?",
            answer: "Yes, SAP MM module can integrate seamlessly with other SAP modules like SAP SD, SAP PP (Production Planning), and SAP WM (Warehouse Management). This integration ensures a holistic approach to business processes, from procurement to production and delivery. Check for Salesforce Training Also",
        },
        {
            question: "What are the common challenges in SAP MM implementation?",
            answer: "Challenges in SAP MM implementation may include data migration issues, user resistance, customization complexities, and ensuring alignment with the organization’s specific business processes. Thorough planning, training, and testing are essential to overcome these challenges.",
        },
        {
            question: "How does SAP MM contribute to cost savings?",
            answer: "SAP MM helps in cost savings by optimizing procurement processes, negotiating better deals with suppliers, reducing inventory carrying costs through efficient inventory management, and minimizing errors in the procurement-to-payment cycle.",
        },
        {
            question: "Is SAP MM certification necessary for a career in SAP MM?",
            answer: "While not mandatory, SAP MM certification can enhance your credibility and marketability in the job market. It validates your skills and knowledge in SAP MM, making you a more competitive candidate for SAP MM-related roles.",
        },
    ];

    const whatsData = [
        {
            title: 'What is SAP MM ?',
            points: [
                "SAP MM is a module of SAP that helps to manage the entire supply chain process.",
                "The primary role of SAP MM is to ensure that all materials of an organization are maintained in the right quantities at the right time in the right place.",
                "SAP MM training institute helps organizations to enhance their supply chain, inventory and warehouse management by providing complete control over the workforce, machine and materials.",
                "Streamlining procurement processes and optimizing inventory management, SAP MM enhances operational efficiency and ensures cost-effective supply chain management.",
                "SAP MM module is a leading enterprise software solution, and its demand continues to surge as businesses seek robust integrated systems for efficient resource planning, management, and analytics.",
                "Sapalogy authorised training centers in Nagpur provides SAP MM training in offline and online mode.Starting end user, consulting, implementation, support with real time SAP project based training.",
                "IT background, non IT background, freshers, experience can start their career in SAP irrespective of their background.",
                "Sapalogy is the best training institute in nagpur with the 100% job opportunities.",
            ]
        }
    ]

    // const sliderSettings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 3,
    //     slidesToScroll: 1,
    //     responsive: [
    //         {
    //             breakpoint: 1024,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 1,
    //             },
    //         },
    //         {
    //             breakpoint: 600,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //             },
    //         },
    //     ],
    // };

    // const imageList = [
    //     { Image: dev1, alt: "Award 1" },
    //     { Image: dev2, alt: "Award 2" },
    //     { Image: dev3, alt: "Award 3" },
    //     { Image: dev4, alt: "Award 4" },
    //     { Image: dev5, alt: "Award 5" },
    //     { Image: dev6, alt: "Award 6" },
    //     { Image: dev7, alt: "Award 7" },
    //     { Image: dev8, alt: "Award 8" },
    // ];

    // const reviewList = [
    //     { Image: rev1, alt: "rev1" },
    //     { Image: rev2, alt: "rev1" },
    //     { Image: rev3, alt: "rev1" },
    //     { Image: rev4, alt: "rev1" },
    //     { Image: rev5, alt: "rev1" },
    // ]

    return (
        <div className="devops-page">
               <SideDropdown roadmapData={modulesData} />
            <div className="content-container">
                <div className="devops-circle devops-circle-bottom-left"></div>
                <div className="left-content">
                    <h1>Best SAP MM Training</h1>
                    <p>
                        SAP MM Training by Sapalogy offers you the best SAP course institute & authorised training centers in nagpur for freshers and experienced candidates in Nagpur, with expert guidance and 100% guaranteed placement assistance. Our SAP MM Training course covers every technical topic in detail and the student is left with real-time hands-on experience.
                    </p>
                    <br />
                    <p>Our practical, Job-oriented training program will not only provide you with a certificate but also with knowledge equivalent to around 2+ years of field experience. We value your time as much as over. Hence we invented to train you in SAP MM with S/4 HANA in just 4 months.</p>
                    <p>Sapalogy SAP training institute in nagpur is practical, Job-oriented training program will not only provide you with a certificate but also with knowledge equivalent to around 7+ years of field experience. Sapalogy SAP training institute value your time as much as over. Hence Sapalogy SAP training institute invented to train you in SAP MM with S/4 HANA in just 4 months.</p>
                    <ul className='points-list'>
                        <li>End user 1 month (ECC / S4 HANA)</li>
                        <li>Configuration 2 months(ECC/S4 HANA)</li>
                        <li>Project 1 month</li>
                    </ul>
                    <h3>All the topics will be covered in detail and also include:</h3>
                    <ul className="points-list">
                        <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
                        <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
                        <li><FontAwesomeIcon icon={faBriefcase} /> 6-months internship</li>
                        <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
                    </ul>
                </div>
                <PageForm />
            </div>
            
            <div className="video-section">
                <div className="video-container" controls ref={videoRef}></div>
            </div>
            <WhatsDevOps whatsData={whatsData} />
            <Roadmap heading="Roadmap to Learn SAP MM" roadmapData={roadmapData} />
            <Syllabus heading="SAP MM Training Syllabus" Syllabus={syllabus} />
            <Syllabus heading="S4 HANA Training Syllabus" Syllabus={syllabus2} />
            <KeyFeatures heading="SAP MM Training" keyFeatures={keyFeatures} />

            <div className="batch-schedule-section">
                <h2>Upcoming Batch Schedule for SAP MM Training</h2>
                <p>Sapalogy provides flexible timings to all our students. Here is the SAP MM Training Class Schedule in our branches. If this schedule doesn’t match please let us know. We will try to arrange appropriate timings based on your flexible timings.</p>
                <div className="batch-schedule-table-wrapper">
                    <table className="batch-schedule-table">
                        <thead>
                            <tr>
                                <th>Course</th>
                                <th>Batch Time</th>
                                <th>Offline</th>
                                <th>Online</th>
                                <th>Enquire Now</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>SAP MM</td>
                                <td>Starts every week</td>
                                <td>Nagpur</td>
                                <td>India</td>
                                <td><a href="/contact">Enquire Now</a></td>
                            </tr>
                            <tr>
                                <td>SAP FICO</td>
                                <td>Starts every week</td>
                                <td>Nagpur</td>
                                <td>India</td>
                                <td><a href="/contact">Enquire Now</a></td>
                            </tr>
                            <tr>
                                <td>SAP ABAP</td>
                                <td>Starts every week</td>
                                <td>Nagpur</td>
                                <td>India</td>
                                <td><a href="/contact">Enquire Now</a></td>
                            </tr>
                            <tr>
                                <td>SAP</td>
                                <td>Starts every week</td>
                                <td>Nagpur</td>
                                <td>India</td>
                                <td><a href="/contact">Enquire Now</a></td>
                            </tr>
                        </tbody>
                    </table></div>
            </div>

            <section className="devops-certification-section">
                <h2 className="certification-heading">SAP MM CERTIFICATION</h2>
                <div className="certification-content">
                    <div className="certification-points">
                        <ul>
                            <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
                            <li>The SAP S/4HANA certification offered by Sapalogy will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
                            <li>Sapalogy provides comprehensive guidance for your SAP S/4HANA global certification, ensuring a 100% passing guarantee in examinations such as SAP Certification, SAP S/4HANA Platform DeveloMMent Certification, and various other global exams.</li>
                        </ul>
                    </div>
                    <div className="certification-image">
                        <img src={certificateImg} alt="DevOps Certification" />
                    </div>
                </div>
            </section>
            <Roadmap heading="SAP modules" roadmapData={modulesData} />
            {/* devops project Section
            <div className="slider-container">
                <h2 className="slider-heading">Devops Project</h2>
                <RegularSlider sliderSettings={sliderSettings} images={imageList} />
            </div> */}

            <Faq Faqs={faqs} />

            {/* <div className="slider-container">
                <h2 className="slider-heading">SAP MM Training courses review</h2>
                <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
            </div> */}


            <div className="alumni-section">
                <h1>Our Alumni Works At</h1>
                <div className="full-screen-image">
                    <img src={IMG} alt="Full Screen" />
                </div>
            </div>
            <Contact />

        </div>
    );
};

export default SAPMM;