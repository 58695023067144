import React from 'react';
import '../Css/DevOps.css';

const KeyFeatures = ({ keyFeatures, heading }) => {
    // Calculate the midpoint of the keyFeatures array to equally distribute between two columns
    const midpoint = Math.ceil(keyFeatures.length / 2);

    return (
        <div className="devops-key-features">
            <h2 className='main-heading'>Key Features of {heading}</h2>
            <div className="devops-features-container">
                <div className="devops-features-left">
                    {keyFeatures.slice(0, midpoint).map((feature, index) => (
                        <div key={index} className="devops-feature-item">
                            <i className="fas fa-check-circle"></i> {feature}
                        </div>
                    ))}
                </div>
                <div className="devops-features-right">
                    {keyFeatures.slice(midpoint).map((feature, index) => (
                        <div key={index} className="devops-feature-item">
                            <i className="fas fa-check-circle"></i> {feature}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default KeyFeatures;
