import React, { useEffect, useRef, useState } from 'react';
import '../Css/DevOps.css';
import certificateImg from '../assets/certificate.webp';
import Contact from '../pages/Contact'
import IMG from '../assets/Work.webp'
import Roadmap from '../Components/Roadmap';
import WhatsDevOps from '../Components/WhatsDevOps'
import PageForm from '../Components/PageForm';
import Syllabus from '../Components/Syllabus';
import KeyFeatures from '../Components/KeyFeatures';
import Faq from '../Components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
// project slider image
import dev1 from '../assets/Projects/AIML/1.png';
import dev2 from '../assets/Projects/AIML/2.png';
import dev3 from '../assets/Projects/AIML/3.png';
import dev4 from '../assets/Projects/AIML/4.png';
import dev5 from '../assets/Projects/AIML/5.png';
import dev6 from '../assets/Projects/AIML/6.png';
import dev7 from '../assets/Projects/AIML/7.png';
import dev8 from '../assets/Projects/AIML/8.png';

// rev image
import rev1 from '../assets/Reviews/AIML/2.png';
import rev2 from '../assets/Reviews/AIML/3.png';
import rev3 from '../assets/Reviews/AIML/4.png';
import rev4 from '../assets/Reviews/AIML/5.png';

import RegularSlider from '../Components/RegularSlider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const DevOpsPage = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);


  useEffect(() => {
    // Function to create the YouTube player
    const createPlayer = () => {
      if (window.YT && window.YT.Player) {
        playerRef.current = new window.YT.Player(videoRef.current, {
          videoId: 'AGALIxFkB94', // Updated YouTube video ID
          events: {
            onReady: (event) => {
              // event.target.mute(); // Mute the video to allow autoplay
              event.target.playVideo();
            },
          },
          playerVars: {
            loop: 1,
            controls: 0, // Hide video controls
            modestbranding: 1, // Minimal YouTube branding
            playlist: 'AGALIxFkB94', // Required for looping
          },
        });
      }
    };

    // Load YouTube IFrame API if it's not already loaded
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Set up callback for when the API is ready
      window.onYouTubeIframeAPIReady = () => {
        createPlayer();
      };
    } else {
      createPlayer();
    }
  }, []);


  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'What is AI & ML?',
'AI & ML course',
'AI & ML certification',
'AI & ML jobs in India',
'AI & ML jobs in Nagpur',
      ]
    },
    {
      title: 'Foundational Skills',
      points: [
        'Math: Linear algebra, calculus, probability & statistics are crucial for understanding algorithms and analyzing data.',
        'Programming: Python is the go-to language. Learn about data structures, algorithms, and object-oriented programming. Explore libraries like NumPy, Pandas, Matplotlib.',
        'Databases: Get familiar with SQL for querying and manipulating relational databases.',
      ]
    },
    {
      title: 'Data Science Core',
      points: [
        'Machine Learning: Supervised (regression, classification) and unsupervised (clustering, dimensionality reduction) learning algorithms.',
        'Statistics & Hypothesis testing: Understand statistical testing, p-values, and confidence intervals.',
        'Data Wrangling & Cleaning: Learn data manipulation techniques with Pandas and data cleaning practices.',
        'Data Visualization: Effectively communicate insights using libraries like Matplotlib, Seaborn, Tableau.'
      ]
    },
    {
      title: 'Deepening the Knowledge',
      points: [
        'Advanced ML Algorithms: Ensemble methods, boosting, time series analysis, deep learning (neural networks, CNNs).',
        'Domain Knowledge: Choose a specific domain (finance, healthcare, etc.) to gain industry-specific knowledge and problem-solving skills.',
        'Big Data Technologies: Explore Apache Spark, Hadoop for handling large datasets.',
        'Cloud Computing: Familiarize yourself with cloud platforms like AWS, Azure, Google Cloud Platform for data storage and computing.'
      ]
    },
    {
      title: 'Specialization & Projects',
      points: [
        'Specialization: Pick a niche area (e.g., NLP, computer vision, recommender systems) and dive deeper.',
        'Portfolio Building: Work on real-world projects to showcase your skills and understanding. Contribute to open-source projects.',
        'Communication & Collaboration: Hone your communication skills to explain complex technical concepts effectively. Learn to collaborate effectively in teams.',
      ]
    },
    {
      title: ' Feedback Loop and Iteration',
      points: [
        'Establish a feedback loop for continuous improvement based on user feedback and evolving business needs.',
        'Iteratively update models and algorithms to adapt to changing conditions.',
      ]
    },
    {
      title: 'Cost Analysis and ROI Measurement',
      points: [
        'Conduct cost analysis for AI and ML implementation, including infrastructure, training, and maintenance.',
        'Measure the return on investment (ROI) based on improved efficiency, cost savings, and other business metrics.',
      ]
    },
    {
      title: 'Resume Preparation',
      points: [
        'Include keywords.',
        'How to prepare resume.',
        'How to prepare resume for freshers.',
        'Show impact.',
        'Include soft skills.',
        'Be unique.'
      ]
    }
  ];

  const syllabus = [
    {
      title: 'Introduction to Python',
      subpoints: [
        'Python Basics',
'Python Functions and Packages',
'Working with Data Structures, Arrays,Vectors & Data Frames',
'Google colab notebook',
'Pandas, NumPy, Matplotlib, Seaborn',
      ],
    },
    {
      title: 'Applied Statistics',
      subpoints: [
        'Descriptive Statistics',
'Probability & Conditional Probability',
'Hypothesis Testing',
'Inferential Statistics',
'Probability Distributions',
      ],
    },
    {
      title: 'EDA and Data Processing',
      subpoints: [
        'Data Types',
'Dispersion & Skewness',
'Uni & Multivariate Analysis',
'Data Imputation',
'Identifying and Normalizing Outlier',
      ],
    },
    {
      title: 'Introduction to Neural Networks and Deep Learning',
      subpoints: [
        'Introduction to Perceptron & Neural Networks',
'Activation and Loss functions',
'Gradient Descent',
'Batch Normalization',
'TensorFlow & Keras for Neural Networks',
'Hyper Parameter Tuning',
      ],
    },
    {
      title: 'Computer Vision',
      subpoints: [
        'Introduction to Convolutional Neural Networks',
'Introduction to Images',
'Convolution, Pooling, Padding & its Mechanisms',
'Forward Propagation & Backpropagation for CNNs',
'CNN architectures like AlexNet, VGGNet, InceptionNet & ResNet',
'Transfer Learning',
'Object Detection',
'YOLO, R-CNN, SSD',
'Semantic Segmentation',
'U-Net',
'Face Recognition using Siamese Networks',
'Instance Segmentation',
'3 PROJECTS ON CV',
      ],
    },
    {
      title: 'NLP (Natural Language Processing)',
      subpoints: [
        'Introduction to NLP',
'Stop Words.',
'Tokenization',
'Stemming and Lemmatization',
'Bag of Words Model',
'Word Vectorizer',
'TF-IDF',
'POS Tagging',
'Named Entity Recognition',
'Introduction to Sequential data',
'RNNs and its Mechanisms',
'Vanishing & Exploding gradients in RNNs',
'LSTMs – Long short-term memory',
'GRUs – Gated Recurrent Unit',
'LSTMs Applications',
'Time Series Analysis',
'LSTMs with Attention Mechanism',
'Neural Machine Translation',
'Advanced Language Models:',
'Transformers, BERT, XLNet',
'3 PROJECTS ON NLP',
      ],
    },
    {
      title: 'Introduction to Reinforcement Learning (RL)',
      subpoints: [
        'RL Framework',
'Component of RL Framework',
'Examples of RL Systems',
'Types of RL Systems',
'Q-learning',
      ],
    },
    {
      title: 'Introduction to GANS (Generative Adversarial Networks)',
      subpoints: [
        'Introduction to GANS',
'Generative Networks',
'Adversarial Networks',
'How do GANs work?',
'DCGANS – Deep Convolution GANs',
'Applications of GANS',
      ],
    },
    {
      title: 'ADDITIONAL MODULES',
      subpoints: [
        'EDA',
 'Time Series Forecasting',
'Pre Work for Deep Learning',
'Model Deployment',
'Visualization using Tensor board',
'GANS (Generative Adversarial Networks)',
 'Reinforcement Learning',
 'Recommendation Systems',
      ],
    },
    {
      title: 'Chat GPT and Open Al Timeline of NLP and Generative Al',
      subpoints: [
        'Frameworks for Understanding Chat GPT and Generative Al',
'Implications for Work, Business, and Education Output',
'Modalities and Limitations',
'Business Roles to Leverage Chat GPT Prompt Engineering for Fine-Tuning Outputs',
'Practical Demonstration and Bonus Section on RLHF',
'Introduction to Generative Al',
'Al vs ML',
'DL vs Gen Al',
'Supervised vs Unsupervised Learning.',
'Discriminative vs Generative Al',
'A Brief Timeline of Gen Al Basics of Generative Models',
'Large Language Models Word Vectors',
'Chat GPT: The Development Stack Attention Mechanism',
'Business Applications of ML, DL and Gen Al Hands-on Bing Images and Chat GPT',],
    },
    {
      title: 'Introduction to generative AI & Prompt engineering',
      subpoints: [
        'Mathematical Fundamentals for Generative AI',
'VAES: First Generative Neural Networks',
'GANS: Photorealistic Image Generation',
'Conditional GANs and Stable Diffusion',
'Transformer Models: Generative Al for Natural Language',
'Chat GPT: Conversational Generative Al',
'Hands-On Chat GPT Prototype Creation',
'Next Steps for Further Learning and Understanding',
'3 PROJECTS ON Chat GPT and Prompt Engineering (1 Week)',

      ],
    },
    {
      title: 'Profit center accounting',
      subpoints: [
        'Understanding profit center and its use',
'Profit center hierarchy & master data',
'Profit center derivation & document splitting',],
    },
    {
      title: 'In Addition',
      subpoints: [
        '5+ projects on sap',
'20+ topic wise tests',
'Resume building',
'Interview preparation',
'6 month internship with cin no.',
'Job opportunities',
'One on one classroom interview practice',],
    },
   
  ];

  const keyFeatures = [
    "220+ Hours course duration",
    "Industry expert faculties",
    "Completed 100+ batches",
    "100% Job oriented training",
    "Free demo class available",
    "Certification guidance"
  ];

  const faqs = [
    {
      question: "What's AI?",
      answer: "AI, or Artificial Intelligence, refers to the development of computer systems that can perform tasks that typically require human intelligence, such as speech recognition, decision-making, and learning.",
    },
    {
      question: "What is Machine Learning (ML)?",
      answer: "ML is a subset of AI that focuses on developing algorithms and models that enable computers to learn from data and improve their performance over time without being explicitly programmed.",
    },
    {
      question: "How does Machine Learning work?",
      answer: "ML systems learn patterns from data by using algorithms that adjust parameters to minimize errors. Common techniques include supervised learning (using labeled data) and unsupervised learning (finding patterns in unlabeled data).",
    },
    {
      question: "Difference between AI and ML?",
      answer: "AI is a broader concept, while ML is a subset of AI. AI encompasses any technique that allows computers to mimic human intelligence, whereas ML specifically involves learning from data.",
    },
    {
      question: "What is Deep Learning?",
      answer: "Deep Learning is a subset of ML that uses neural networks with multiple layers (deep neural networks) to model and solve complex problems, achieving high-level abstractions in data representation.",
    },
    {
      question: "Common Machine Learning Algorithms?",
      answer: "Some popular ML algorithms include Linear Regression, Decision Trees, Random Forest, Support Vector Machines, K-Nearest Neighbors, and Neural Networks.",
    },
    {
      question: "Supervised vs. Unsupervised Learning?",
      answer: "In supervised learning, the algorithm is trained on labeled data, while unsupervised learning involves finding patterns in unlabeled data without predefined outputs.",
    },
    {
      question: "Natural Language Processing (NLP) in AI?",
      answer: "NLP focuses on enabling computers to understand, interpret, and generate human language. Applications include language translation, sentiment analysis, and chatbots.",
    },
    {
      question: "Reinforcement Learning?",
      answer: "Reinforcement Learning involves training models to make sequences of decisions by receiving feedback in the form of rewards or penalties, commonly used in gaming, robotics, and autonomous systems.",
    },
    {
      question: "Bias in AI?",
      answer: "Bias in AI refers to the unfair and disproportionate impact of algorithms on certain groups. It can occur due to biased training data, algorithm design, or unintended consequences during implementation.",
    },
    {
      question: "Ethical concerns in AI?",
      answer: "Ethical concerns in AI include issues related to bias, transparency, accountability, job displacement, and privacy. Ensuring ethical AI development and use is crucial for responsible implementation.",
    },
    {
      question: "Future trends in AI and ML?",
      answer: "Emerging trends include Explainable AI, Federated Learning, AI-driven automation, AI in healthcare, and the continued advancement of reinforcement learning and natural language processing.",
    },
  ];

  const whatsData = [
    {
      title: 'What is AI & ML?',
      points: [
        'AI (Artificial Intelligence) is a branch of pc technological know-how focused on growing machines capable of sensible conduct.',
        'ML (Machine Learning) is a subset of AI that involves education algorithms to study styles from statistics and make predictions or decisions.',
        'ML algorithms enhance overall performance over the years via learning from revel in and adapting to new facts.',
        'Supervised mastering entails schooling ML models on classified information, while unsupervised studying discovers styles in unlabeled records.',
        'AI and ML packages span various industries, inclusive of healthcare, finance, and robotics, riding innovation and automation.',
      ]
    }
  ]

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const imageList = [
    { Image: dev1, alt: "Award 1" },
    { Image: dev2, alt: "Award 2" },
    { Image: dev3, alt: "Award 3" },
    { Image: dev4, alt: "Award 4" },
    { Image: dev5, alt: "Award 5" },
    { Image: dev6, alt: "Award 6" },
    { Image: dev7, alt: "Award 7" },
    { Image: dev8, alt: "Award 8" },
  ];

  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    // { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },
   
  ]


  return (
    <div className="devops-page">
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Best AI & ML Training</h1>
          <p>
          Best AI & ML Training by Sapalogy training offers you the best AI & ML training in Nagpur for freshers and experience candidate in Nagpur, with expert guidance and 100% guaranteed placement assistance. Our AI & ML training course covers every technical topics in details and the student is left with real-time hand on experience.
          </p>
          <p>
          Our practical, Job-oriented training program will not only provide you with a certificate but also with knowledge equivalent to around 2+ years of field experience. We value your time as much as over. Hence we invented to train you in AI & ML in just 7 months.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> 6-months internship</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
        </ul>
        </div>

        <PageForm />
      </div>

      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>

      </div>
      <WhatsDevOps whatsData={whatsData} />
      <Roadmap heading="Roadmap to learn AI & ML with Sapalogy" roadmapData={roadmapData} />
      <Syllabus heading="AI & ML Training syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="of AI & ML Training" keyFeatures={keyFeatures} />

      <div className="batch-schedule-section">
        <h2>Upcoming Batch Schedule for AI & ML Training</h2>
        <p>
          Sapalogy provides flexible timings to all our students. Here is the DevOps Training Class Schedule in our branches. If this schedule doesn’t match your availability, please let us know. We will try to arrange appropriate timings based on your flexible timings.
        </p>
        <div className="batch-schedule-table-wrapper">
        <table className="batch-schedule-table">
          <thead>
            <tr>
              <th>Course</th>
              <th>Batch Time</th>
              <th>Offline</th>
              <th>Online</th>
              <th>Enquire Now</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>AI & ML</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
            <tr>
              <td>Machine learning</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
            <tr>
              <td>Data science</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div>
</div>


      <section className="devops-certification-section">
        <h2 className="certification-heading">AI & ML CERTIFICATION</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
              <li>The AI & ML certification offered by Sapalogy will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
              <li>Sapalogy provides comprehensive guidance for your AI & ML global certification, ensuring a 100% passing guarantee in examinations such as AI & ML Certification, AI & ML Platform Development Certification, and various other global exams.</li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>



      {/* devops project Section */}
      <div className="slider-container">
        <h2 className="slider-heading">AI & ML Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div>

      <Faq Faqs={faqs} />

      <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div>


      <div className="alumni-section">
        <h1>Our Alumni Works At</h1>
        <div className="full-screen-image">
          <img src={IMG} alt="Full Screen Image" />
        </div>
      </div>
      <Contact />

    </div>
  );
};

export default DevOpsPage;