import React, { useEffect, useRef } from 'react';
import '../../Css/DevOps.css';
import certificateImg from '../../assets/certificate.webp';
import Contact from '../Contact'
import IMG from '../../assets/Work.webp'
import Roadmap from '../../Components/Roadmap';
import WhatsDevOps from '../../Components/WhatsDevOps'
import PageForm from '../../Components/PageForm';
import Syllabus from '../../Components/Syllabus';
import KeyFeatures from '../../Components/KeyFeatures';
import Faq from '../../Components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
import dev1 from '../../assets/Projects/Devops/DEV1.png';
import dev2 from '../../assets/Projects/Devops/DEV2.png';
import dev3 from '../../assets/Projects/Devops/DEV3.png';
import dev4 from '../../assets/Projects/Devops/DEV4.png';
import dev5 from '../../assets/Projects/Devops/DEV5.png';
import dev6 from '../../assets/Projects/Devops/DEV6.png';
import dev7 from '../../assets/Projects/Devops/DEV7.png';
import dev8 from '../../assets/Projects/Devops/DEV8.png';
import rev1 from '../../assets/Reviews/DEVOPS/devrev1.png';
import rev2 from '../../assets/Reviews/DEVOPS/devrev2.png';
import rev3 from '../../assets/Reviews/DEVOPS/devrev3.png';
import rev4 from '../../assets/Reviews/DEVOPS/devrev4.png';
import rev5 from '../../assets/Reviews/DEVOPS/devrev5.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import SideDropdown from '../../Components/SideDropdown';


const SAPPP = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);


  // useEffect(() => {
  //   // Function to create the YouTube player
  //   const createPlayer = () => {
  //     if (window.YT && window.YT.Player) {
  //       playerRef.current = new window.YT.Player(videoRef.current, {
  //         videoId: 'i3HdVH6gdfE', // Updated YouTube video ID
  //         events: {
  //           onReady: (event) => {
  //             event.target.mute(); // Mute the video to allow autoplay
  //             // event.target.playVideo();
  //           },
  //         },
  //         playerVars: {
  //           loop: 1,
  //           controls: 0, // Hide video controls
  //           modestbranding: 1, // Minimal YouTube branding
  //           playlist: 'i3HdVH6gdfE', // Required for looping
  //         },
  //       });
  //     }
  //   };

  //   // Load YouTube IFrame API if it's not already loaded
  //   if (!window.YT) {
  //     const tag = document.createElement('script');
  //     tag.src = 'https://www.youtube.com/iframe_api';
  //     const firstScriptTag = document.getElementsByTagName('script')[0];
  //     firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  //     // Set up callback for when the API is ready
  //     window.onYouTubeIframeAPIReady = () => {
  //       createPlayer();
  //     };
  //   } else {
  //     createPlayer();
  //   }
  // }, []);

  const modulesData = [
    {
      title: 'SAP',
      points: [
          'Sapalogy Training in Nagpur offers expert SAP training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
      ],
      link: "/best-sap-training"
  },
    {
      title: 'SAP BASIS',
      points: [
        'Sapalogy Training in Nagpur offers expert SAP BASIS training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
      ],
      link: "/sap-basis-training-in-nagpur"
    },
    {
      title: 'SAP MM',
      points: [
        'SAP MM (Materials Management) is a module in SAP ERP that manages procurement processes and inventory management. Sapalogy Institute in Nagpur offers comprehensive training in SAP MM for effective utilization ',
      ],
      link: "/sap-mm"
    },
    {
      title: 'SAP FICO',
      points: [
        'SAP FICO, offered by Sapalogy Institute Nagpur, encompasses financial accounting and controlling modules within the SAP ERP system, facilitating comprehensive financial management and reporting for organizations'
      ],
      link: "/sap-fico"
    },
    {
      title: 'SAP ABAP',
      points: [
        'SAP ABAP is a programming language used for developing applications within the SAP ecosystem. Sapalogy Institute in Nagpur is a training center specializing in SAP courses, including ABAP programming to equip professionals with the skills.',
      ],
      link: "/sap-abap",
    },
    {
      title: 'SAP HCM',
      points: [
        'SAP HCM (Human Capital Management) is an integrated software suite by SAP that manages HR processes, from recruitment to payroll. Sapalogy Institute in Nagpur likely provides training in SAP HCM and related modules.'
      ],
      link: "/sap-hcm",
    },
    {
      title: 'SAP SCM',
      points: [
        'SAP SCM (Supply Chain Management) is a software suite by SAP that integrates and optimizes key supply chain processes for enhanced efficiency. It enables businesses to manage procurement, production, and distribution seamlessly, ensuring streamlined operations.',
      ],
      link: "/sap-scm"
    },
    {
      title: 'SAP ARIBA',
      points: [
        'SAP Ariba is a cloud-based procurement platform by SAP that streamlines and automates procurement processes, enhancing collaboration between buyers and suppliers. Sapalogy Institute Nagpur is an educational institution specializing in SAP training and certification.',
      ],
      link: "/sap-ariba"
    },
    {
      title: 'SAP PP',
      points: [
        'SAP PP (Production Planning) is an SAP module that helps organizations manage and optimize their manufacturing processes, from planning and scheduling to execution. It integrates various business functions to streamline production.',
      ],
      link: "/sap-pp"
    },
    {
      title: 'SAP SD',
      points: [
        'SAP SD (Sales and Distribution) is a module in SAP ERP that manages sales and customer service processes. It covers order-to-cash processes, including sales order processing, pricing, delivery, and billing. SAP SD to equip individuals with skills.',
      ],
      link: "/sap-sd"
    },
    {
      title: 'SAP FIORI',
      points: [
        'SAP Fiori is a user experience (UX) design approach and set of design principles that enhance the usability and functionality of SAP applications. It provides a modern, responsive, and personalized user interface for a seamless and intuitive user experience in the SAP environment.',
      ],
      link: "/sap-fiori"
    },
    {
      title: 'SAP QM',
      points: [
        'SAP QM (Quality Management) is a module in SAP ERP that facilitates quality control and assurance processes in manufacturing and production. It helps organizations manage quality planning, inspection, and certification, ensuring products meet specified standards and many more.',
      ],
      link: "/sap-qm"
    },
    {
      title: 'SAP PM',
      points: [
        'SAP PM (Plant Maintenance) is a module in SAP ERP that helps organizations effectively manage and maintain their assets, equipment, and facilities. It covers processes such as preventive maintenance, work orders, and equipment tracking to optimize asset performance.',
      ],
      link: "/sap-pm"
    },
    {
      title: 'SAP WM & EWM',
      points: [
        'SAP WM (Warehouse Management) focuses on efficient inventory management within a warehouse, while SAP EWM (Extended Warehouse Management) extends these capabilities with advanced features like labour management and slotting optimization for operations.',
      ],
      link: "/sap-wm-ewm"
    },
    {
      title: 'SAP LE & SL',
      points: [
        'SAP LE (Logistics Execution) is a module that manages and optimizes warehouse and transportation processes, while SAP SL (Slotting) focuses on optimizing storage space and picking efficiency within a warehouse. Both modules contribute to streamlining supply chain operations in SAP systems.',
      ],
      link: "/sap-le-sl"
    },
  ];
  const roadmapData = [
    {
      title: 'Introduction',
      points: [
       ' What is SAP?',
       ' What is SAP PP?',
       ' What is SAP HANA?',
       ' SAP course',
       ' SAP certification',
       ' SAP jobs in india',
       ' SAP jobs in nagpur',
      ]
    },
    {
      title: ' System Landscape Design',
      points: [
        'Design the SAP landscape, considering integration with other modules (e.g., SAP MM, SAP SD).',
        'Plan system architecture, hardware requirements, and scalability.',
      ]
    },
    {
      title: 'Data Migration and Cleansing',
      points: [
        'Assess existing data quality and cleanse data for migration.',
        'Develop a data migration strategy and execute the migration plan.',
      ]
    },
    {
      title: 'Customization and Configuration',
      points: [
        'Customize SAP PP to align with specific organizational requirements.',
        'Configure master data such as work centers, routings, and production versions.',
      ]
    },
    {
      title: 'Prototype and Testing',
      points: [
        'Develop a prototype of the SAP PP system for initial testing and validation.',
        'Conduct unit testing, integration testing, and user acceptance testing (UAT).',
      ]
    },
    {
      title: ' Deployment and Go-Live',
      points: [
        'Plan and execute the deployment of SAP PP in a controlled environment.',
        'Monitor and address any issues during the initial phase of operation.',
      ]
    },
    {
      title: 'Performance Metrics and KPIs',
      points: [
        'Define and monitor key performance indicators (KPIs) for production planning.',
        'Use metrics to evaluate the success and efficiency of the SAP PP implementation.',
      ]
    },
    {
      title: 'Resume preparation',
      points: [
        'Include keywords',
        'How to prepare reume',
        'How to prepare resume for freshers.',
        'Show impact',
        'Include soft skills',
        'Be unique',
      ]
    }
  ];

  const syllabus = [
    {
      title: 'Introduction to ERP',
      subpoints: [
        'ERP Packages',
        'Introduction to SAP',
        'SAP Logistics Cycle SD/MM/PP',
        'PP Flow and Procurement Types',
      ],
    },
    {
      title: 'SAP Navigation',
      subpoints: [
       
      ],
    },
    {
      title: 'Organizational structure of an Enterprise in the SAP R/3 System',
      subpoints: [
        'Company',
        'Company code,',
        'Controlling Area,',
        'Plant, Storage Location,',
        'Purchase Organization,',
        'Purchasing group,',
        'Sales Organization,',
        'Distribution Channel,',
        'Division,',
        'Sales Office,',
        'Sales Group,',
        'Shipping Point Etc…',
        'Controlling Settings (Cost Center and Cost Element Etc…)',
      ],
    },
    {
      title: 'Master Data',
      subpoints: [
        'Material Master',
        'Bill of Material – BOM',
        'Work Center',
        'Routing – Different types of Routing',
        'Production Version',
      ],
    },
    {
      title: 'Production Resources/Tools – PRT',
      subpoints: [
        'PRT settings',
'Master Data Creation for PRT Tools',
'Assigning of PRT Tools in Routing',
      ],
    },
    {
      title: 'Sales and Operational Planning – SOP',
      subpoints: [
        'Creation of Product Groups',
        'Creation SOP Plan',
        'Copy and Change SOP Plan',
        'Transfer of Plan to Demand Management',
        'Display Planned Independent Requirements',
      ],
    },
    {
      title: 'Independent Requirement Planning',
      subpoints: [
        'Demand Planning',
'Creation of PIR (Planned independent requirements)',
'Assigning of PRT Tools in Routing',
      ],
    },
    {
      title: 'Sales and Operational Planning – SOP',
      subpoints: [
        'Creation of Product Groups',
        'Creation SOP Plan',
        'Copy and Change SOP Plan',
        'Transfer of Plan to Demand Management',
        'Display Planned Independent Requirements',
        
      ],
    },
    {
      title: 'Independent Requirement Planning',
      subpoints: [
        'Demand Planning',
'Creation of PIR (Planned independent requirements)',
      ],
    },
    {
        title: 'MRP – Planning',
        subpoints: [
          'Master Data requirements in MRP',
          'Carrying MRP Procedure MRP, MPS, CBP',
          'Reorder Point Planning – Automatic and Manual',
          'Forecast Based Planning',
          'Time Phased Planning',
          'Independent requirement Planning',
          'MPS Planning',
          'Difference between MRP Vs MPS',
          'Master Production Scheduling planning run (MPS RUN)',
          'MRP Run In Background for all network Plants',
          
          
        ],
      },
      {
        title: 'Conversions and Processing Orders',
        subpoints: [
          'Processing Planned ordersy',
          'Planned orders conversion to Production Orders,',
          'Purchase Requisitions,',
          'Process Orders',
          'Production Orders Confirmations',
          'Production Orders Settlement',
          'Technically Complete Production Orders – TECO',
          'Collective conversion of Planned Orders into Production Orders',
          'Collective conversion of Planned Orders into Purchase Requisitions',
          'Collective Release of Production Orders',
          'Collective Confirmation of Production Order',
        ],
      },
      {
        title: 'Goods Movement in PP',
        subpoints: [
          'Goods Receipt wref to Production Order',
          'Goods Receipt w/o Production Order',
          'Goods Issue wref to Production Order',
          'Automatic Creation of GR and Release of the Production Order',
          'Creation of GI wref to Reservation',
        ],
      },
      {
        title: 'Serial Number Assignment in PP',
        subpoints: [
          'Define Serial Number Profile',
          'Maintaining Serial Number Profile in the Material Master',
          'Manual and Automatic creation of Serial Numbers in Production Orders',
          'List display of Serial Numbers',
        ],
      },
      {
        title: 'Batch Management',
        subpoints: [
          'Characteristics, Class and Sort Sequence',
          'SLED In Batch in PP',
          'Condition Tables',
          'Access Sequence',
          'Strategy Types',
          'PP Batch Search Procedure',
          'Maintain Batch Search Procedure for the Order Type',
          'Production orders creation using Batch Automatic Batch Display at Production order creation',      
          'Production order Release',
          
        ],
      },
      {
        title: 'Strategy Group',
        subpoints: [
          'Define Strategy',
          'Define Strategy Group',
          'Assign MRP Group to Strategy Group',
          'Process Flow for each Strategy Group',
          'Detailed Process Flow for each Strategy Group',
        ],
      },
      {
        title: 'Discrete Manufacturing Process',
        subpoints: [
            'Make To Order Cycle – MTO Process',
            'Make To Stock Cycle – MTS Process',
        ],
      },
      {
        title: 'Variant Configuration in PP',
        subpoints: [
          'Characteristics',
          'Class',
          'Creation of Configurable Material Master along with Classification',
          'Sales BOM for the Configurable Material',
          'Assignment of Object Dependencies for the Characteristics',
          'Maintaining Condition records',
          'Final Execution of the Variant Configuration',
        ],
      },
      {
        title: 'Availability in MRP in PP',
        subpoints: [
            'Define Requirement Classes and Requirement Types',
'Define Checking Rule and Availability Check',          
        ],
      },
      {
        title: 'Repetitive Manufacturing Process',
        subpoints: [
          'Master Data Maintenance',
          'Production versions',
          'REM Profile',
          'Backflushing',
          'REM Back flushing',
        ],
      }
  ];

  const keyFeatures = [
    "124+ Hours course duration",
    "100% Job oriented Training",
    "Industry expert faculties",
    "Free demo class available",
    "Completed 200+ Batches",
    "Certifaction guidance",
  ];

  const faqs = [
    {
      question: "What is SAP PP?",
      answer: "SAP PP (Production Planning) is a module in SAP ERP that helps organizations plan and manage their manufacturing processes.",
    },
    {
      question: "What are the key components of SAP PP?",
      answer: "SAP PP includes components like Master Data, Bill of Materials (BOM), Work Centers, and Production Orders.",
    },
    {
      question: "How to create a Production Order in SAP PP?",
      answer: "Use transaction code CO01 to create a Production Order. Enter details like material, quantity, and scheduling information.",
    },
    {
      question: "What is BOM in SAP PP?",
      answer: "BOM (Bill of Materials) is a list of components or materials required to produce a finished product in SAP PP.",
    },
    {
      question: "How to check material availability in SAP PP?",
      answer: "Transaction code MD04 allows you to check material availability. It displays the stock situation and planned requirements for a material.",
    },
    {
      question: "What is Capacity Planning in SAP PP?",
      answer: "Capacity Planning in SAP PP involves determining the production capacity required and available for manufacturing orders.",
    },
    {
      question: "How to perform MRP (Material Requirements Planning) in SAP PP?",
      answer: "Transaction code MD01/MD02 is used for MRP in SAP PP. It helps in calculating material requirements based on demand and stock levels.",
    },
    {
      question: "What is the difference between Discrete and Process Manufacturing in SAP PP?",
      answer: "Discrete Manufacturing is for products with distinct, separate units, while Process Manufacturing is for continuous production, like in the chemical industry.",
    },
    {
        question: "How to reschedule a Production Order in SAP PP?",
        answer: "Use transaction code CO02 to access the Production Order and adjust the dates as needed for rescheduling.",
      },
      {
        question: "What is SAP PP-PI (Production Planning for Process Industries)?",
        answer: "SAP PP-PI is a sub-module of SAP PP designed specifically for process industries, providing functionalities like batch management and recipe management.",
      }
  ];

  const whatsData = [
    {
      title: 'What is SAP PP ?',
      points: [
        'SAP PP (Production Planning) is a module within SAP ERP software.',
        'It facilitates efficient planning and execution of production processes.',
        'Integrates various business processes to optimize production workflows.',
        'Manages master data related to production, such as bills of materials and work centers.',
        'Enables capacity planning to ensure optimal resource utilization.',
        'Supports demand-driven production planning for timely order fulfillment.',
        'Provides real-time monitoring of production activities.',
        'Allows for the creation and management of production orders.',
        'Sapalogy taining provides SAP PP training in offline and online mode. Starting end user, consulting, implementation, support with real time SAP project based training.',
        'IT background, non IT background, freshers, experience can start their career in SAP irrespective of their background.',
        'Sapalogy is the best SAP PP training institute in nagpur with the 100% job opportunities.',
      ]
    }
  ]

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imageList = [
    { Image: dev1, alt: "Award 1" },
    { Image: dev2, alt: "Award 2" },
    { Image: dev3, alt: "Award 3" },
    { Image: dev4, alt: "Award 4" },
    { Image: dev5, alt: "Award 5" },
    { Image: dev6, alt: "Award 6" },
    { Image: dev7, alt: "Award 7" },
    { Image: dev8, alt: "Award 8" },
  ];

  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },
    { Image: rev5, alt: "rev1" },
  ]


  return (
    <div className="devops-page">
         <SideDropdown roadmapData={modulesData} />
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Best SAP PP Training</h1>
          <p>
          Sapalogy offers you the best SAP PP training in Nagpur for freshers and experience candidate in Nagpur, with expert guidance and 100% guaranteed placement assistance. Our SAP PP training course covers every technical topics in details and the student is left with real-time hand on experience.
          </p>
          <br />
          <p>
          Our practical, Job-oriented training program will not only provide you with a certificate but also with knowledge equivalent to around 2+ years of field experience. We value your time as much as over. Hence we invented to train you in SAP PP in just 4 months.
          </p>

          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faFileAlt} /> End user 1 month (ECC / S4 HANA)</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Configuration 2 months (ECC / S4 HANA)</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> Project 1 month</li>
            <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> 6-months internship</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
        </ul>
        </div>

        <PageForm />
      </div>
    

{/* 
      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>

      </div> */}
      <Roadmap heading="Roadmap to learn SAP PP" roadmapData={roadmapData} />
      <WhatsDevOps whatsData={whatsData} />
      <Syllabus heading="SAP PP Training syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="SAP PP Training" keyFeatures={keyFeatures} />

      <div className="batch-schedule-section">
        <h2>Upcoming Batch Schedule for SAP PP Training</h2>
        <p>
        Sapalogy provides flexible timings to all our students. Here are the SAP PP Training Classes in Nagpur Schedule in our branches. If this schedule doesn’t match please let us know. We will try to arrange appropriate timings based on your flexible timing.
        </p>
        <div className="batch-schedule-table-wrapper">
        <table className="batch-schedule-table">
          <thead>
            <tr>
              <th>Course</th>
              <th>Batch Time</th>
              <th>Offline</th>
              <th>Online</th>
              <th>Enquire Now</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>SAP PP</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
            <tr>
              <td>SAP MM</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
            <tr>
              <td>SAP FICO</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
            <tr>
              <td>SAP</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div></div>



      <section className="devops-certification-section">
        <h2 className="certification-heading">SAP PP CERTIFICATION</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
              <li>The SAP S/4HANA certification offered by Sapalogy will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
              <li>Sapalogy provides comprehensive guidance for your SAP S/4HANA global certification, ensuring a 100% passing guarantee in examinations such as SAP Certification, SAP S/4HANA Platform Development Certification, and various other global exams.</li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>
      <Roadmap heading="SAP modules" roadmapData={modulesData} />


      {/* devops project Section */}
      {/* <div className="slider-container">
        <h2 className="slider-heading">SAP HCM Project</h2>
        <RegularSlider sliderSettings={sliderSettings} images={imageList} />
      </div> */}

      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
        <h2 className="slider-heading">Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div> */}


      <div className="alumni-section">
        <h1>Our Alumni Works At</h1>
        <div className="full-screen-image">
          <img src={IMG} alt="Full Screen Image" />
        </div>
      </div>
      <Contact />

    </div>
  );
};

export default SAPPP;