import React, { useState, useEffect } from 'react';
import logo from '../assets/Logo.webp';
import '../Css/Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [activeAccordion, setActiveAccordion] = useState(null);
    const [activeSubAccordion, setActiveSubAccordion] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleAccordion = (index) => {
        setActiveAccordion(activeAccordion === index ? null : index);
    };

    const toggleSubAccordion = (index) => {
        setActiveSubAccordion(activeSubAccordion === index ? null : index);
    };

    return (
        <header>
            <nav className="navbar">
                <div className="logo">
                    <img src={logo} alt="hunya" />
                    <Link to="/">Sapalogy Training</Link>
                </div>
                <ul className={`nav-links ${isMenuOpen ? 'active' : ''}`}>
                    <li><Link to="/">HOME</Link></li>

                    {/* For the COURSES section */}
                    <li className="dropdown">
                        {isMobile ? (
                            // Accordion structure for mobile mode
                            <div className={`accord-item ${activeAccordion === 0 ? 'active' : ''}`}>
                                <button onClick={() => toggleAccordion(0)} className="accord-toggle">
                                    COURSES <span>{activeAccordion === 0 ? '▲' : '▼'}</span>
                                </button>
                                <div className={`accord-content ${activeAccordion === 0 ? 'active' : ''}`}>
                                    <div className={`accord-item ${activeSubAccordion === 1 ? 'active' : ''}`}>
                                        <button onClick={() => toggleSubAccordion(1)} className="accord-toggle">
                                            <a href="/salesforce-training" >Salesforce</a> <span>{activeSubAccordion === 1 ? '▲' : '▼'}</span>
                                        </button>
                                        <div className={`accord-content ${activeSubAccordion === 1 ? 'active' : ''}`}>
                                            <ul>
                                                <li><a href="/salesforce-admin">Salesforce Admin</a></li>
                                                <li><a href="/salesforce-development-2">Salesforce Development</a></li>
                                                <li><a href="/salesforce-lwc-integration">Salesforce LWC & Integration</a></li>
                                                <li><a href="/salesforce-marketing-cloud">Salesforce Marketing Cloud</a></li>
                                            </ul>
                                        </div>
                                        <ul>
                                            <li><a href="/best-data-analytics-training">Data Analytics</a></li>
                                            <li><a href="/best-data-science-training">Data Science</a></li>
                                            <li><a href="/best-business-analytics-training">Business Analytics</a></li>
                                            <li><a href="/full-stack-developer">Full Stack Development</a></li>
                                            <li><a href='/best-devops-training'>DevOps</a></li>
                                            <li><a href="/best-aws-training">AWS</a></li>
                                            <li><a href="/best-ai-ml-training">AI & ML</a></li>
                                            <li><a href="/best-machine-learning-training">Machine Learning</a></li>
                                            <li><a href="/tally">Tally</a></li>                                        
                                            <li><a href="/python">Python</a></li>
                                            <li><a href="/share-market">Share Market</a></li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        ) : (
                            // Regular dropdown for non-mobile mode
                            <span onClick={() => toggleAccordion(0)} className="dropdown-title">
                                <a href="#Course">COURSES</a>
                                <span className="dropdown-icon">{activeAccordion === 0 ? '▲' : '▼'}</span>
                            </span>
                        )}

                        {!isMobile && (
                            <ul className={`dropdown-menu ${activeAccordion === 0 ? 'active' : ''}`}>
                                <li className="dropdown-submenu">
                                    <span onClick={() => toggleSubAccordion(1)} className="dropdown-title">
                                        <a href="/salesforce-training">Salesforce</a>
                                        <span className="dropdown-icon">{activeSubAccordion === 1 ? '▲' : '▼'}</span>
                                    </span>
                                    <ul className={`dropdown-submenu-menu ${activeSubAccordion === 1 ? 'active' : ''}`}>
                                        <li><a href="/salesforce-admin">Salesforce Admin</a></li>
                                        <li><a href="/salesforce-development-2">Salesforce Development</a></li>
                                        <li><a href="/salesforce-lwc-integration">Salesforce LWC & Integration</a></li>
                                        <li><a href="/salesforce-marketing-cloud">Salesforce Marketing Cloud</a></li>
                                    </ul>
                                </li>
                                <li><a href="/best-data-analytics-training">Data Analytics</a></li>
                                <li><a href="/best-data-science-training">Data Science</a></li>
                                <li><a href="/best-business-analytics-training">Business Analytics</a></li>
                                <li><a href="/full-stack-developer">Full Stack Development</a></li>
                                <li><Link to='/best-devops-training'>DevOps</Link></li>
                                <li><a href="/best-aws-training">AWS</a></li>
                             
                                <li><a href="/best-ai-ml-training">AI & ML</a></li>
                                <li><a href="/best-machine-learning-training">Machine Learning</a></li>
                                <li><a href="/tally">Tally</a></li>
                               
                                <li><a href="/python">Python</a></li>
                                <li><a href="/share-market">Share Market</a></li>
                            </ul>
                        )}
                    </li>
                    
                    <li><a href="/career">CAREERS</a></li>
                    <li><a href="/b">BLOGS</a></li>
                    <li><a href="/about-us">ABOUT US</a></li>
                    <li><a href="/contact">CONTACT US</a></li>
                </ul>
                <button className="hamburger" onClick={toggleMenu}>
                    {isMenuOpen ? '✖' : '☰'}
                </button>
            </nav>
        </header>
    );
};

export default Navbar;
