import React, { useEffect, useRef } from 'react';
import '../../Css/DevOps.css';
import certificateImg from '../../assets/certificate.webp';
import Contact from '../../pages/Contact'
import IMG from '../../assets/Work.webp'
import Roadmap from '../../Components/Roadmap';
import WhatsDevOps from '../../Components/WhatsDevOps'
import PageForm from '../../Components/PageForm';
import Syllabus from '../../Components/Syllabus';
import KeyFeatures from '../../Components/KeyFeatures';
import Faq from '../../Components/Faq'
import 'slick-carousel/slick/slick.css'; // Importing slick carousel styles
import 'slick-carousel/slick/slick-theme.css';// Importing slick theme styles
import dev1 from '../../assets/Projects/Devops/DEV1.png';
import dev2 from '../../assets/Projects/Devops/DEV2.png';
import dev3 from '../../assets/Projects/Devops/DEV3.png';
import dev4 from '../../assets/Projects/Devops/DEV4.png';
import dev5 from '../../assets/Projects/Devops/DEV5.png';
import dev6 from '../../assets/Projects/Devops/DEV6.png';
import dev7 from '../../assets/Projects/Devops/DEV7.png';
import dev8 from '../../assets/Projects/Devops/DEV8.png';
import rev1 from '../../assets/Reviews/DEVOPS/devrev1.png';
import rev2 from '../../assets/Reviews/DEVOPS/devrev2.png';
import rev3 from '../../assets/Reviews/DEVOPS/devrev3.png';
import rev4 from '../../assets/Reviews/DEVOPS/devrev4.png';
import rev5 from '../../assets/Reviews/DEVOPS/devrev5.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faChalkboardTeacher, faBriefcase, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import SideDropdown from '../../Components/SideDropdown';



const SAPSD = () => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    // Function to create the YouTube player
    const createPlayer = () => {
      if (window.YT && window.YT.Player) {
        playerRef.current = new window.YT.Player(videoRef.current, {
          videoId: 'dta2ucEwgdw', // Updated YouTube video ID
          events: {
            onReady: (event) => {
              // event.target.mute(); // Mute the video to allow autoplay
               event.target.playVideo();
            },
          },
          playerVars: {
            loop: 1,
            controls: 0, // Hide video controls
            modestbranding: 1, // Minimal YouTube branding
            playlist: 'dta2ucEwgdw', // Required for looping
          },
        });
      }
    };

    // Load YouTube IFrame API if it's not already loaded
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // Set up callback for when the API is ready
      window.onYouTubeIframeAPIReady = () => {
        createPlayer();
      };
    } else {
      createPlayer();
    }
  }, []);


  const roadmapData = [
    {
      title: 'Introduction',
      points: [
        'What is SAP?',
        'What is SAP SD?',
        'What is SAP HANA?',
        'SAP course',
        'SAP certification',
        'SAP jobs in india',
        'SAP jobs in nagpur',
      ]
    },
    {
      title: 'Stakeholder Alignment',
      points: [
        "Engage with key stakeholders, including sales, finance, and customer service teams.",
        "Gather input on requirements and expectations.",
      ]
    },
    {
      title: 'System Landscape Analysis',
      points: [
        'Review the existing system landscape, including integration points with other SAP modules and external systems.',
        'Identify potential areas for system consolidation or optimization.',
      ]
    },
    {
      title: 'Data Migration Strategy',
      points: [
        "Plan and execute a data migration strategy to ensure a smooth transition of historical data.",
        "Validate and cleanse data to maintain data integrity.",
      ]
    },
    {
      title: 'User Training and Change Management',
      points: [
        "Develop a comprehensive training plan for end-users.",
        "Implement change management strategies to facilitate user adoption.",
      ]
    },
    {
      title: 'Testing Phase',
      points: [
        "Conduct thorough testing, including unit testing, integration testing, and user acceptance testing (UAT).",
        "Address and resolve any issues identified during testing."
      ]
    },
    {
      title: 'Go-Live Planning',
      points: [
        "Develop a detailed go-live plan with minimal disruption to business operations.",
        "Consider a phased rollout approach for different business units or regions."
      ]
    },
    {
      title: 'Resume preparation',
      points: [
        'Include keywords.',
        'How to prepare resume.',
        'How to prepare resume for freshers.',
        'Show impact.',
        'Include soft skills.',
        'Be unique.',
      ]
    }
  ];
  const modulesData = [
    {
      title: 'SAP',
      points: [
          'Sapalogy Training in Nagpur offers expert SAP training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
      ],
      link: "/best-sap-training"
  },
    {
      title: 'SAP BASIS',
      points: [
        'Sapalogy Training in Nagpur offers expert SAP BASIS training, empowering you to efficiently manage SAP systems and ensure seamless operations.'
      ],
      link: "/sap-basis-training-in-nagpur"
    },
    {
      title: 'SAP MM',
      points: [
        'SAP MM (Materials Management) is a module in SAP ERP that manages procurement processes and inventory management. Sapalogy Institute in Nagpur offers comprehensive training in SAP MM for effective utilization ',
      ],
      link: "/sap-mm"
    },
    {
      title: 'SAP FICO',
      points: [
        'SAP FICO, offered by Sapalogy Institute Nagpur, encompasses financial accounting and controlling modules within the SAP ERP system, facilitating comprehensive financial management and reporting for organizations'
      ],
      link: "/sap-fico"
    },
    {
      title: 'SAP ABAP',
      points: [
        'SAP ABAP is a programming language used for developing applications within the SAP ecosystem. Sapalogy Institute in Nagpur is a training center specializing in SAP courses, including ABAP programming to equip professionals with the skills.',
      ],
      link: "/sap-abap",
    },
    {
      title: 'SAP HCM',
      points: [
        'SAP HCM (Human Capital Management) is an integrated software suite by SAP that manages HR processes, from recruitment to payroll. Sapalogy Institute in Nagpur likely provides training in SAP HCM and related modules.'
      ],
      link: "/sap-hcm",
    },
    {
      title: 'SAP SCM',
      points: [
        'SAP SCM (Supply Chain Management) is a software suite by SAP that integrates and optimizes key supply chain processes for enhanced efficiency. It enables businesses to manage procurement, production, and distribution seamlessly, ensuring streamlined operations.',
      ],
      link: "/sap-scm"
    },
    {
      title: 'SAP ARIBA',
      points: [
        'SAP Ariba is a cloud-based procurement platform by SAP that streamlines and automates procurement processes, enhancing collaboration between buyers and suppliers. Sapalogy Institute Nagpur is an educational institution specializing in SAP training and certification.',
      ],
      link: "/sap-ariba"
    },
    {
      title: 'SAP PP',
      points: [
        'SAP PP (Production Planning) is an SAP module that helps organizations manage and optimize their manufacturing processes, from planning and scheduling to execution. It integrates various business functions to streamline production.',
      ],
      link: "/sap-pp"
    },
    {
      title: 'SAP SD',
      points: [
        'SAP SD (Sales and Distribution) is a module in SAP ERP that manages sales and customer service processes. It covers order-to-cash processes, including sales order processing, pricing, delivery, and billing. SAP SD to equip individuals with skills.',
      ],
      link: "/sap-sd"
    },
    {
      title: 'SAP FIORI',
      points: [
        'SAP Fiori is a user experience (UX) design approach and set of design principles that enhance the usability and functionality of SAP applications. It provides a modern, responsive, and personalized user interface for a seamless and intuitive user experience in the SAP environment.',
      ],
      link: "/sap-fiori"
    },
    {
      title: 'SAP QM',
      points: [
        'SAP QM (Quality Management) is a module in SAP ERP that facilitates quality control and assurance processes in manufacturing and production. It helps organizations manage quality planning, inspection, and certification, ensuring products meet specified standards and many more.',
      ],
      link: "/sap-qm"
    },
    {
      title: 'SAP PM',
      points: [
        'SAP PM (Plant Maintenance) is a module in SAP ERP that helps organizations effectively manage and maintain their assets, equipment, and facilities. It covers processes such as preventive maintenance, work orders, and equipment tracking to optimize asset performance.',
      ],
      link: "/sap-pm"
    },
    {
      title: 'SAP WM & EWM',
      points: [
        'SAP WM (Warehouse Management) focuses on efficient inventory management within a warehouse, while SAP EWM (Extended Warehouse Management) extends these capabilities with advanced features like labour management and slotting optimization for operations.',
      ],
      link: "/sap-wm-ewm"
    },
    {
      title: 'SAP LE & SL',
      points: [
        'SAP LE (Logistics Execution) is a module that manages and optimizes warehouse and transportation processes, while SAP SL (Slotting) focuses on optimizing storage space and picking efficiency within a warehouse. Both modules contribute to streamlining supply chain operations in SAP systems.',
      ],
      link: "/sap-le-sl"
    },
  ];
  const syllabus = [
    {
      title: 'Intruduction to SAP SD',
      subpoints: [
        'SAP Evolution',
        'SAP Project Environment',
        'SAP Ides System',
        'Business Process',
        'SAP Clients',
        'Logon to SAP System',
        'R/3 Architecture',
      ],
    },
    {
      title: 'Enterprise structure (with configuration & assignment)',
      subpoints: [
        "Sales Organization, Distribution",
        "Channel, Division –Sales Area",
        "Sales Office, Sales group, Sales",
        "Person",
        "Plant, Storage Location,",
        "Shipping Point, Loading Point",
      ],
    },
    {
      title: 'Master Data',
      subpoints: [
        'Customer Master',
        'Material Master',
        'Customer material information Record',
        'Other Records',
      ],
    },
    {
      title: 'Sales Cycle(different Sales processes)',
      subpoints: [
        'Sales Document Overview',
        'Sales Document Header Level',
        'Sales Document Item Level',
        'Sales Document Schedule Line Level',
        'Item Category Determination',
        'Schedule Line Determination',
        'Defining and Assigning Number',
        'Ranges',
        'Sales Document Types Assignment',
        'to Sales Area',
        'Copy Control',
        'Processing Sales Order',
        'Sales Document Functions',
        'Inquiry',
        'Quotation',
        'Standard order',
      ],
    },
    {
      title: 'Pricing',
      subpoints: [
        'Pricing Overview',
        'Working with Condition Records',
        'Components of Condition',
        'Techniques',
        'Condition Tables',
        'Access Sequence',
        'Condition Type',
        'Pricing Procedure',
        'Pricing Procedure Determination',
        'Condition Master Data',
        'Creation of Records for Automatic',
        'Pricing and Related IMG activity',
      ],
    },
    {
      title: 'Shipping & picking',
      subpoints: [
        'Shipping & Picking Overview',
        'Overview of Shipping',
        'Organizational Units in Shipping',
        'Defining Shipping Point and',
        'Determination',
        'Storage Location Determination',
        'Delivery Document Type',
        'Scheduling (Backward and Forward)',
        'PGI & PGR',
      ],
    },
    {
      title: 'Pre Sales',
      subpoints: [
        "Inquiry",
        "Quotation",
      ],
    },
    {
      title: 'Order',
      subpoints: [
        "Standard Order",
      ],
    }, {
      title: 'Order Management',
      subpoints: [
        'Creation of Sales Documents',
        'Overview on Sales Doc types',
        'Effects of Masters on sales Documents',
      ],
    },
    {
      title: 'Special Functionalities in SD',
      subpoints: [
        'Output Control',
        'Account Determination',
        'Partner Determination',
      ],
    },
    {
      title: 'Delivery',
      subpoints: [
        'Creation of Outbound delivery',
        'Goods Issue',
      ],
    },
    {
      title: 'Billing',
      subpoints: [
        'Functional Overview',
        'Billing Document Type',
        'Defining And Assigning Number Ranges',
      ],
    },
    {
      title: 'Complaints',
      subpoints: [
        'Credit Memo',
        'Debit Memo',
        'Returns',
      ],
    },
    {
      title: 'Special Processes',
      subpoints: [
        'Third Party Sales Order',
        'Sales BOM',
        'Consignment Process',
        'Cash Sales & Rush Orders',
        'Credit Management',
      ],
    },
    {
      title: 'In Addition',
      subpoints: [
        '5+ PROJECTS ON SAP',
        '20+ TOPIC WISE TESTS',
        'RESUME BUILDING',
        'INTERVIEW PREPARATION',
        '6 MONTH INTERNSHIP WITH CIN NO.',
        'JOB OPPORTUNITIES',
        'ONE ON ONE CLASSROOM INTERVIEW PRACTICE',
      ],
    },
  ];

  const keyFeatures = [
    '125+ Hours course duration',
    '100% Job oriented training',
    'Industry expert faculties',
    'Free demo class available',
    'Certification guidance',
    'Completed 200+ batches',

  ];

  const faqs = [
    {
      question: "What is SAP SD?",
      answer: "SAP SD stands for Sales and Distribution. It is a module in SAP ERP that manages customer-related business processes, including sales, billing, and shipping.",
    },
    {
      question: "What is a Sales Order in SAP SD?",
      answer: "A sales order in SAP SD is a document that captures the customer’s request for goods or services. It contains details such as products, quantities, and delivery dates.",
    },
    {
      question: "How to create a Sales Order in SAP SD?",
      answer: "Sales orders can be created in SAP SD using transaction code VA01 or through the SAP Easy Access menu.",
    },
    {
      question: "What is the Delivery Document in SAP SD?",
      answer: "The delivery document in SAP SD is created to process the shipment of goods to the customer. It includes information on what and how much is being shipped.",
    },
    {
      question: "How is Pricing Determined in SAP SD?",
      answer: "Pricing in SAP SD is determined based on various factors such as customer pricing conditions, discounts, and surcharges. It is configured using condition types and condition records.",
    },
    {
      question: "What is the Billing Document in SAP SD?",
      answer: "The billing document in SAP SD is generated to invoice the customer for the products or services delivered. It includes details on pricing, taxes, and payment terms.",
    },
    {
      question: "Explain the Intercompany Sales Process in SAP SD.",
      answer: "Intercompany sales involve transactions between different legal entities within the same corporate group. SAP SD facilitates this process by allowing for seamless order processing and financial reconciliation between the entities.",
    },
    {
      question: "What is Availability Check in SAP SD?",
      answer: "Availability check in SAP SD is a feature that verifies whether the requested quantity of a product is available in stock before confirming a sales order. It helps in preventing overselling and backorder situations.",
    },
    {
      question: "What is Availability Check in SAP SD?",
      answer: "Availability check in SAP SD is a feature that verifies whether the requested quantity of a product is available in stock before confirming a sales order. It helps in preventing overselling and backorder situations.",
    },
    {
      question: "Explain the Integration of SAP SD with Other Modules.",
      answer: "SAP SD integrates with various modules like MM (Materials Management), PP (Production Planning), and WM (Warehouse Management) for seamless coordination between different business processes.",
    },
  ];

  const whatsData = [
    {
      title: 'About SAP SD Training Course',
      points: [
        "SAP SD (Sales and Distribution) is a module within the SAP ERP system.",
        "It manages sales, shipping, and billing processes in an organization.",
        "Supports the entire sales lifecycle, from order creation to delivery and invoicing.",
        "Enables efficient order processing and management of customer relationships.",
        "Integrates with other SAP modules for seamless business operations.",
        "Manages pricing, product availability, and credit limits for customers.",
        "Sapalogy Training provides SAP MM training in offline and online mode. Starting end user, consulting, implementation, support with real time SAP project based training.",
        "IT background, non IT background, freshers, experience can start their career in SAP irrespective of their background.",
        "Sapalogy is the best training institute in Nagpur with the 100% job opportunities.",
      ]
    }
  ]

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const imageList = [
    { Image: dev1, alt: "Award 1" },
    { Image: dev2, alt: "Award 2" },
    { Image: dev3, alt: "Award 3" },
    { Image: dev4, alt: "Award 4" },
    { Image: dev5, alt: "Award 5" },
    { Image: dev6, alt: "Award 6" },
    { Image: dev7, alt: "Award 7" },
    { Image: dev8, alt: "Award 8" },
  ];

  const reviewList = [
    { Image: rev1, alt: "rev1" },
    { Image: rev2, alt: "rev1" },
    { Image: rev3, alt: "rev1" },
    { Image: rev4, alt: "rev1" },
    { Image: rev5, alt: "rev1" },
  ]

  return (
    <div className="devops-page">
         <SideDropdown roadmapData={modulesData} />
      <div className="content-container">
        <div className="devops-circle devops-circle-bottom-left"></div>
        <div className="left-content">
          <h1>Best SAP SD Training</h1>
          <p>Sapalogy offers you the best SAP SD(Sales and Distribution) training in Nagpur for freshers and experience candidate in Nagpur, with expert guidance and 100% guaranteed placement assistance. Our SAP training course covers every technical topics in details and the student is left with real-time hand on experience.</p>
          <br />
          <p>Our practical, Job-oriented training program will not only provide you with a certificate but also with knowledge equivalent to around 2+ years of field experience. We value your time as much as over. Hence we invented to train you in SAP SD with S/4 HANA in just 4 months.</p>
          <ul className='points-list'>
            <li>End user 1 month (ECC / S4 HANA)</li>
            <li>Configuration 2 months(ECC/S4 HANA)</li>
            <li>Project 1 month</li>
          </ul>
          <h3>All the topics will be covered in detail and also include:</h3>
          <ul className="points-list">
            <li><FontAwesomeIcon icon={faFileAlt} /> Resume preparation</li>
            <li><FontAwesomeIcon icon={faChalkboardTeacher} /> Interview practice</li>
            <li><FontAwesomeIcon icon={faBriefcase} /> 6-months internship</li>
            <li><FontAwesomeIcon icon={faCheckCircle} /> 100% job opportunities guaranteed program</li>
        </ul>
                
        </div>
        <PageForm />
      </div>
      

      <div className="video-section">
        <div className="video-container" controls ref={videoRef}></div>
      </div>
      <WhatsDevOps whatsData={whatsData} />
      <Roadmap heading="Roadmap to learn SAP SD" roadmapData={roadmapData} />
      <Syllabus heading="SAP SD Training Syllabus" Syllabus={syllabus} />
      <KeyFeatures heading="SAP SD Training" keyFeatures={keyFeatures} />

      <div className="batch-schedule-section">
        <h2>Upcoming Batch Schedule for SAP SD Training</h2>
        <p>Sapalogy provides flexible timings to all our students. Here is the SAP SD Training Class Schedule in our branches. If this schedule doesn’t match please let us know. We will try to arrange appropriate timings based on your flexible timings.</p>
        <div className="batch-schedule-table-wrapper">
        <table className="batch-schedule-table">
          <thead>
            <tr>
              <th>Course</th>
              <th>Batch Time</th>
              <th>Offline</th>
              <th>Online</th>
              <th>Enquire Now</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>SAP SD</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
            <tr>
              <td>SAP FICO</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
            <tr>
              <td>SAP HCM</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
            <tr>
              <td>SAP</td>
              <td>Starts every week</td>
              <td>Nagpur</td>
              <td>India</td>
              <td><a href="/contact">Enquire Now</a></td>
            </tr>
          </tbody>
        </table>
      </div>
</div>
      <section className="devops-certification-section">
        <h2 className="certification-heading">SAP SD CERTIFICATION</h2>
        <div className="certification-content">
          <div className="certification-points">
            <ul>
              <li>Sapalogy training certification will serve as proof that the courses were completed by Sapalogy.</li>
              <li>The SAP S/4HANA certification offered by Sapalogy will equip you with valuable skills, enhancing your competitiveness in the job market.</li>
              <li>Sapalogy provides comprehensive guidance for your SAP S/4HANA global certification, ensuring a 100% passing guarantee in examinations such as SAP Certification, SAP S/4HANA Platform Development Certification, and various other global exams.</li>
            </ul>
          </div>
          <div className="certification-image">
            <img src={certificateImg} alt="DevOps Certification" />
          </div>
        </div>
      </section>
      <Roadmap heading="SAP modules" roadmapData={modulesData} />
      <Faq Faqs={faqs} />

      {/* <div className="slider-container">
        <h2 className="slider-heading">SAP SD Training courses review</h2>
        <RegularSlider sliderSettings={sliderSettings} images={reviewList} />
      </div> */}


      <div className="alumni-section">
        <h1>Our Alumni Works At</h1>
        <div className="full-screen-image">
          <img src={IMG} alt="Full Screen Image" />
        </div>
      </div>
      <Contact />

    </div>
  );
};

export default SAPSD;