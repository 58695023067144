// src/components/Roadmap.jsx
import React from 'react';
import '../Css/Roadmap.css'; // Make sure to add your CSS styling for the Roadmap

const Roadmap = ({ heading, roadmapData }) => {
  return (
    <div className="roadmap-container">
      <h1 className="main-heading">{heading}</h1>
      <div className="devops-circle devops-circle-top-right"></div>
      <div className="cards-wrapper">
        {roadmapData.map((item, index) => (
          <div className="roadmap-card" key={index}>
            <h2 className="card-heading">{item.title}</h2>
            <ul className="card-points">
              {item.points.map((point, idx) => (
                <li key={idx}>{point}</li>
              ))}
            </ul>
            {/* Use an anchor tag to force a full page refresh */}
            {item.link && (
              <a href={item.link}>
                <button className="module-btn">Go to {item.title} page</button>
              </a>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Roadmap;
